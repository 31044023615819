import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Button, Card, Col, Dropdown, Flex, Input, MenuProps, Row, Switch, Table } from "antd";
import {
  PageHeader,
  TableSkeleton,
  DeleteModal,
  AddEditRoleForm,
  PermissionModal,
} from "../../../components";
import { RootState } from "../../../store";
import {
  setCurrPage,
  setRolesList,
  setSearchText,
} from "../../../store/roleManagement/roleManage.slice";
import { IRolesListRes } from "../../../store/roleManagement/roleManage.interface";
import {
  deleteRole,
  fetchPermissionsList,
  fetchRolesList,
  updateRoleStatus,
} from "../../../services/roleManagement";
import {
  useDebounce,
  PageLimit,
  CustomPagination,
  isEmpty,
  Limit,
  checkPlatformPermission,
  Permissions,
} from "../../../shared";
import "./RoleManagement.scss";

const RoleManagement = () => {
  const dispatch = useDispatch();

  const { allUsers } = useSelector((state: RootState) => state.user);
  const {
    rolesList,
    totalRolesCount,
    isRoleFetching,
    isRoleDeleting,
    currPage,
    searchText,
    isRoleUpdating,
  } = useSelector((state: RootState) => state.roleManagement);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<IRolesListRes | null>(null);
  const [isShowDeleteRoleModal, setIsShowDeleteRoleModal] = useState<boolean>(false);
  const [isShowPermissionModal, setIsShowPermissionModal] = useState<number>(-1);

  const handleClosePermissionModal = () => {
    setIsShowPermissionModal(-1);
    setSelectedRole(null);
  };

  useEffect(() => {
    fetchPermissionsList();
    fetchRolesList(searchText, currPage);
  }, []);

  const handleUpdateStatus = async (
    value: boolean,
    roleDetails: IRolesListRes,
    selectedIndex: number
  ) => {
    const res = await updateRoleStatus(value, roleDetails?.id);

    if (res?.status === 200) {
      const contentsValues = [...rolesList];
      contentsValues[selectedIndex] = {
        ...roleDetails,
        status: value,
      };
      dispatch(setRolesList(contentsValues));
    }
  };

  const getMenuItems = () => {
    const items: MenuProps["items"] = [];

    if (checkPlatformPermission(Permissions.EDIT_ROLE)) {
      items.push({
        key: "1",
        label: (
          <div
            onClick={() => {
              setIsModalOpen(true);
            }}>
            Edit
          </div>
        ),
      });
    }

    if (checkPlatformPermission(Permissions.DELETE_ROLE)) {
      items.push({
        key: "2",
        label: (
          <div
            onClick={() => {
              setIsShowDeleteRoleModal(true);
            }}
            className="UserDeleteText">
            Delete
          </div>
        ),
      });
    }

    return items;
  };

  const columns: any[] = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      render: (name: string) => (
        <>
          <span className="td-label">Role Name : </span>
          {name}
        </>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      key: "permissions",
      width: "50%",
      render: (_: undefined, role: IRolesListRes) => (
        <>
          <span className="td-label">Permissions : </span>

          {!isEmpty(role?.privileges) ? (
            <span className="permissions-text">
              {role?.privileges.slice(0, Limit.Permissions).map((item, index) => {
                return (
                  <span key={index}>
                    {item?.label}
                    {role?.privileges?.length - 1 !== index && `, `}
                  </span>
                );
              })}

              {role?.privileges?.length > Limit.Permissions && (
                <u
                  onClick={() => {
                    setSelectedRole(role);
                    setIsShowPermissionModal(role?.id);
                  }}
                  className="more-tag">
                  +{role?.privileges?.length - Limit.Permissions} more
                </u>
              )}
            </span>
          ) : (
            <span className="permissions-text">N/A</span>
          )}

          {isShowPermissionModal === role?.id && (
            <PermissionModal
              isOpen={isShowPermissionModal === role?.id}
              setModalClose={handleClosePermissionModal}
              selectedRole={selectedRole}
            />
          )}
        </>
      ),
    },

    {
      title: "Status",
      width: "100px",
      render: (_: string, role: IRolesListRes, index: number) => {
        return (
          <>
            <span className="td-label">Status : </span>
            <Switch
              disabled={!checkPlatformPermission(Permissions.EDIT_ROLE) || isRoleUpdating}
              checked={role?.status}
              onChange={(e) => handleUpdateStatus(e, role, index)}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "90px",
      className: "centered-column",
      render: (_: string, role: IRolesListRes) => (
        <>
          <span className="td-label">Action : </span>
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: () => {
                setSelectedRole(role);
              },
              items: getMenuItems(),
            }}
            overlayStyle={{ width: 80 }}
            overlayClassName="table-action-dropdown table-action-dropdown-user"
            placement="bottomRight">
            <Button className="more-action">
              <i className="ri-more-2-fill"></i>
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setSelectedRole(null);
  };

  const getUsersBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) await fetchRolesList(trimmedSearchText, 1);
    if (e.target.value.length === 0) {
      await fetchRolesList("", 1);
    }
    dispatch(setCurrPage(1));
  };

  const handleSearchDebounce = useDebounce(getUsersBySearch, 500);
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounce(e);
    dispatch(setSearchText(e.target.value));
  };

  const handleDeleteUser = async () => {
    if (selectedRole?.id) await deleteRole(selectedRole?.id, searchText, currPage, totalRolesCount);
    setIsShowDeleteRoleModal(false);
    setSelectedRole(null);
  };

  const handlePagination = (page: number) => {
    dispatch(setCurrPage(page));
    fetchRolesList(searchText, page);
  };

  return (
    <Flex className="admin-inner-wrap user-managements-page" vertical>
      <PageHeader
        title="Role Management"
        isRelevantAsset={false}
        content={
          <>
            <Input
              type="search"
              className="header-search"
              placeholder="Search here..."
              prefix={<i className="ri-search-line" />}
              onChange={(e) => {
                handleChangeSearch(e);
              }}
              defaultValue={searchText}
              value={searchText}
              allowClear
            />
            {checkPlatformPermission(Permissions.ADD_ROLE) && (
              <Button
                className="btn"
                type="default"
                icon={<i className="ri-add-circle-fill"></i>}
                onClick={() => setIsModalOpen(true)}>
                Add new Role
              </Button>
            )}
          </>
        }
      />

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}>
        <Row>
          <Col span={24}>
            <Card className={`global-table-card ${isEmpty(allUsers) ? "global-table-empty" : ""}`}>
              {isRoleFetching ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table responsive-table-user-management"
                  columns={columns}
                  dataSource={rolesList?.length ? rolesList : []}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 188px)" }}
                  pagination={false}
                />
              )}

              {rolesList && rolesList.length ? (
                <CustomPagination
                  currentPage={currPage}
                  total={totalRolesCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.RoleManagement}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </motion.div>

      {isModalOpen && (
        <AddEditRoleForm
          title={isEmpty(selectedRole) ? "Add Role" : "Edit Role"}
          isModalOpen={isModalOpen}
          onCancel={handleOnCancel}
          titleBtn={isEmpty(selectedRole) ? "Create" : "Save"}
          selectedRole={selectedRole}
        />
      )}

      {/* delete Role modal */}
      <DeleteModal
        isLoading={isRoleDeleting}
        isModalOpen={isShowDeleteRoleModal}
        handleCancel={() => {
          setIsShowDeleteRoleModal(false);
          setSelectedRole(null);
        }}
        handleSubmit={handleDeleteUser}
        message="Are you sure you want to delete this Role?"
        title="Delete Role"
      />
    </Flex>
  );
};

export default RoleManagement;
