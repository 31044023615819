import { isEmpty } from "../helpers";
import { Permissions } from "./../constants";
import { IPermissions } from "../../store/roleManagement/roleManage.interface";
import { store } from "../../store";

export const isShowAdmin = (permissionsList: IPermissions[], isPlatformAdmin: boolean) => {
  if (isPlatformAdmin) {
    return isPlatformAdmin;
  } else {
    let isAdmin = false;
    if (!isEmpty(permissionsList)) {
      permissionsList?.forEach((item: IPermissions) => {
        if (item?.name === Permissions.VIEW_PLATFORM_CONFIG) {
          isAdmin = true;
          return;
        }
      });
    }

    return isAdmin;
  }
};

export const checkPlatformPermission = (permission: Permissions): boolean => {
  if (store.getState().auth?.platformAccess?.isPlatformAdmin) {
    return true;
  } else {
    return (
      store
        .getState()
        .auth.platformAccess?.role?.privileges?.some(
          (privilege) => privilege?.name === permission
        ) || false
    );
  }
};
