import { Button, Form, Modal } from "antd";

interface IModal {
  isModalOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  handleLogout: VoidFunction;
  titleBtn?: string;
}

const WarningSystemPrompts = ({ isModalOpen, title, onCancel, titleBtn = "Save" }: IModal) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      title={
        <>
          <i className="ci caution-sign-icon" style={{ marginRight: 8 }} />
          <span className="warning-title">{title}</span>
        </>
      }
      afterClose={() => form.resetFields()}
      width={500}
      footer={
        <>
          <Button
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            className="btn"
            size={"middle"}>
            Cancel
          </Button>
          <Button
            // loading={isLoading}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn"
            type="primary"
            size={"middle"}>
            {titleBtn}
          </Button>
        </>
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addUser"
          initialValues={{
            name: "",
            email: "",
            password: "",
            roleName: null,
            industry: [],
            functions: [],
          }}
          // onFinish={onFinish}
          requiredMark={false}
          size="large"
          className="form-container add-role-form">
          <div className="container2">
            <div className="form-inner">
              <p>
                Changing a prompt can significantly alter the functionality, performance and
                accuracy of the platform,
              </p>
              <h4>Please be sure before editing.</h4>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default WarningSystemPrompts;
