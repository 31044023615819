import {
  IMappedIndustry,
  IMappedIndustryChildren,
} from "../store/contentManagement/content.interface";
import {
  ITaxonomyByTitle,
  ITaxonomyTree,
  ITaxonomyTreeList,
} from "../store/taxonomy/taxonomy.interface";
import { isEmpty } from "./helpers";

export const mapDataToTreeSelect = (data: ITaxonomyTree[]): IMappedIndustryChildren[] => {
  return data.map((item) => {
    const node: IMappedIndustryChildren = {
      value: String(item.taxonomyNodeId),
      title: item.taxonomyNodeName,
      id: item.taxonomyNodeId,
    };

    if (item.children && item.children.length > 0) {
      node.children = mapDataToTreeSelect(item.children);
    }

    return node;
  });
};

// TODO: Need to remove this
// export const mapTaxonomyToTreeSelect = (data: ITaxonomyTreeList[]): IMappedIndustryChildren[] => {
//   return data.map((item) => {
//     const node: IMappedIndustryChildren = {
//       value: String(item.taxonomyNodeId),
//       title: item.taxonomyNodeName,
//       id: item.taxonomyNodeId,
//     };

//     if (item.children && item.children.length > 0) {
//       node.children = mapTaxonomyToTreeSelect(item.children);
//     }

//     return node;
//   });
// };

export const findNodeNameByValue = (data: IMappedIndustry[], value: string): string => {
  for (const item of data) {
    if (item.value === value) {
      return item.title;
    }
    if (item.children) {
      const result = findNodeNameByValue(item.children, value);
      if (result) {
        return result;
      }
    }
  }
  return "";
};

export function findParentNodesByValue(value: string, mappedTags: IMappedIndustry[]) {
  let parents: any[] = [];

  function traverse(node: IMappedIndustry, parentPath: IMappedIndustry[]) {
    if (node.value === value) {
      parents.push(parentPath);
    }
    if (node.children) {
      node.children.forEach((child: IMappedIndustry) => {
        traverse(child, [...parentPath, node]);
      });
    }
  }

  for (let item of mappedTags) {
    traverse(item, []);
  }
  if (parents && parents.length) {
    parents = parents[0];
  }

  return parents;
}

export function findParentNodesByValueForView(value: number, mappedTaxonomyTree: any) {
  let parents: any[] = [];

  function traverse(node: ITaxonomyTree, parentPath: ITaxonomyTree[]) {
    if (node.taxonomyNodeId === value) {
      parents.push(parentPath);
    }
    if (node.children) {
      node.children.forEach((child: ITaxonomyTree) => {
        traverse(child, [...parentPath, node]);
      });
    }
  }

  for (let item of mappedTaxonomyTree) {
    traverse(item, []);
  }
  if (parents && parents.length) {
    parents = parents[0];
  }

  return parents;
}

export const findNodeNameByValueForView = (data: ITaxonomyTree[], value: number): string => {
  for (const item of data) {
    if (item.taxonomyNodeId === value) {
      return item.taxonomyNodeName;
    }
    if (item.children) {
      const result = findNodeNameByValueForView(item.children, value);
      if (result) {
        return result;
      }
    }
  }
  return "";
};

export const findNodeNameByValueForAccessGroupTaxonomy = (
  data: ITaxonomyTreeList[],
  value: number
): string => {
  for (const item of data) {
    if (item.taxonomyNodeId === value) {
      return item.taxonomyNodeName;
    }
    if (item.children) {
      const result = findNodeNameByValueForAccessGroupTaxonomy(item.children, value);
      if (result) {
        return result;
      }
    }
  }
  return "";
};

export const getFormattedTaxonomyForMapData = (allTaxonomy: ITaxonomyByTitle[]) => {
  let formattedTaxonomy: ITaxonomyTree[] = [];

  if (!isEmpty(allTaxonomy)) {
    allTaxonomy?.forEach((item) => {
      formattedTaxonomy.push({
        children: item?.taxonomyTree,
        description: item?.purpose,
        taxonomyNodeId: item?.taxonomyId || -1,
        taxonomyNodeLevel: item?.levels,
        taxonomyNodeName: item?.title,
      });
    });
  }

  return formattedTaxonomy;
};
