import { useRef } from "react";
import { Button, Popover } from "antd";
import { IRolesListRes } from "../../../store/roleManagement/roleManage.interface";
import { useClickOutsideModal } from "../../../shared";

interface IPermissionModal {
  isOpen: boolean;
  setModalClose(value: number): void;
  selectedRole: IRolesListRes | null;
}

const PermissionModal = (props: IPermissionModal) => {
  const { isOpen, setModalClose, selectedRole } = props;

  const moreDescriptionRef = useRef(null);
  useClickOutsideModal(moreDescriptionRef, () => setModalClose(-1), isOpen);

  const handleCloseDescription = () => {
    setModalClose(-1);
  };

  return (
    <Popover
      overlayClassName="custom-description-body"
      trigger="click"
      open={isOpen}
      content={
        <div className="popover-content-container" ref={moreDescriptionRef}>
          <span className="permissions-text">
            {selectedRole?.privileges.map((item, index) => {
              return (
                <span key={index}>
                  {item?.label}
                  {selectedRole?.privileges?.length - 1 !== index && `, `}
                </span>
              );
            })}
          </span>
          <div className="des-btn">
            <Button style={{ marginRight: "10px" }} type="text" onClick={handleCloseDescription}>
              Close
            </Button>
          </div>
        </div>
      }
      overlayStyle={{ width: "600px" }}
      arrow={false}
      placement="bottomRight"
    />
  );
};

export default PermissionModal;
