import { useState, useRef, ChangeEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import TextArea from "antd/es/input/TextArea";
import {
  Modal,
  Form,
  Input,
  Card,
  Button,
  Progress,
  Checkbox,
  GetProp,
  Tooltip,
  CheckboxProps,
} from "antd";
import "./UploadAssets.scss";
import UploadMedia from "./uploadMedia";
import { RootState } from "../../store";
import { uploadAsset } from "../../services/contentManagement";
import { mapDataToTreeSelect } from "../../shared/taxonomyHelper";
import { ITaxonomyByTitle } from "../../store/taxonomy/taxonomy.interface";
import EditContentTags from "../viewEditContentManagement/EditContentTags/EditContentTags";
import {
  AiOptions,
  FileLocation,
  PATHS,
  IMAGES,
  SurveyFileType,
  UploadAssetState,
  findParentsNodeNameById,
  getUserId,
  isEmpty,
  IUploadFormProps,
  getMultipartFileName,
  isFileTYpeVideo,
  isFileTypeAudio,
  isFileTypeExcel,
} from "../../shared";
import {
  contentFilterField,
  setUpdateContentFilter,
} from "../../store/contentManagement/contentSlice";

interface IUploadAssets {
  isModalOpen: boolean;
  setIsModalOpen: (isModalOpen: boolean) => void;
  setSelectedFiles?: (file: File) => void;
  fromWhere: string;
  toWhere?: string;
}

const UploadAssets = ({
  isModalOpen,
  setIsModalOpen,
  toWhere = PATHS.myContent,
}: IUploadAssets) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { findProfile } = useSelector((state: RootState) => state.app);
  const { assetTypeTaxonomy, allTaxonomy, industryTaxonomy } = useSelector(
    (state: RootState) => state.taxonomy
  );

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [fileType, setFileType] = useState<string>("");
  const [_, setIsShowSurvey] = useState<boolean>(false);
  const [survey, setSurvey] = useState<boolean>(false);
  const [runVision, setRunVision] = useState<boolean>(false);
  const [__, setIsShowAutoSummarize] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [autoSummarize, setAutoSummarize] = useState<boolean>(false);
  const [aiSelectedValues, setAiSelectedValues] = useState<any[]>([]);
  const [proceedLoading, setProceedLoading] = useState<boolean>(false);
  const [isShowRunVision, setIsShowRunVision] = useState<boolean>(false);
  const [fileLocation, setFileLocation] = useState<FileLocation>(FileLocation.ORGANISATION);
  const [uploadingState, setUploadingState] = useState<UploadAssetState>(UploadAssetState.INITIAL);

  const [localFilters, setLocalFilters] = useState<{ [key: string]: string[] }>();

  useEffect(() => {
    findProfile && setAiSelectedValues(["SUMMARIZE", "VISION_MODEL"]);
    findProfile && form.setFieldValue("assetType", "517");
  }, [findProfile]);

  const aiOptions = [
    // {
    //   label: "Auto Summarize",
    //   value: AiOptions.SUMMARIZE,
    //   icon: IMAGES.icon1,
    //   tooltip: "Automatically generate concise summaries of your uploaded documents.",
    //   disabled: !isShowAutoSummarize,
    // },
    // {
    //   label: "AutoTag Taxonomy",
    //   value: AiOptions.AUTO_TAG_TAXONOMY,
    //   icon: IMAGES.icon2,
    //   tooltip:
    //     "Automatically assign relevant tags and categories to your content for better organization.",
    //   disabled: true,
    // },
    {
      label: "Process using Vision Models",
      value: AiOptions.VISION_MODEL,
      icon: IMAGES.icon3,
      tooltip: "Analyze images and extract meaningful information using advanced vision AI models.",
      disabled: !isShowRunVision,
    },
  ];

  const handleClose = () => {
    form.resetFields();
    setFileName("");
    setIsModalOpen(false);
    setFile(null);
    handleRemoveFile();
    setRunVision(false);
    setFileType("");
    setIsShowRunVision(false);
    setUploadingState(UploadAssetState.INITIAL);
    setIsShowSurvey(false);
    setSurvey(false);
    setIsShowAutoSummarize(false);
    setAutoSummarize(false);
    setFileLocation(FileLocation.ORGANISATION);
    setAiSelectedValues([]);
    setUploadProgress(0);
  };

  const handleClickUploadFile = () => {
    if (fileInputRef.current != null) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    setRunVision(false);
    setIsShowRunVision(true);

    if (e.target.files) {
      const file = e.target.files[0];
      const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);

      setFileType(fileType);
      setFile(file);
      setFileName(file.name);
      setUploadingState(UploadAssetState.FILE);
      setIsShowSurvey(SurveyFileType.includes(fileType));
      setIsShowAutoSummarize(true);
      form.setFieldsValue({
        title: file?.name,
      });
    }
  };

  const handleRemoveFile = () => {
    setUploadingState(UploadAssetState.INITIAL);
    setFileName("");
    setIsShowRunVision(false);
    setRunVision(false);
    setIsShowSurvey(false);
    setSurvey(false);
    setAiSelectedValues([]);
    setFileLocation(FileLocation.ORGANISATION);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    form.setFieldsValue({
      title: "",
    });
  };

  const getParentsNodeNameBySelectIds = (linkedTagIds: number[]) => {
    let parentsNodeNameBySelectIds: string[] = [];

    if (linkedTagIds && linkedTagIds?.length) {
      linkedTagIds.forEach((id) => {
        const nodeNameById: string[] = findParentsNodeNameById(
          industryTaxonomy?.taxonomyTree ?? [],
          id
        );
        if (nodeNameById && nodeNameById?.length) {
          parentsNodeNameBySelectIds.push(...nodeNameById);
        } else {
          const assetTypeNodeNameById: string[] = findParentsNodeNameById(
            assetTypeTaxonomy?.taxonomyTree ?? [],
            id
          );
          if (assetTypeNodeNameById && assetTypeNodeNameById?.length) {
            parentsNodeNameBySelectIds.push(...assetTypeNodeNameById);
          }
        }
      });
      parentsNodeNameBySelectIds = [...new Set(parentsNodeNameBySelectIds)];
    }

    return parentsNodeNameBySelectIds;
  };

  const MAX_FILE_SIZE = 1024 * 1024 * 1024; // 1GB in bytes
  const MAX_FILE_DURATION = 3 * 60 * 60; // 3 hours in seconds

  const validateFile = (file: File): Promise<{ valid: boolean }> => {
    return new Promise((resolve) => {
      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size exceeds 1GB limit.");
        resolve({ valid: false });
        return;
      }

      const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);

      if (isFileTYpeVideo(fileType) || isFileTypeAudio(fileType)) {
        // Create a video or audio element to check the duration
        const fileUrl = URL.createObjectURL(file);
        const mediaElement = document.createElement(
          file.type.startsWith("video") ? "video" : "audio"
        );

        mediaElement.src = fileUrl;

        mediaElement.onloadedmetadata = () => {
          const duration = mediaElement.duration;

          URL.revokeObjectURL(fileUrl); // Clean up object URL

          if (duration > MAX_FILE_DURATION) {
            toast.error("File duration exceeds 3-hour limit.");
            resolve({ valid: false });
          } else {
            resolve({ valid: true });
          }
        };
        // Error handling if metadata cannot be loaded
        mediaElement.onerror = () => {
          toast.error("Error loading media file.");
          resolve({ valid: false });
        };
      } else {
        resolve({ valid: true });
      }
    });
  };

  const onFinish = async () => {
    const values = form.getFieldsValue();

    const extractValuesAsNumbers = (data: { [key: string]: string[] }): number[] => {
      return Object.values(data).flat().map(Number);
    };

    let linkedTagIds = localFilters ? extractValuesAsNumbers(localFilters) : [];

    if (values?.assetType) {
      linkedTagIds.push(Number(values?.assetType));
    }
    const tags = getParentsNodeNameBySelectIds(linkedTagIds);

    setProceedLoading(true);
    setIsUploading(true);
    setUploadProgress(0);

    if (!!file) {
      const checkValidation = await validateFile(file);
      if (checkValidation?.valid) {
        const formData = new FormData();
        formData.append("file", file);
        const formParams: IUploadFormProps = {
          formData,
          userId: getUserId(),
          title: !isEmpty(values?.title) ? values?.title : "",
          summary: !isEmpty(values?.summary) ? values?.summary : "",
          fileType: fileType,
          fileLevel: fileLocation,
          visionProcessing: runVision,
          isSurveyData: survey,
          generateSummary: autoSummarize,
          linkedTagIds: findProfile ? [517] : linkedTagIds,
          tags: findProfile ? ["Resume"] : tags,
        };
        let res;
        if (file.size > 100 * 1024 * 1024) {
          // Uploading file grater than 100 mb
          const FileName = getMultipartFileName(file?.name);
          await UploadMedia(file, FileName, fileType, setUploadingState, setUploadProgress);
          res = await uploadAsset(
            formParams,
            navigate,
            setUploadingState,
            setUploadProgress,
            FileName,
            true,
            "",
            toWhere
          );
        } else {
          //Uploading file less than 100 mb
          res = await uploadAsset(
            formParams,
            navigate,
            setUploadingState,
            setUploadProgress,
            "",
            false,
            "",
            toWhere
          );
        }
        if (!isEmpty(res)) {
          dispatch(setUpdateContentFilter({ field: contentFilterField?.currentPage, value: 1 }));
        }
        setIsModalOpen(false);
      }
    } else {
      setUploadingState(UploadAssetState.FAILED);
      toast.error("Failed to upload asset");
    }
    setIsUploading(false);
    setProceedLoading(false);
    handleRemoveFile();
    handleClose();
  };

  const renderAssetUploadingState = (fileName: string) => {
    return (
      <div className="uploading-field">
        <p className="upload-status">
          {uploadingState === UploadAssetState.FILE
            ? "Selected file"
            : uploadProgress < 100
            ? "Uploading..."
            : "Uploaded"}
        </p>
        <div>
          {uploadingState === UploadAssetState.FILE ? (
            <>
              <div className="file-container">
                <span>
                  <i className="ri-attachment-line browseIcon" />
                  <span>{fileName}</span>
                </span>

                <div className="btn-wrapper">
                  <Button type="text" className="text-btn remove-file" onClick={handleRemoveFile}>
                    <i className="ri-delete-bin-fill" />
                  </Button>
                </div>
              </div>
            </>
          ) : uploadingState === UploadAssetState.UPLOADING ? (
            <Progress percent={uploadProgress} />
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  const fileLocationChange: CheckboxProps["onChange"] = (e) => {
    if (e?.target?.checked === true) {
      setFileLocation(FileLocation.USER);
    } else {
      setFileLocation(FileLocation.ORGANISATION);
    }
  };

  const handleSelectNodes = (selectedNodes: string[], title: string) => {
    let ids: string[] = [];

    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node: any) => {
        ids.push(node?.value);
      });
    }
    setLocalFilters((prev) => ({ ...prev, [title]: ids }));
    form.setFieldsValue({ [title]: ids });
  };

  const aiOptionsChange: GetProp<typeof Checkbox.Group, "onChange"> = (checkedValues: any[]) => {
    setAiSelectedValues(checkedValues);
    checkedValues.includes(AiOptions.SUMMARIZE) ? setAutoSummarize(true) : setAutoSummarize(false);
    checkedValues.includes(AiOptions.VISION_MODEL) ? setRunVision(true) : setRunVision(false);
  };

  const checkIsVisionAvailable = (): boolean => {
    if (fileType) {
      if (isFileTYpeVideo(fileType) || isFileTypeAudio(fileType) || isFileTypeExcel(fileType))
        return false;
      else return true;
    }
    return false;
  };

  const renderFileUpload = () => {
    return (
      <Form
        form={form}
        name="uploadFile"
        initialValues={{
          file: null,
          title: "",
          assetType: null,
          summary: "",
          industrys: [],
          functions: [],
          aiOptions: [],
        }}
        onFinish={onFinish}
        requiredMark={true}
        size="large"
        className="form-container add-user-form">
        <Card className="input-card">
          <p className="input-label">Upload File</p>
          <div>
            <input
              ref={fileInputRef}
              className="file-input"
              type="file"
              multiple={false}
              placeholder="Upload Asset"
              onChange={handleFileUpload}
            />
            <div className="upload-file-container">
              <div className="file-name">
                <span>
                  <i className="ri-attachment-line browseIcon"></i>
                  <span>Browse</span>
                </span>
              </div>

              <Button
                onClick={handleClickUploadFile}
                className="btn-primary fill-button btn-type2"
                type="primary"
                size="middle"
                disabled={isUploading}>
                Browse
              </Button>
            </div>
          </div>

          <div className="file-upload-extra">
            {uploadingState === UploadAssetState.FILE ||
            uploadingState === UploadAssetState.UPLOADING ||
            uploadingState === UploadAssetState.UPLOADED
              ? renderAssetUploadingState(fileName)
              : ""}

            <Checkbox
              checked={fileLocation === FileLocation.USER}
              disabled={uploadingState === UploadAssetState.UPLOADING}
              onChange={fileLocationChange}
              className="margin-bottom">
              Only for me
            </Checkbox>

            <Form.Item
              label="Title"
              rules={[{ required: true, message: "Please enter title" }]}
              name="title"
              labelCol={{ span: 24 }}>
              <Input placeholder="Add Title" autoComplete="off" />
            </Form.Item>

            {!findProfile && (
              <>
                {allTaxonomy
                  ?.filter((v) => v?.mandatory)
                  .map((v: ITaxonomyByTitle) => {
                    return (
                      <Form.Item
                        label={v?.title || ""}
                        name={v?.title || ""}
                        labelCol={{ span: 24 }}
                        rules={[{ required: true, message: `Please enter ${v?.title}` }]}
                        className="select-tags-input-wrap"
                        key={uuidv4()}>
                        <EditContentTags
                          maxTagCountnumber={2}
                          selectedTags={localFilters?.[v?.title] || []}
                          mappedContentTags={mapDataToTreeSelect(v?.taxonomyTree)}
                          popupClassName="ui-choose-select"
                          placeholder={`Select ${v?.title}`}
                          handleChange={(e) => handleSelectNodes(e, v?.title)}
                          className="select-dropdown-multiple"
                          isNonLeafNodeDisable={false}
                          treeCheckStrictly={true}
                        />
                      </Form.Item>
                    );
                  })}

                <Form.Item label="Summary" name="summary" labelCol={{ span: 24 }}>
                  <TextArea
                    placeholder="Leave summary blank to allow AI to auto-generate."
                    autoComplete="off"
                  />
                </Form.Item>

                {allTaxonomy
                  ?.filter((v) => !v?.mandatory)
                  .map((v: ITaxonomyByTitle) => {
                    return (
                      <Form.Item
                        label={v?.title || ""}
                        name={v?.title || ""}
                        labelCol={{ span: 24 }}
                        className="select-tags-input-wrap"
                        key={uuidv4()}>
                        <EditContentTags
                          maxTagCountnumber={2}
                          selectedTags={localFilters?.[v?.title] || []}
                          mappedContentTags={mapDataToTreeSelect(v?.taxonomyTree)}
                          popupClassName="ui-choose-select"
                          placeholder={`Select ${v?.title}`}
                          handleChange={(e) => handleSelectNodes(e, v?.title)}
                          className="select-dropdown-multiple"
                          isNonLeafNodeDisable={false}
                          treeCheckStrictly={true}
                        />
                      </Form.Item>
                    );
                  })}

                {checkIsVisionAvailable() && (
                  <Form.Item name="aiOptions" labelCol={{ span: 24 }} className="ai-action-items">
                    <Checkbox.Group
                      className="ai-options"
                      value={aiSelectedValues}
                      onChange={aiOptionsChange}>
                      {aiOptions.map((item, index: number) => (
                        <Checkbox key={index} {...item}>
                          <img src={item.icon} className="ai-check-icon" alt="Icon" />
                          {item.label}
                          <Tooltip title={item.tooltip} className="cursor-pointer">
                            <i className="ri-information-line" />
                          </Tooltip>
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                )}
              </>
            )}
          </div>
        </Card>
        <div className="ant-modal-footer">
          <Button onClick={handleClose} className="btn" type="default" size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={proceedLoading}
            htmlType="submit"
            className="btn"
            type="primary"
            disabled={
              uploadingState === UploadAssetState.INITIAL ||
              uploadingState === UploadAssetState.FAILED
            }>
            Proceed
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <Modal
      open={isModalOpen}
      onCancel={handleClose}
      title={findProfile ? "Upload Resume" : "Upload Asset"}
      centered
      width={500}
      className="asset-upload-form-container"
      footer={false}>
      <div className="container upload-asset-modal">
        <div>{renderFileUpload()}</div>
      </div>
    </Modal>
  );
};

export default UploadAssets;
