import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Popover,
  Row,
  Switch,
  Table,
  Modal,
  MenuProps,
  Dropdown,
} from "antd";
import "./ContentManagement.scss";
import { RootState } from "../../../store";
import { setViewType } from "../../../store/appState/appSlice";
import { getTaxonomyByTitle } from "../../../services/taxonomy";
import { isAdminFilterEmpty } from "../../../shared/helper/contentHelper";
import { ITaxonomyTree } from "../../../store/taxonomy/taxonomy.interface";
import { setIsPDFModalOpen } from "../../../store/pdfViewer/pdfViewer.slice";
import { setSelectedFilesForAssetFilter } from "../../../store/conversationalSearch/conversationalSearchSlice";
import {
  AssetTags,
  AssetUploadStatus,
  BookmarkIcon,
  ContentSourceFilter,
  CustomPagination,
  FileCardType,
  FromWhere,
  NoContentData,
  PATHS,
  PageLimit,
  ViewType,
  getFormattedDate,
  AssetSummaryPopover,
  getIsDisableEditContent,
  getIsContentFailed,
  isEmpty,
  getIsSummaryProcessing,
  getIsAssetProcessing,
  PDFViewer,
  renderFileTypeIcon,
  getCardOwnersFullName,
  AssetStatus,
  TaxonomyTitle,
  renderSourceType,
  ImageViewer,
  handleOpenDocClick,
  checkPlatformPermission,
  Permissions,
} from "../../../shared";
import {
  ContentActionButton,
  DeleteModal,
  FileCard,
  FileCardSkeleton,
  PageHeader,
  TableSkeleton,
  UploadAssets,
} from "../../../components";
import {
  bookmarkContent,
  deleteAssetById,
  disablePublish,
  enablePublish,
  getAllContent,
  getAssetDetailsByAssetId,
  likeContent,
  retryProcess,
  stopProcessing,
  updateViewCount,
} from "../../../services/contentManagement";
import {
  IContent,
  IContentFilter,
  ITagsByAssestId,
} from "../../../store/contentManagement/content.interface";
import {
  contentByIdInitialValue,
  contentFilterField,
  setAllContentDetails,
  setContentById,
  setSelectedAllTagsByAssestId,
  setUpdateAdminContentFilter,
  setUpdatePublishDraft,
  tagsByAssestIdInitValue,
  setUpdateAllAdminContentFilter,
  clearAdminContentFilterWithoutBookmark,
  updateAssetByAssetId,
  adminFilterInitValue,
  setSelectionAppliedForSources,
  setSelectContentDetails,
  setContentFilesInContext,
  setContentSelectedIds,
} from "../../../store/contentManagement/contentSlice";

const Sources = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { viewType } = useSelector((state: RootState) => state.app);
  const {
    contents,
    isContentTableLoading,
    totalContentsCount,
    contentDeleteLoading,
    contentFilesInContext,
    adminContentFilter,
    isRetryLoading,
  } = useSelector((state: RootState) => state.content);
  const { isPDFModalOpen, pdfContent } = useSelector((state: RootState) => state.pdfViewer);

  const isDataPersist = location?.state?.isDataPersist || false;
  const currentPage = adminContentFilter?.currentPage ?? 1;

  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState<boolean>(false);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [pendingContents, setPendingContents] = useState<IContent[]>([]);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);
  const [localFilters, setLocalFilters] = useState<IContentFilter>(adminFilterInitValue);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  let contentInterval: any;

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (contents && contents.length) {
        contents.forEach((content) => {
          if (
            getIsAssetProcessing(content?.assetStatus) ||
            content?.assetStatus === AssetUploadStatus.STOPPING ||
            getIsSummaryProcessing(content?.summaryStatus)
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  const resetIsDataPersistRoute = () => {
    navigate(PATHS.adminContentManagement, { state: { isDataPersist: false } });
  };

  useEffect(() => {
    if (!isDataPersist) {
      getTaxonomyByTitle(TaxonomyTitle.AssetType);
      getTaxonomyByTitle(TaxonomyTitle.Industry);
      getTaxonomyByTitle(TaxonomyTitle.Function);
      getAllContent(adminContentFilter, false);
    } else {
      resetIsDataPersistRoute();
    }

    return () => {
      dispatch(setContentFilesInContext([]));
      dispatch(setContentSelectedIds([]));
    };
  }, []);

  const handleContentPulling = () => {
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }
  };

  useEffect(() => {
    handleContentPulling();

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  useEffect(() => {
    localFilters?.isFilterVisible
      ? document.body.classList.add("content-filter-visible")
      : document.body.classList.remove("content-filter-visible");
  }, [localFilters]);

  const getIsShowStopBtn = (content: IContent) =>
    [
      String(AssetUploadStatus.UPLOADED_TO_DO),
      AssetUploadStatus.PROCESSING,
      AssetUploadStatus.SENT_TO_LLM,
      AssetUploadStatus.CONVERTED_TO_PDF,
    ].includes(content.assetStatus);

  const getStopBtnLabel = (content: IContent) => {
    let label = "";

    if (
      [
        String(AssetUploadStatus.UPLOADED_TO_DO),
        AssetUploadStatus.PROCESSING,
        AssetUploadStatus.SENT_TO_LLM,
        AssetUploadStatus.CONVERTED_TO_PDF,
      ].includes(content.assetStatus)
    ) {
      label = "Stop";
    } else if (AssetUploadStatus.STOPPING === content.assetStatus) {
      label = "Stopping";
    }

    return label;
  };

  const getIsDisableStopBtn = (content: IContent) =>
    AssetUploadStatus.STOPPING === content.assetStatus;

  const handleViewEditContentNavigate = (assetId: number, isEditMode: boolean) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(
      setUpdateAdminContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );
    updateViewCount(assetId);

    const content = contents.find((e) => e.asset_id === assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: content ? getIsContentFailed(content) : false,
        fromWhere: FromWhere.AdminContentManagement,
      },
    });
  };

  const findNodeNameByValue = (data: ITaxonomyTree[], value: number): string => {
    for (const item of data) {
      if (item.taxonomyNodeId === value) {
        return item.taxonomyNodeName;
      }
      if (item.children) {
        const result = findNodeNameByValue(item.children, value);
        if (result) {
          return result;
        }
      }
    }
    return "";
  };

  const handlePublishedValidation = (content: IContent) => {
    let flag = false;
    let messageList = [];
    const assetType = content?.linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];

    if (getIsAssetProcessing(content?.assetStatus)) {
      toast.error("Unable to publish as document is still under AI processing");
      return true;
    }

    if (!content?.title || content?.title.trim() === "") {
      messageList.push("Title");
      flag = true;
    }
    if (!content?.summary || content?.summary.trim() === "") {
      messageList.push("Summary");
      flag = true;
    }
    if (!assetType.length) {
      messageList.push("Asset type");
      flag = true;
    }

    if (messageList && messageList.length) {
      let message = "";
      messageList.forEach((item, index) => {
        message =
          message +
          `${
            index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
          }${item}`;
      });

      if (message) {
        message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
        toast.error(message);
      }
    }

    return flag;
  };

  const renderAssetStatus = (isPublished: boolean | string, content: IContent, index: number) => {
    return (
      <Switch
        value={["TRUE", true].includes(isPublished) ? true : false}
        disabled={getIsContentFailed(content)}
        onChange={() => {
          if (handlePublishedValidation(content)) return;

          const isPublishedValue = ["TRUE", true].includes(isPublished) ? true : false;
          if (!isPublishedValue) {
            enablePublish(content?.asset_id);
          } else {
            disablePublish(content?.asset_id);
          }
          dispatch(setUpdatePublishDraft(index));
        }}
      />
    );
  };

  const handleRetry = async (content: IContent, index: number) => {
    if (content?.asset_id) {
      const res = await retryProcess(content?.asset_id);

      if (res?.data) {
        const updatedContent = {
          ...content,
          assetStatus: res?.data?.assetStatus || "",
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const handleStopProcessing = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      const res = await stopProcessing(content?.asset_id);

      if (res?.data) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.STOPPING,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const getItems = (content: IContent, index: number) => {
    let items: MenuProps["items"] = [];

    if (checkPlatformPermission(Permissions.EDIT_DOC)) {
      items.push({
        key: 1,
        label: (
          <div
            onClick={() => {
              handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
            }}>
            Edit
          </div>
        ),
        className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
      });
    }

    if (checkPlatformPermission(Permissions.DELETE_DOC)) {
      items.push({
        key: 2,
        label: (
          <div
            onClick={() => {
              if (getIsShowStopBtn(content)) {
                handleStopProcessing(selectedContent, index);
              } else {
                setIsShowAssetDeleteModal(true);
              }
            }}
            className="taxonomyDeleteText">
            {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
          </div>
        ),
        className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
      });
    }

    if (
      [String(AssetUploadStatus.FAILED), AssetUploadStatus.FAILED_IN_LLM].includes(
        content?.assetStatus
      ) &&
      viewType === ViewType.GRID
    ) {
      items.push({
        key: 3,
        label: (
          <div onClick={() => handleRetry(content, index)} className="taxonomyDeleteText">
            Retry
          </div>
        ),
      });
    }

    return items;
  };

  const handleDeleteSource = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        totalContentsCount,
        false,
        adminContentFilter,
        "sources"
      );
      if (res?.data) {
        let contentsValues = [...contents];
        contentsValues = !isEmpty(contentsValues)
          ? contentsValues?.filter((item) => item.asset_id !== selectedContent?.asset_id)
          : [];
        dispatch(setAllContentDetails(contentsValues));

        const filteredContext = contentFilesInContext.filter(
          (c) => selectedContent?.asset_id !== c.asset_id
        );
        dispatch(setSelectedFilesForAssetFilter(filteredContext));
      }
    }

    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    dispatch(
      setUpdateAdminContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );
  };

  const handleSelectAllContents = (e: any) => {
    if (contents && contents.length) {
      const filteredContents = [...contents].map((item) => ({
        ...item,
        isSelected: e?.target?.checked,
        // isSelected: getIsDisableEditContent(item) ? false : e?.target?.checked,  // All assets should select
      }));
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(filteredContents));
    }
  };

  const getEditableContents = () => {
    let editableContents: number[] = [];
    if (contents && contents.length) {
      editableContents = contents
        // .filter((item) => !getIsDisableEditContent(item)) // All assets should select
        .map((item) => item?.asset_id);
    }
    return editableContents || [];
  };

  const getSelectedContents = () => {
    let selectedContents: number[] = [];
    if (contents && contents.length) {
      selectedContents = contents
        .filter((item) => item?.isSelected)
        // .filter((item) => !getIsDisableEditContent(item) && item?.isSelected) // All assets should select
        .map((item) => item?.asset_id);
    }
    return selectedContents || [];
  };

  const rowSelection = {
    selectedRowKeys: getSelectedContents(),
    onChange: (selectedRowKeys: any[]) => {
      let updatedContent: IContent[] = [];
      if (contents && contents?.length) {
        contents.forEach((item) => {
          if (selectedRowKeys.includes(item?.asset_id)) {
            updatedContent.push({ ...item, isSelected: true });
          } else {
            updatedContent.push({ ...item, isSelected: false });
          }
        });
      }
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(updatedContent));
    },
    // getCheckboxProps: (record: IContent) => ({  // All assets should select
    //   disabled: getIsDisableEditContent(record),
    // }),
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const res = await bookmarkContent(content.asset_id, content?.is_bookmarked ? false : true);
    if (res) {
      const updatedContent = {
        ...content,
        is_bookmarked: !content?.is_bookmarked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handleLike = async (
    content: IContent,
    index: number,
    isLiked: boolean | null,
    flag: string
  ) => {
    const res = await likeContent(content.asset_id, isLiked, flag);
    if (res) {
      const updatedContent = {
        ...content,
        is_liked: isLiked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handlePagination = (page: number) => {
    dispatch(setUpdateAllAdminContentFilter({ ...adminContentFilter, currentPage: page }));
    getAllContent({ ...adminContentFilter, currentPage: page }, false);
  };

  const fileHoverOptions = (content: IContent, index: number) => {
    return (
      <>
        <Button
          className={content?.is_liked === true ? "btn active" : "btn"}
          type="text"
          onClick={() =>
            handleLike(content, index, content?.is_liked === true ? null : true, "like")
          }
          icon={<i className="ri-thumb-up-fill"></i>}></Button>
        <Button
          className={content?.is_liked === false ? "btn active" : "btn"}
          type="text"
          onClick={() =>
            handleLike(content, index, content?.is_liked === false ? null : false, "dislike")
          }
          icon={<i className="ri-thumb-down-fill"></i>}></Button>
      </>
    );
  };

  const threeDotsMenu = (content: IContent, index: number) => {
    return (
      <Dropdown
        trigger={["click"]}
        menu={{
          onClick: () => {
            setSelectedContent(content);
          },
          items: getItems(content, index),
        }}
        open={content.asset_id === selectedContent?.asset_id && isDropdownOpen}
        onOpenChange={(open) => setIsDropdownOpen(open)}
        overlayClassName="table-action-dropdown content-dropdown"
        placement="bottomRight">
        <Button className="custom-icon more-action" onClick={() => setSelectedContent(content)}>
          <i className="ri-more-2-fill"></i>
        </Button>
      </Dropdown>
    );
  };

  const fileExtraOptions = (content: IContent, index: number) => {
    return (
      <>
        {threeDotsMenu(content, index)}
        {/* <div className="link-btn" onClick={() => handleContentDetail(content)}>
          <i className="ci small-external-link"></i>
        </div> */}
      </>
    );
  };

  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isContentTableLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const renderOwnerUI = (data: IContent) => {
    return (
      <div className="author-info">
        <div className="profile-pic">
          {/* if user pic is true */}
          {/* <img src={IMAGES.avatarDefault} alt="profile-pic" className="profile-pic" /> */}

          {/* else */}
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
        <span className="author-name">{getCardOwnersFullName(data?.user)}</span>
      </div>
    );
  };

  const tableColumns: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "240px",
      className: "title-table-col",
      render: (text: string, content: IContent) => (
        <>
          <span className="td-label">Title : </span>
          <Popover
            placement="bottomLeft"
            rootClassName="custom-description-root"
            content={
              <AssetSummaryPopover
                content={content}
                tags={false}
                fromWhere={FromWhere.AdminContentManagement}
              />
            }
            trigger="hover">
            <strong
              className={`${"cursor-pointer title-col float-title d-flex d-flex-middle"} `}
              onClick={() => handlePDFClick(content)}>
              {/* <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" /> */}
              <div
                className="file-bg"
                style={{ backgroundImage: `url(${content?.thumbnail_pre_signed_url})` }}></div>
              {text ? text : content?.original_file_name}
            </strong>
          </Popover>
        </>
      ),
    },
    {
      title: "Type",
      key: "docType",
      width: "110px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Type : </span>
          <span className="doc-type">
            <div className="profile-pic">
              <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" />
            </div>
            {content?.file_type}
          </span>
        </>
      ),
    },
    {
      title: "Source Type",
      key: "src_type",
      width: "110px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Type : </span>
          <span className="doc-type">{renderSourceType(content?.src_type)}</span>
        </>
      ),
    },
    {
      title: "Owner",
      key: "owner",
      width: "140px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Owner : </span>
          {renderOwnerUI(content)}
        </>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "modifiedOn",
      key: "modifiedOn",
      width: "120px",
      render: (date: string) => {
        return (
          <>
            <span className="td-label">Last Updated : </span>
            {getFormattedDate(date, "type1")}
          </>
        );
      },
    },
    // {
    //   title: "Summary",
    //   dataIndex: "summary",
    //   key: "summary",
    //   width: "300px",
    //   render: (text: string, content: IContent) => (
    //     <>
    //       <span className="td-label">Summary : </span>
    //       <span className="summary-span">
    //         {text?.length > ContentManage.InitSummaryLength && !content?.isShowFullSummary ? (
    //           <span className="content-manage-inner-span d-flex  d-flex-middle">
    //             <div className="content-manage-span">
    //               {text?.slice(0, ContentManage.InitSummaryLength)}
    //             </div>
    //             <span
    //               className="more"
    //               onClick={() => {
    //                 setSummaryDescirption(content?.summary);
    //                 setIsSummaryModal(content?.asset_id);
    //               }}>
    //               +more
    //             </span>

    //             <SummaryManagement
    //               isOpen={content?.asset_id === isSummaryModal}
    //               description={summaryDescription}
    //               setModalClose={handleSummaryModal}
    //             />
    //           </span>
    //         ) : (
    //           <span>{text}</span>
    //         )}
    //       </span>
    //     </>
    //   ),
    // },
    {
      title: "Tags",
      dataIndex: "linkedTags",
      key: "linkedTags",
      width: "200px",
      render: (linkedTags: ITagsByAssestId, content: IContent) => (
        <AssetTags {...{ linkedTags, content }} fromWhere={FromWhere.AdminContentManagement} />
      ),
    },
    {
      title: "Published",
      dataIndex: "isPublished",
      key: "isPublished",
      width: "130px",
      render: (isPublished: boolean | string, content: IContent, index: number) => (
        <>
          <span className="td-label">Published : </span>
          <span className="published-draft-label d-flex d-flex-middle d-flex-between">
            {renderAssetStatus(isPublished, content, index)}
          </span>
        </>
      ),
    },
    {
      title: "AI Status",
      key: "isPublished",
      width: "120px",
      render: (_: any, content: IContent, index: number) => (
        <>
          <span className="td-label">AI Status : </span>
          <span className="published-draft-label d-flex d-flex-middle">
            {/* {content?.assetStatus === AssetUploadStatus.COMPLETED &&
            (content.isPublished === true || content.isPublished === "TRUE") ? (
              <div className="asset-stat">
                <span className="published inner">Published</span>
              </div>
            ) : (
              <AssetStatus status={content?.assetStatus} isShowAllStatus={true} />
            )} */}
            <AssetStatus content={content} isShowAllStatus={true} />
            {(content?.assetStatus === AssetUploadStatus.FAILED ||
              content?.assetStatus === AssetUploadStatus.FAILED_IN_LLM) && (
              <Button
                className="table-retry"
                type="text"
                onClick={() => handleRetry(content, index)}>
                <i className={`ci ci-retry-icon ${isRetryLoading ? "round-animate" : ""}`} />
              </Button>
            )}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "80px",
      render: (_: any, content: IContent, index: number) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="extra-options">
              <div className="file-extra-options">
                {fileExtraOptionsTwo(content, index)}
                {threeDotsMenu(content, index)}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const tabDataGrid = () => {
    return (
      <>
        <div className="page-scroll">
          <Row className="tab-row-inner">
            {isContentTableLoading ? (
              <FileCardSkeleton />
            ) : contents?.length ? (
              contents.map((item: IContent, index: number) => (
                <Fragment key={`${index}-${item.asset_id}`}>
                  <FileCard
                    data={item}
                    type={FileCardType.UPLOADED}
                    isPopOver={item.asset_id === selectedContent?.asset_id ? false : true}
                    extraOptions={fileExtraOptions(item, index)}
                    extraOptionsTwo={fileExtraOptionsTwo(item, index)}
                    hoverOptions={fileHoverOptions(item, index)}
                    onClick={() => handlePDFClick(item)}
                    fromWhere={FromWhere.AdminContentManagement}
                  />
                </Fragment>
              ))
            ) : (
              <NoContentData />
            )}
          </Row>
        </div>
      </>
    );
  };

  const tabDataList = () => {
    return (
      <>
        <Row className="tab-row-inner list-view">
          <Col span={24}>
            <Card className="global-table-card  content-management-table-card">
              {isContentTableLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table"
                  rowKey={"asset_id"}
                  columns={tableColumns}
                  dataSource={contents?.length ? contents : []}
                  locale={{ emptyText: <NoContentData /> }}
                  tableLayout="fixed"
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  scroll={{ y: "calc(100vh - 235px)" }}
                  pagination={false}
                />
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  const handleBookmarkFilter = async (checked: boolean) => {
    if (typeof checked === "boolean") {
      dispatch(
        setUpdateAdminContentFilter({
          field: contentFilterField?.isBookmarked,
          value: checked,
        })
      );
      await getAllContent(
        {
          ...adminContentFilter,
          isBookmarked: checked,
        },
        false
      );
    }
  };

  const toggleFilter = () => {
    dispatch(
      setUpdateAllAdminContentFilter({
        ...localFilters,
        isFilterVisible: !localFilters?.isFilterVisible,
      })
    );
  };

  const handleResetFilter = async (isToastShow = true) => {
    dispatch(clearAdminContentFilterWithoutBookmark());
    await getAllContent(
      {
        ...adminFilterInitValue,
        isBookmarked: adminContentFilter?.isBookmarked,
      },
      false
    );
    isToastShow && toast.success("Filter successfully reset");
  };

  const handleApplyFilter = async (localFilters: IContentFilter) => {
    const res = await getAllContent(
      {
        ...localFilters,
        isBookmarked: adminContentFilter?.isBookmarked,
      },
      false
    );
    if (res) {
      dispatch(setUpdateAllAdminContentFilter(localFilters));
      dispatch(
        setUpdateAdminContentFilter({
          field: contentFilterField?.isApplied,
          value: true,
        })
      );
      dispatch(
        setUpdateAdminContentFilter({
          field: contentFilterField?.isFilterChanged,
          value: false,
        })
      );
      toast.success("Filter successfully applied");
    }
  };

  useEffect(() => {
    setLocalFilters(adminContentFilter);
  }, [adminContentFilter]);

  const handlePDFClick = (item: IContent) => {
    if (getIsAssetProcessing(item?.assetStatus)) {
      toast.error("Asset is under processing");
      return;
    } else if (["xls", "xlsx"].includes(item?.file_type || "")) {
      handleViewEditContentNavigate(item?.asset_id, false);
      return;
    }

    handleOpenDocClick(item);
  };

  const handlePDFClose = () => {
    dispatch(setIsPDFModalOpen(false));
  };

  const handleContentDetail = (content: IContent) => {
    return handleViewEditContentNavigate(content?.asset_id, false);
  };

  const isShowPagination = () => {
    return (
      contents && contents.length && (totalContentsCount || 1) / PageLimit.ContentManagement > 1
    );
  };

  return (
    <Flex
      className={`inner-app-wrap admin-content-management-page ${
        !isShowPagination() ? "no-pagination" : ""
      }`}
      vertical>
      <PageHeader
        title="Asset Management"
        isRelevantAsset={false}
        content={
          <>
            {/* <div onClick={handleAllBookmark}>
              <BookmarkIcon isActive={adminContentFilter?.isBookmarked ? true : false} />
            </div> */}

            <div className="bookmark-filter">
              <span className="text">Bookmarked</span>
              <Switch
                size="small"
                checked={adminContentFilter?.isBookmarked ? true : false}
                onChange={handleBookmarkFilter}
              />
            </div>

            <Button
              type="text"
              className="filter-toggle-btn"
              onClick={toggleFilter}
              icon={<i className="ri-equalizer-fill"></i>}>
              Filters
            </Button>

            {checkPlatformPermission(Permissions.UPLOAD_DOC) && (
              <Button
                className="btn upload-btn"
                type="primary"
                onClick={() => setIsOpenUploadAsset(true)}>
                Upload
              </Button>
            )}

            <div className="view-change">
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.GRID ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.GRID))}>
                <i className="ri-layout-grid-fill"></i>
              </Button>
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.LIST ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.LIST))}>
                <i className="ri-list-unordered"></i>
              </Button>
            </div>
          </>
        }
      />

      {localFilters?.isFilterVisible && (
        <motion.div
          className="content-source-filter"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}>
          <ContentSourceFilter
            isShowContentType={false}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            isFilterSelected={!isAdminFilterEmpty(localFilters)}
          />
        </motion.div>
      )}

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25 }}>
        <div className="relevant-assets-wrap">
          <Card className="relevant-assets">
            <div className="top-bar d-flex d-flex-middle d-flex-between">
              {contents && contents?.length > 0 && (
                <div className="ant-tabs-nav-container">
                  <Checkbox
                    className="select-label"
                    checked={getSelectedContents().length === getEditableContents().length}
                    indeterminate={
                      getSelectedContents().length &&
                      getSelectedContents().length < getEditableContents().length
                        ? true
                        : false
                    }
                    onChange={handleSelectAllContents}>
                    {getSelectedContents().length ? (
                      `${getSelectedContents().length} Out of ${contents?.length} selected`
                    ) : (
                      <>
                        Select All <span>(Showing {contents?.length} results)</span>
                      </>
                    )}
                  </Checkbox>
                </div>
              )}

              {checkPlatformPermission(Permissions.DELETE_DOC) && !isContentTableLoading && (
                <div className="ant-tabs-extra-content">
                  <div className="btn-wrap">
                    <ContentActionButton isShowChatIcon={false} />
                  </div>
                </div>
              )}
            </div>

            {viewType === ViewType.GRID ? tabDataGrid() : tabDataList()}
          </Card>
          {isShowPagination() ? (
            <div className="bottom-bar d-flex d-flex-middle d-flex-right">
              <CustomPagination
                currentPage={currentPage}
                total={totalContentsCount}
                handlePagination={handlePagination}
                pageSize={PageLimit.ContentManagement}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </motion.div>

      {isOpenUploadAsset && (
        <UploadAssets
          isModalOpen={isOpenUploadAsset}
          setIsModalOpen={setIsOpenUploadAsset}
          fromWhere={FromWhere.AdminContentManagement}
          toWhere={PATHS.adminContentManagement}
        />
      )}

      {/* Image Modal */}
      <ImageViewer
        handleDetail={() => handleContentDetail(pdfContent)}
        fromWhere={FromWhere.AdminContentManagement}
      />

      {/* PDF Modal */}
      <Modal
        className="pdf-modal detail-open content-admin"
        centered={true}
        onCancel={handlePDFClose}
        footer={false}
        open={isPDFModalOpen}
        style={{ top: "20px", right: "20px", position: "absolute", marginRight: "20px" }}>
        <PDFViewer isRelevantAsset={true} btnClick={() => handleContentDetail(pdfContent)} />
      </Modal>

      {/* delete Content modal */}
      <DeleteModal
        isLoading={contentDeleteLoading}
        isModalOpen={isShowAssetDeleteModal}
        handleCancel={() => setIsShowAssetDeleteModal(false)}
        handleSubmit={handleDeleteSource}
        message="Are you sure you want to delete this asset?"
        title="Delete Asset"
      />
      {/* Multiple contents delete */}
      {/* <DeleteModal
        isLoading={isMultipleContentDeleteLoading}
        isModalOpen={isOpenMultipleDelete}
        handleCancel={() => setIsOpenMultipleDelete(false)}
        handleSubmit={handleMultipleContentsDelete}
        message="Are you sure you want to delete these assets?"
        title="Delete Assets"
      /> */}
    </Flex>
  );
};

export default Sources;
