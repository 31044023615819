import { NavigateFunction } from "react-router-dom";
import { persistStore } from "redux-persist";
import { toast } from "react-toastify";
import { store } from "../store";
import {
  setIsCreatePasswordLoading,
  setIsSendOtpLoading,
  setIsVerifyOtpLoading,
  setLoginLoading,
  setRestPasswordLoading,
} from "../store/auth/auth.slice";
import { PATHS } from "../shared";
import { userPostReq } from "./apiClients";
import { removeLocalstorage, setLoginData } from "../shared/helpers";
import { setIsShowDeletedUserModal } from "../store/appState/appSlice";

export const authenticatesUser = async (
  request: { username: string; password: string },
  navigate: NavigateFunction
) => {
  store.dispatch(setLoginLoading(true));
  store.dispatch(setIsShowDeletedUserModal(false));

  try {
    const { data } = await userPostReq("/api/users/authenticate", request);

    setLoginData(data, navigate);
  } catch (error: any) {
    console.log("login Error", error);
    toast.error(error?.errorMessage || "Failed to login");
  } finally {
    store.dispatch(setLoginLoading(false));
  }
};

export const CreateNewPassword = async (
  request: { email: string; otp: number; newPassword: string },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsCreatePasswordLoading(true));
    await userPostReq(`/core/token/createNewPassword`, request);
    navigate(PATHS.login);
    toast.success("Password updated successfully");
  } catch (error: any) {
    console.log(error, "CreateNewPassword api error");
    toast.error(error ?? "Failed to reset password", error);
  } finally {
    store.dispatch(setIsCreatePasswordLoading(false));
  }
};

export const resetPassword = async (
  request: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
    userId: number;
  },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setRestPasswordLoading(true));
    await userPostReq(`/core/token/resetPasswordOnboarding`, request);
    persistStore(store).purge();
    removeLocalstorage();
    toast.success("Password reset successfully");
    navigate(PATHS.login);
  } catch (error: any) {
    console.log(error, "reset api error");
    toast.error(error ?? "Failed to reset password", error);
  } finally {
    store.dispatch(setRestPasswordLoading(false));
  }
};

export const sendOtp = async (request: { email: string }, navigate: NavigateFunction) => {
  try {
    store.dispatch(setIsSendOtpLoading(true));
    await userPostReq(`/core/token/send-otp`, request);
    navigate(PATHS.verifyOtp);
    toast.success("OTP sent successfully");
  } catch (error: any) {
    console.log(error, "sendOtp api error");
    toast.error(error?.message ?? "Failed to send OTP", error?.message);
  } finally {
    store.dispatch(setIsSendOtpLoading(false));
  }
};

export const verifyOtp = async (
  request: { email: string; otp: number },
  navigate: NavigateFunction
) => {
  try {
    store.dispatch(setIsVerifyOtpLoading(true));
    await userPostReq(`/core/token/verify-otp`, request);
    navigate(PATHS.changePassword);
    toast.success("OTP verified successfully");
  } catch (error: any) {
    console.log(error, "verifyOtp api error");
    toast.error(error ?? "Failed to verify OTP", error);
  } finally {
    store.dispatch(setIsVerifyOtpLoading(false));
  }
};
