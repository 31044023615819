import { FC, useEffect, useState, Dispatch, SetStateAction } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { DeleteModal } from "../../deleteModal";
import { IExperience, IUserProfile } from "../../../store/userManagement/user.interface";
import { isEmpty } from "../../../shared";

interface IExperienceForm {
  localExp: IExperience[];
  setLocalUserProfile: Dispatch<SetStateAction<IUserProfile>>;
  selectedExperience: IExperience | null;
  selectedExpIndex: number;
  handleCloseExperienceModal: () => void;
  isVisibleExperienceModal: boolean;
}
const ExperienceForm: FC<IExperienceForm> = (props) => {
  const {
    localExp,
    setLocalUserProfile,
    selectedExperience,
    selectedExpIndex,
    handleCloseExperienceModal,
    isVisibleExperienceModal,
  } = props;
  const [experienceForm] = Form.useForm();
  const [isShowDeleteExpModal, setIShowDeleteExpModal] = useState<boolean>(false);
  const [currentlyWorking, setCurrentlyWorking] = useState<boolean>(
    selectedExperience?.currentlyWorking ? Boolean(selectedExperience?.currentlyWorking) : false
  );

  const handleClose = () => {
    handleCloseExperienceModal();
    setCurrentlyWorking(false);
  };

  const handleProceed = (values: any): void => {
    const exp = localExp || [];
    if (isEmpty(selectedExperience)) {
      exp.push(values);
    } else {
      exp[selectedExpIndex] = values;
    }
    setLocalUserProfile((prev) => ({ ...prev, experience: exp }));
    handleClose();
    experienceForm.resetFields();
  };

  useEffect(() => {
    if (selectedExperience) {
      experienceForm.setFieldsValue(selectedExperience);
    } else {
      experienceForm.resetFields();
    }
  }, [selectedExperience, isVisibleExperienceModal]);

  const handleCancel = () => {
    experienceForm.resetFields();
    handleClose();
  };

  const handleDeleteExp = () => {
    const exp = localExp || [];
    exp.splice(selectedExpIndex, 1);
    setLocalUserProfile((prev) => ({ ...prev, experience: exp }));
    setIShowDeleteExpModal(false);
    handleClose();
  };

  return (
    <>
      <Form
        onFinish={handleProceed}
        form={experienceForm}
        name="addExperience"
        className="block-style"
        layout="vertical">
        <div className="form-item-col">
          <Form.Item
            label="Title"
            name="title"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please enter title",
              },
            ]}>
            <Input placeholder="Ex. Retail Source Manager" />
          </Form.Item>
        </div>

        <div className="form-item-col">
          <Form.Item
            label="Company"
            name="company"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please enter company",
              },
            ]}>
            <Input placeholder="Enter Company" />
          </Form.Item>
        </div>

        <div className="form-item-col">
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                type: "string",
                required: true,
                message: "Please enter location",
              },
            ]}>
            <Input placeholder="Enter Location" />
          </Form.Item>
        </div>

        <div className="form-item-col two-item">
          <Form.Item
            label="Start Date"
            name="startDate"
            rules={[
              {
                type: "date",
                required: true,
                message: "Please select start date",
              },
            ]}>
            <Input placeholder="DD/MM/YYYY" type="date" />
          </Form.Item>

          <Form.Item
            label="End Date"
            name="endDate"
            rules={[
              {
                type: "date",
                required: !currentlyWorking,
                message: "Please select end date",
              },
            ]}>
            <Input placeholder="DD/MM/YYYY" type="date" />
          </Form.Item>

          <Form.Item valuePropName="checked" className="new-line" name="currentlyWorking">
            <Checkbox onChange={(e) => setCurrentlyWorking(e.target.checked)}>
              I am currently working in this role
            </Checkbox>
          </Form.Item>
        </div>

        <div className="submit-wrap ant-modal-footer">
          {selectedExperience ? (
            <Button
              className="btn"
              type="default"
              danger
              onClick={() => setIShowDeleteExpModal(true)}>
              Delete
            </Button>
          ) : (
            <Button className="btn" type="default" onClick={handleCancel}>
              Cancel
            </Button>
          )}

          <Button className="btn" type="primary" htmlType="submit">
            Proceed
          </Button>
        </div>
      </Form>

      <DeleteModal
        isLoading={false}
        isModalOpen={isShowDeleteExpModal}
        handleCancel={() => setIShowDeleteExpModal(false)}
        handleSubmit={handleDeleteExp}
        message="Are you sure you want to delete this experience?"
        title="Delete Experience"
      />
    </>
  );
};

export default ExperienceForm;
