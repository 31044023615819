import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./StartChatPanel.scss";
import { Button, Tooltip } from "antd";
import TextArea from "antd/es/input/TextArea";
import { RootState } from "../../../store";
import { defaultLogo, fileType, FromWhere, getAllIds, useChats } from "../../../shared";
import { UploadAssets } from "../../uploadAssetsContentMangement";
import {
  removeFilterData,
  setAllAssetTypesSelected,
  setAllFileTypesSelected,
} from "../../../store/filtersFromTaxonomyData/filters.slice";
import {
  setEmptyUploadFileList,
  setStartNewConversation,
  setUserQuestion,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import WhisperAudioFile from "../chatPanel/WhisperAudioFile";

const StartChatPanel = () => {
  const [question, setQuestion] = useState("");
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState(false);
  const [selectedFileList, _] = useState<File[]>([]);
  const [__, setDisableInputWhileRecording] = useState(false);

  const dispatch = useDispatch();
  const { clearAllChatStates, defaultConfigureState } = useChats();

  const { assetTypeTaxonomy } = useSelector((state: RootState) => state.taxonomy);
  const { selectedChatHistory, uploadFileList } = useSelector(
    (state: RootState) => state.conversationalSearch
  );
  const { appearance } = useSelector((state: RootState) => state.app);
  const assistantLogo = appearance?.assistantLogo?.url || defaultLogo.assistantLogo;

  const handleEnterKeyAction = (e: any) => {
    e.preventDefault();
    if (e.key === "Enter" && question.trim()) {
      if (selectedChatHistory.id !== -1) {
        dispatch(removeFilterData([]));
        dispatch(setAllFileTypesSelected(fileType));
        dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      }
      dispatch(setStartNewConversation(true));
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(question.trim()));
      setQuestion("");
    }
  };

  const handleStartChat = () => {
    if (question.trim()) {
      if (selectedChatHistory.id !== -1) {
        dispatch(removeFilterData([]));
        dispatch(setAllFileTypesSelected(fileType));
        dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      }
      dispatch(setStartNewConversation(true));
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(question.trim()));
      setQuestion("");
    }
  };

  useEffect(() => {
    if (uploadFileList?.length && selectedFileList.length === uploadFileList.length) {
      if (selectedChatHistory.id !== -1) {
        dispatch(removeFilterData([]));
        dispatch(setAllFileTypesSelected(fileType));
        dispatch(setAllAssetTypesSelected(getAllIds(assetTypeTaxonomy)));
      }
      dispatch(setStartNewConversation(true));
      clearAllChatStates();
      defaultConfigureState();
      dispatch(setUserQuestion(question.trim()));
      setQuestion("");
    }
  }, [uploadFileList, selectedFileList]);

  return (
    <>
      <div className="start-chat-panel">
        <div className="help-you">
          <div className="brand-icon">
            <img
              src={assistantLogo}
              alt="Assistant Logo"
              onError={(e) => (e.currentTarget.src = defaultLogo.assistantLogo)}
            />
          </div>
          <h2>
            Hello!
            <br />
            How can I help you today?
          </h2>
        </div>
        <div className="start-chat-bottom">
          <div className="ask-me-input d-flex d-flex-middle position-relative">
            <div className="ant-input-affix-wrapper">
              <span className="ant-input-prefix">
                <Tooltip
                  placement="top"
                  title="Upload"
                  trigger="hover"
                  overlayClassName="tooltip-text">
                  <i
                    onClick={() => {
                      dispatch(setEmptyUploadFileList()), setIsOpenUploadAsset(true);
                    }}
                    className="ri-attachment-2"></i>
                </Tooltip>
              </span>
              <TextArea
                value={question}
                placeholder="Ask me anything or upload asset..."
                onPressEnter={handleEnterKeyAction}
                onChange={(e) => {
                  setQuestion(e.target.value);
                }}
                autoSize={{ minRows: 1, maxRows: 4 }}
              />
            </div>

            <WhisperAudioFile
              setQuestion={setQuestion}
              setIsAudioLoading={setIsAudioLoading}
              isAudioLoading={isAudioLoading}
              isDisable={false}
              setDisableInputWhileRecording={setDisableInputWhileRecording}
            />
            <Button
              type="primary"
              disabled={!question.trim() || isAudioLoading}
              onClick={handleStartChat}
              className="send-btn position-absolute">
              <i className="ri-send-plane-fill" />
            </Button>

            <UploadAssets
              isModalOpen={isOpenUploadAsset}
              setIsModalOpen={setIsOpenUploadAsset}
              fromWhere={FromWhere.Source}
            />
          </div>
          <p className="by-query">
            {/* By entering query, you are agreeing to our <Link to={"#"}>privacy policy</Link>. */}
            Ocktogen can make mistakes. Verify Important details
          </p>
        </div>
      </div>
    </>
  );
};

export default StartChatPanel;
