import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { Button, Card, Col, Dropdown, Flex, Input, Row, Switch, Table } from "antd";
import {
  AccessGroupTaxonomies,
  AddEditAccessForm,
  DeleteModal,
  PageHeader,
  TableSkeleton,
} from "../../../components";
import { RootState } from "../../../store";
import { IaccessGroups } from "../../../store/accessGroup/accessGroup.interface";
import {
  setAccessGroupPage,
  setAccessGroups,
  setAccessGroupSearch,
} from "../../../store/accessGroup/accessGroupSlice";
import {
  deleteContentAccessGroup,
  fetchContentAccessGroup,
  updateContentAccessGroupStatus,
} from "../../../services/accessGroup";
import { getAllTaxonomyWithoutPagination } from "../../../services/taxonomy";
import { CustomPagination, isEmpty, PageLimit, useDebounce } from "../../../shared";
import {
  getFormattedTaxonomyForMapData,
  mapDataToTreeSelect,
} from "../../../shared/taxonomyHelper";
import "./AccessGroup.scss";

const AccessGroup = () => {
  const dispatch = useDispatch();
  const {
    accessGroups,
    accessGroupPage,
    accessGroupSearch,
    isAccessGroupsLoading,
    totalAccessGroupsCount,
    isAccessGroupDeleting,
    isAccessGroupUpdating,
  } = useSelector((state: RootState) => state.accessGroup);
  const { allTaxonomy } = useSelector((state: RootState) => state.taxonomy);

  const [selectedAccessGroup, setSelectedAccessGroup] = useState<IaccessGroups | null>(null);
  const [isShowAddEditModal, setIsShowAddEditModal] = useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const formattedTaxonomy = getFormattedTaxonomyForMapData(allTaxonomy) || {};
  const mappedTaxonomyData = mapDataToTreeSelect(formattedTaxonomy);

  useEffect(() => {
    getAllTaxonomyWithoutPagination();
  }, []);

  useEffect(() => {
    fetchContentAccessGroup(true, accessGroupSearch, accessGroupPage);
  }, [accessGroupPage]);

  const handleAddNewGroup = () => {
    setIsShowAddEditModal(true);
    setSelectedAccessGroup(null);
  };

  const handleStatusChange = async (value: boolean, record: any, selectedIndex: number) => {
    const res = await updateContentAccessGroupStatus(value, record?.id);

    if (res?.status === 200) {
      const values = [...accessGroups];
      values[selectedIndex] = {
        ...record,
        status: value,
      };
      dispatch(setAccessGroups(values));
    }
  };

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (name: string) => (
        <>
          <span className="td-label">Name : </span>
          <span>
            <strong>{name}</strong>
          </span>
        </>
      ),
    },
    {
      title: "Taxonomies",
      width: "60%",
      key: "taxonomyNodeIds",
      render: (_: undefined, record: any) => {
        return (
          <>
            <span className="td-label">Taxonomies : </span>
            <div className="industry-column-group">
              <AccessGroupTaxonomies
                id={record?.id}
                title="Taxonomies"
                linkedTags={formattedTaxonomy ?? []}
                selectedIds={record?.taxonomyNodeIds ?? []}
              />
            </div>
          </>
        );
      },
    },
    {
      title: "Status",
      key: "status",
      width: "10%",
      dataIndex: "status",
      render: (_: undefined, record: any, index: number) => (
        <>
          <span className="td-label">Status : </span>
          <div className="switch-edit-action">
            <Switch
              disabled={isAccessGroupUpdating}
              checked={record?.status}
              onChange={(val) => handleStatusChange(val, record, index)}
            />
            <Dropdown
              trigger={["click"]}
              menu={{
                onClick: () => {
                  setSelectedAccessGroup(record);
                },
                items: [
                  {
                    key: "1",
                    label: "Edit",
                    onClick: () => setIsShowAddEditModal(true),
                  },
                  {
                    key: "2",
                    label: "Delete",
                    onClick: () => setIsShowDeleteModal(true),
                  },
                ],
              }}
              overlayStyle={{ width: 80 }}
              overlayClassName="table-action-dropdown table-action-dropdown-user"
              placement="bottomRight">
              <Button className="more-action">
                <i className="ri-more-2-fill"></i>
              </Button>
            </Dropdown>
          </div>
        </>
      ),
    },
  ];

  const handleDeleteAccessGroup = async () => {
    if (selectedAccessGroup) {
      await deleteContentAccessGroup(
        selectedAccessGroup?.id,
        accessGroupSearch,
        accessGroupPage,
        totalAccessGroupsCount
      );
      handleOnCancel();
    }
  };

  const getAccessGroupsBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();

    if (trimmedSearchText.length >= 3)
      await fetchContentAccessGroup(true, trimmedSearchText, accessGroupPage);
    if (e.target.value.length === 0) {
      await fetchContentAccessGroup(true, "", 1);
    }
    dispatch(setAccessGroupPage(1));
  };

  const handleSearchDebounce = useDebounce(getAccessGroupsBySearch, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounce(e);
    dispatch(setAccessGroupSearch(e.target.value));
  };

  const handleOnCancel = () => {
    setIsShowAddEditModal(false);
    setIsShowDeleteModal(false);
    setSelectedAccessGroup(null);
  };

  const handlePagination = (page: number) => {
    dispatch(setAccessGroupPage(page));
  };

  return (
    <>
      <Flex className="admin-inner-wrap access-group-page" vertical>
        <PageHeader
          title="Content Access Group"
          isRelevantAsset={false}
          content={
            <>
              <Input
                type="search"
                className="header-search"
                placeholder="Search by name..."
                prefix={<i className="ri-search-line" />}
                allowClear
                onChange={(e) => {
                  handleChangeSearch(e);
                }}
                defaultValue={accessGroupSearch}
                value={accessGroupSearch}
              />
              <Button
                className="btn"
                type="default"
                icon={<i className="ri-add-circle-fill" />}
                onClick={handleAddNewGroup}>
                Add new group
              </Button>
            </>
          }
        />

        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}>
          <Row>
            <Col span={24}>
              <Card
                className={`global-table-card ${
                  isEmpty(accessGroups) ? "global-table-empty" : ""
                }`}>
                {isAccessGroupsLoading ? (
                  <TableSkeleton />
                ) : (
                  <Table
                    className="global-table responsive-table access-group-table"
                    columns={columns}
                    dataSource={accessGroups}
                    tableLayout="fixed"
                    scroll={{ y: "calc(100vh - 188px)" }}
                    pagination={false}
                  />
                )}
                {!isEmpty(accessGroups) ? (
                  <CustomPagination
                    currentPage={accessGroupPage}
                    total={totalAccessGroupsCount}
                    pageSize={PageLimit.AccessGroup}
                    handlePagination={handlePagination}
                  />
                ) : (
                  <></>
                )}
              </Card>
            </Col>
          </Row>
        </motion.div>
      </Flex>

      {isShowAddEditModal && (
        <AddEditAccessForm
          isModalOpen={isShowAddEditModal}
          setIsModalOpen={setIsShowAddEditModal}
          onCancel={handleOnCancel}
          title={`${isEmpty(selectedAccessGroup) ? "Add" : "Edit"} Group`}
          titleBtn={isEmpty(selectedAccessGroup) ? "Create" : "Save"}
          selectedAccessGroup={selectedAccessGroup}
          mappedTaxonomyData={mappedTaxonomyData}
        />
      )}

      {isShowDeleteModal && (
        <DeleteModal
          isLoading={isAccessGroupDeleting}
          isModalOpen={isShowDeleteModal}
          handleCancel={handleOnCancel}
          handleSubmit={handleDeleteAccessGroup}
          message="This access group may be assigned to a user. Are you sure you want to delete this access group?"
          title="Delete Access Group"
        />
      )}
    </>
  );
};

export default AccessGroup;
