import { FC } from "react";
import { Col, Button, Row, Select, Switch, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../../../store";
import {
  setChatLanguage,
  setSelectedChatHistory,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import { getSubdomain } from "../../../services/apiClients";
import { ChatLanguage } from "../../constants";
import "./SettingModal.scss";

interface ISettingModalProps {
  onSubmit?: () => void;
}

const SettingModal: FC<ISettingModalProps> = ({ onSubmit }) => {
  const dispatch = useDispatch();

  const { chatLanguage, selectedChatHistory } = useSelector(
    (state: RootState) => state.conversationalSearch
  );

  const isNewChat = () => {
    return selectedChatHistory?.id === -1;
  };

  const getChatLanguages = () => {
    if (getSubdomain() === "aecb") {
      return [
        { value: ChatLanguage.English, name: "English (EN)" },
        { value: ChatLanguage.Arabic, name: "Arabic (AR)" },
      ];
    } else
      return [
        { value: ChatLanguage.Auto, name: "Auto" },
        { value: ChatLanguage.English, name: "English (EN)" },
        { value: ChatLanguage.Arabic, name: "Arabic (AR)" },
        { value: ChatLanguage.Spanish, name: "Spanish (ES)" },
        { value: ChatLanguage.Chinese, name: "Chinese (Mandarin) (ZH)" },
        { value: ChatLanguage.French, name: "French (FR)" },
        { value: ChatLanguage.German, name: "German (DE)" },
        { value: ChatLanguage.Portuguese, name: "Portuguese (PT)" },
        { value: ChatLanguage.Italian, name: "Italian (IT)" },
        { value: ChatLanguage.Dutch, name: "Dutch (NL)" },
        { value: ChatLanguage.Russian, name: "Russian (RU)" },
        { value: ChatLanguage.Japanese, name: "Japanese (JA)" },
        { value: ChatLanguage.Korean, name: "Korean (KO)" },
        { value: ChatLanguage.Hindi, name: "Hindi (HI)" },
        { value: ChatLanguage.Turkish, name: "Turkish (TR)" },
        { value: ChatLanguage.Polish, name: "Polish (PL)" },
      ];
  };

  const onChange = (checked: boolean) => {
    dispatch(setSelectedChatHistory({ ...selectedChatHistory, agent: checked }));
  };

  const handleSubmit = () => {
    if (onSubmit) {
      return onSubmit();
    }
    return () => {};
  };

  return (
    <>
      <Row className="setting-modal-row">
        <Col span={24}>
          <div className="label-wrap">
            <span className="label">Agents</span>
          </div>
          <div className="input-wrap">
            <Tooltip
              placement="right"
              trigger="hover"
              title={isNewChat() ? "" : "This feature only available for new chat."}
              overlayClassName="tooltip-text">
              <Switch
                size="small"
                checked={selectedChatHistory?.agent}
                onChange={onChange}
                disabled={!isNewChat()}
              />
            </Tooltip>
          </div>
        </Col>

        <Col span={24}>
          <div className="label-wrap">
            <span className="label">Language</span>
          </div>
          <div className="input-wrap">
            <Select
              className="language-select"
              style={{ height: "24px" }}
              popupClassName="ui-dropdown-default"
              value={chatLanguage}
              onChange={(e: ChatLanguage) => dispatch(setChatLanguage(e))}
              dropdownStyle={{ width: "120px" }}>
              {getChatLanguages()?.map((v) => (
                <Select.Option value={v?.value} key={uuidv4()}>
                  {v?.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </Col>
      </Row>

      <div className="setting-footer d-flex d-flex-middle d-flex-right" hidden={true}>
        <Button type="link" className="btn" onClick={handleSubmit}>
          Done
        </Button>
      </div>
    </>
  );
};

export default SettingModal;
