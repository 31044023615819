import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  LoadingOutlined,
  RedoOutlined,
} from "@ant-design/icons";
import { Button, Card, Checkbox, Form, Input, Modal, Select, Tag } from "antd";
import { RootState } from "../../store";
import { clearUserManagementFilter } from "../../store/userManagement/usersSlice";
import { IUser, IUserManagementForm } from "../../store/userManagement/user.interface";
import { IRolesListRes } from "../../store/roleManagement/roleManage.interface";
import { useLogout } from "../../shared/hooks/useLogout";
import { addUser, updateUser, verifyUserByEmail } from "../../services/userManagement";
import {
  isEmpty,
  generatePassword,
  getCapitalizedStr,
  checkPlatformPermission,
  Permissions,
  useDebounce,
} from "../../shared";
import { ISelectOpt } from "../../types";
import "./AddEditUserManagement.scss";

interface IModal {
  isModalOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  selectedUser: IUser | null;
  userDetails: IUser;
  titleBtn: string;
}

const AddNewUser = ({
  isModalOpen,
  onCancel,
  title = "Update",
  selectedUser,
  userDetails,
  titleBtn,
}: IModal) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { accessGroups } = useSelector((state: RootState) => state.accessGroup);
  const { rolesList } = useSelector((state: RootState) => state.roleManagement);
  const { userCurrPage, searchText, isUserUpdating, isUserVerifying } = useSelector(
    (state: RootState) => state.user
  );
  const [autoPassword, setAutoPassword] = useState<any>("");
  const [showRoleInfo, setShowRoleInfo] = useState<boolean>(false);
  const [selectedAccessGroup, setSelectedAccessGroup] = useState<number[]>([]);

  const [isEmailAlreadyExists, setIsEmailAlreadyExists] = useState<boolean>(false);
  const [isAlreadyPlatformAdmin, setIsAlreadyPlatformAdmin] = useState<boolean>(false);
  const [existUser, setExistUser] = useState<IUser | null>(null);

  const { handleLogout } = useLogout();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const tagRender = (props: any) => {
    const { label, closable, onClose } = props;

    return (
      <Tag
        // color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}>
        <span className="label-ui">{label}</span>
      </Tag>
    );
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const getFormattedRolesOpt = () => {
    let options: ISelectOpt[] = [];
    if (!isEmpty(rolesList)) {
      rolesList.forEach((item: IRolesListRes) => {
        if (item?.status) {
          options.push({ value: item?.id, label: item?.name });
        }
      });
    }
    return options;
  };

  const getContentAccessGroupOpt = () => {
    const filteredAccessGroups = accessGroups?.filter(
      (accessGroup) => accessGroup?.status === true
    );

    let options: ISelectOpt[] = [];
    if (!isEmpty(filteredAccessGroups)) {
      filteredAccessGroups.forEach((item) => {
        options.push({ value: item?.id, label: item?.name });
      });
    }
    return options;
  };

  const handleSetSelectedUser = () => {
    if (!isEmpty(selectedUser)) {
      const accessGroupIds: number[] =
        selectedUser?.accessGroups?.map((item) => Number(item?.id)) || [];

      form.setFieldsValue({
        name: selectedUser?.name,
        email: selectedUser?.email,
        roleId: selectedUser?.role?.id || null,
        accessGroupIds: accessGroupIds,
      });
      setSelectedAccessGroup(accessGroupIds);
    } else {
      form.resetFields();
    }
  };

  useEffect(() => {
    handleSetSelectedUser();
  }, [selectedUser]);

  useEffect(() => {
    form.setFieldsValue({ accessGroupIds: selectedAccessGroup });
  }, [selectedAccessGroup]);

  const handleSelectAccessGroup = (value: any[]) => {
    if (value.includes("selectAll")) {
      const allValues = getContentAccessGroupOpt()?.map((item) => Number(item?.value));

      // De-Select All
      if (allValues?.length === value?.length - 1) {
        setSelectedAccessGroup([]);
      }
      // Select All
      else if (selectedAccessGroup?.length === value?.length - 1) {
        setSelectedAccessGroup(allValues);
      } else {
        const ids = selectedAccessGroup?.map((item) => Number(item));
        setSelectedAccessGroup([...new Set(ids)]);
      }
    } else {
      setSelectedAccessGroup(value);
    }
  };

  useEffect(() => {
    setAutoPassword(generatePassword());
  }, []);

  const passwordCopied = () => {
    navigator.clipboard.writeText(autoPassword);
    toast.success("message copied");
  };

  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleShowEmailVerifyIcon = () => {
    if (!isEmpty(selectedUser)) {
      return <span />;
    }

    const emailVal = form.getFieldValue("email");
    const trimmedSearchText = emailVal?.trim();
    if (isUserVerifying) {
      return <LoadingOutlined className="loading" />;
    } else {
      if (!isEmpty(trimmedSearchText)) {
        if (!emailRegex.test(trimmedSearchText)) {
          return <CloseOutlined className="cancel" />;
        }
        return !isEmailAlreadyExists ? <CheckOutlined className="checked" /> : <span />;
      }
    }
    return <span />;
  };

  const handleVerifyUser = async () => {
    const emailVal = form.getFieldValue("email");
    const trimmedSearchText = emailVal?.trim();

    if (emailRegex.test(trimmedSearchText)) {
      if (trimmedSearchText?.length >= 3) {
        const res = await verifyUserByEmail(trimmedSearchText);
        if (res?.id) {
          setIsEmailAlreadyExists(true);
          setExistUser(res);
          setIsAlreadyPlatformAdmin(res?.isPlatformAdmin === null ? false : true);
          form.setFieldValue("name", res?.name);
        } else {
          setIsEmailAlreadyExists(false);
          setExistUser(null);
          setIsAlreadyPlatformAdmin(false);
          form.setFieldValue("name", "");
          form.setFieldValue("password", "");
        }
      }
    }
  };

  const handleSearchDebounce = useDebounce(handleVerifyUser, 500);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isEmpty(selectedUser)) {
      handleSearchDebounce(e);
    }
  };

  const onFinish = async (values: IUserManagementForm) => {
    if (isAlreadyPlatformAdmin) {
      toast.warning("Email already assigned to a Platform admin!");
      return;
    } else if (!isEmpty(existUser) && existUser?.isPlatformAdmin === null && existUser?.role?.id) {
      toast.warning("User already exists!");
      return;
    }

    const request: IUserManagementForm = {
      ...values,
      name: getCapitalizedStr(values.name),
      username: values?.email,
      accessGroupIds: values?.accessGroupIds || [],
    };
    const isUserExistsInDiffEnv = isEmailAlreadyExists && !isAlreadyPlatformAdmin;
    const userId = isUserExistsInDiffEnv ? existUser?.id : selectedUser?.id;

    if (!isEmpty(selectedUser) || isUserExistsInDiffEnv) {
      const res: any = await updateUser({ ...request, userId }, searchText, userCurrPage);
      if (!isEmpty(res?.data) && selectedUser?.id === userDetails?.id) {
        handleLogout();
      }
    } else {
      const res = await addUser(request, "", 1);
      if (res?.status === 200) {
        dispatch(clearUserManagementFilter());
      }
    }
    handleCancel();
  };

  return (
    <Modal
      centered
      title={title}
      afterClose={() => form.resetFields()}
      width={500}
      footer={
        <>
          <Button onClick={handleCancel} className="btn" size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={isUserVerifying || isUserUpdating}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn"
            type="primary"
            size={"middle"}>
            {titleBtn}
          </Button>
        </>
      }
      onCancel={handleCancel}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addUser"
          initialValues={{
            name: "",
            email: "",
            password: "",
            roleId: null,
            accessGroupIds: null,
          }}
          onFinish={onFinish}
          requiredMark={false}
          size="large"
          className="form-container add-user-form">
          <div className="container2">
            <div className="form-inner">
              <Card className="input-card">
                <Form.Item
                  label="Email Address"
                  name="email"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      type: "email",
                      required: true,
                      message: "Please enter valid email",
                    },
                  ]}>
                  <Input
                    placeholder="Enter email address"
                    autoComplete="off"
                    suffix={handleShowEmailVerifyIcon()}
                    onChange={handleEmailChange}
                  />
                </Form.Item>

                <Form.Item
                  label="Full Name"
                  name="name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your full name",
                    },
                    // {
                    //   pattern: new RegExp(/^[a-zA-Z\s]*$/),
                    //   message: "Name field only accept alphabets",
                    // },
                  ]}>
                  <Input
                    placeholder="Enter full name"
                    autoComplete="off"
                    disabled={isEmpty(selectedUser) && isEmailAlreadyExists}
                  />
                </Form.Item>

                {isEmpty(selectedUser) && !isEmailAlreadyExists ? (
                  <>
                    <Form.Item
                      className="password-input"
                      label="Password"
                      name="password"
                      labelCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password",
                        },
                      ]}>
                      <Input.Password placeholder="Enter password" />
                    </Form.Item>

                    <div className="passwordCreater">
                      Suggested password is <div className="passwordText">{autoPassword}</div>
                      <div
                        className="copyPassword"
                        onClick={() => {
                          passwordCopied();
                        }}>
                        <CopyOutlined />
                      </div>
                      <div className="reset" onClick={() => setAutoPassword(generatePassword())}>
                        <RedoOutlined />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {!isAlreadyPlatformAdmin && (
                  <>
                    <Form.Item
                      className={`select-input ${isEmpty(selectedUser) && "mt-2"}`}
                      label="Select Role"
                      name="roleId"
                      labelCol={{ span: 24 }}
                      rules={[{ required: true, message: "Please select role" }]}>
                      <Select
                        showSearch
                        className="select-dropdown-multiple"
                        tagRender={tagRender}
                        popupClassName="ui-dropdown-default"
                        placeholder="Select Role"
                        options={getFormattedRolesOpt()}
                        filterOption={(inputValue: string, option: any) =>
                          option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        onChange={() => setShowRoleInfo(true)}
                        disabled={
                          !isEmpty(selectedUser) &&
                          !checkPlatformPermission(Permissions.EDIT_USER_ROLE)
                        }
                      />
                    </Form.Item>

                    {selectedUser && selectedUser?.id === userDetails?.id && showRoleInfo && (
                      <div className="role-alert edit-role-alert">
                        <i className="ri-information-fill" style={{ color: "#A7A7A7" }}></i>
                        <span>Changing your role will log you out from the app.</span>
                      </div>
                    )}

                    <Form.Item
                      className="select-input"
                      label="Content Access Groups"
                      name="accessGroupIds"
                      labelCol={{ span: 24 }}
                      rules={[{ required: true, message: "Please select content access groups" }]}>
                      <Select
                        showSearch
                        className="select-dropdown-multiple"
                        mode="multiple"
                        tagRender={tagRender}
                        popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
                        placeholder="Select Content Access Groups"
                        value={selectedAccessGroup || []}
                        onChange={handleSelectAccessGroup}
                        options={
                          !isEmpty(getContentAccessGroupOpt())
                            ? [
                                { label: "Select All", value: "selectAll" },
                                ...getContentAccessGroupOpt(),
                              ]
                            : []
                        }
                        filterOption={(inputValue: string, option: any) =>
                          option?.label?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                        }
                        menuItemSelectedIcon={({ isSelected }) => (
                          <span>
                            {isSelected ||
                            (selectedAccessGroup &&
                              selectedAccessGroup?.length ===
                                getContentAccessGroupOpt()?.length) ? (
                              <Checkbox checked={true}></Checkbox>
                            ) : (
                              <Checkbox checked={false}></Checkbox>
                            )}
                          </span>
                        )}
                        maxTagCount={3}
                      />
                    </Form.Item>
                  </>
                )}
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddNewUser;
