import { useEffect, useState } from "react";
import { Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import { setAppLayoutview } from "../../../store/appState/appSlice";
import { UploadAssets } from "../../uploadAssetsContentMangement";
import {
  FromWhere,
  AppLayout,
  PATHS,
  useChats,
  checkPlatformPermission,
  Permissions,
} from "../../../shared";
import "./HomeFeatured.scss";
import { useNavigate } from "react-router-dom";
import {
  setContentFilterForNewChat,
  setIsNewContextFilterApplied,
  setSelectedFilesForAssetFilter,
  setStartNewChatFromInsight,
  setStartNewConversation,
} from "../../../store/conversationalSearch/conversationalSearchSlice";
import { setContentSelectedIds } from "../../../store/contentManagement/contentSlice";
import { useFindProfile } from "../../../shared/hooks/findProfile/useFindProfile";

const HomeFeatured = () => {
  const { appLayoutView } = useSelector((state: RootState) => state.app);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { defaultConfigureState } = useChats();
  const { handleClearFindProfileChat } = useFindProfile();

  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState<boolean>(false);
  const [bodyClass, setBodyClass] = useState(appLayoutView || AppLayout.MINIMIZE);

  useEffect(() => {
    document.body.className = bodyClass;
  }, [bodyClass]);

  const toggleChat = () => {
    dispatch(setSelectedFilesForAssetFilter([]));
    dispatch(setContentSelectedIds([]));
    dispatch(setIsNewContextFilterApplied(false));
    dispatch(setContentFilterForNewChat({ pageLocation: PATHS.home }));

    defaultConfigureState();
    dispatch(setStartNewChatFromInsight(true));
    dispatch(setStartNewConversation(false));
    dispatch(setAppLayoutview(AppLayout.MINIMIZE));
    setBodyClass(AppLayout.MINIMIZE);
  };

  const handleFindProfiles = () => {
    handleClearFindProfileChat();
    navigate(PATHS.profile);
  };

  return (
    <>
      <Card className="home-card full-card">
        <div className="full-card-inner d-flex d-flex-middle d-flex-between">
          <div className="feature-content-col">
            <p>
              Our AI-powered Knowledge Management Platform transforms data interaction with natural
              language commands, intelligent search, and real-time insights, offering effortless
              communication, precise results, and up-to-the-minute information for informed
              decisions.
            </p>
          </div>
          <div className="divider">
            <i className="ci ci-right-arrow-divider-icon" />
          </div>
          <div className="card-extra-options d-flex">
            {checkPlatformPermission(Permissions.UPLOAD_DOC) && (
              <div className="card cursor-pointer" onClick={() => setIsOpenUploadAsset(true)}>
                <div className="card-inner">
                  <i className="ci ci-upload-cloud-icon big-icon" />
                  <h3 className="card-title">
                    Upload Your Asset <i className="ri-arrow-right-line"></i>
                  </h3>
                  <p>Upload your assets to uncover valuable insights instantly</p>
                </div>
              </div>
            )}

            <div className="card cursor-pointer" onClick={toggleChat}>
              <div className="card-inner">
                <i className="ci ci-get-insights-icon big-icon" />
                <h3 className="card-title">
                  Get Insights <i className="ri-arrow-right-line"></i>
                </h3>
                <p>Engage with single or multiple files to derive actionable insights</p>
              </div>
            </div>

            <div className="card cursor-pointer" hidden={true}>
              <div className="card-inner" onClick={handleFindProfiles}>
                <i className="ci ci-find-profiles-icon big-icon" />
                <h3 className="card-title">
                  Find Profiles <i className="ri-arrow-right-line"></i>
                </h3>
                <p>Upload job descriptions to discover the most suitable profiles effortlessly</p>
              </div>
            </div>
          </div>
        </div>
      </Card>

      {isOpenUploadAsset && (
        <UploadAssets
          isModalOpen={isOpenUploadAsset}
          setIsModalOpen={setIsOpenUploadAsset}
          fromWhere={FromWhere.Home}
          toWhere={PATHS.myContent}
        />
      )}
    </>
  );
};

export default HomeFeatured;
