import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IPermissions, IRoleManagement, IRolesListRes } from "./roleManage.interface";

export const rolesListTestData = [
  {
    id: 1,
    name: "VIEW_DOC",
  },
  {
    id: 2,
    name: "EDIT_DOC",
  },
  {
    id: 3,
    name: "UPLOAD_DOC",
  },
  {
    id: 4,
    name: "DOWNLOAD_DOC",
  },
  {
    id: 5,
    name: "VIEW_DOC_METADATA",
  },
  {
    id: 6,
    name: "EDIT_DOC_METADATA",
  },
  {
    id: 7,
    name: "DELETE_DOC",
  },
];

const initialState: IRoleManagement = {
  rolesList: [],
  totalRolesCount: 0,
  isRoleFetching: false,
  isRoleUpdating: false,
  isRoleDeleting: false,
  permissionsList: [],
  isPermissionFetching: false,
  currPage: 1,
  searchText: "",
};

export const roleManageSlice = createSlice({
  name: "roleManageSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setRolesList: (state, action: PayloadAction<IRolesListRes[]>) => {
      state.rolesList = action.payload;
    },
    setTotalRoles: (state, action: PayloadAction<number>) => {
      state.totalRolesCount = action.payload;
    },
    setIsRoleFetching: (state, action: PayloadAction<boolean>) => {
      state.isRoleFetching = action.payload;
    },
    setIsRoleUpdating: (state, action: PayloadAction<boolean>) => {
      state.isRoleUpdating = action.payload;
    },
    setIsRoleDeleting: (state, action: PayloadAction<boolean>) => {
      state.isRoleDeleting = action.payload;
    },

    setPermissionsList: (state, action: PayloadAction<IPermissions[]>) => {
      state.permissionsList = action.payload;
    },
    setIsPermissionFetching: (state, action: PayloadAction<boolean>) => {
      state.isPermissionFetching = action.payload;
    },

    setCurrPage: (state, action: PayloadAction<number>) => {
      state.currPage = action.payload;
    },
    updatePageRoleDelete: (state, action: PayloadAction<number>) => {
      state.currPage = action.payload;
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    clearRoleManagementFilter: (state) => {
      state.searchText = "";
      state.currPage = 1;
    },
  },
});

export const {
  setRolesList,
  setTotalRoles,
  setIsRoleFetching,
  setIsRoleUpdating,
  setIsRoleDeleting,
  setPermissionsList,
  setIsPermissionFetching,
  setCurrPage,
  updatePageRoleDelete,
  setSearchText,
  clearRoleManagementFilter,
} = roleManageSlice.actions;

export default roleManageSlice.reducer;
