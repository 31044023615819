export const IMAGES = {
  logo: "/assets/images/logo.png",

  logoSm: "/assets/images/logo-sm.jpg",

  logoLg: "/assets/images/logo-lg.png",

  homeIcon: "/assets/images/grid.svg",

  aiAssistenceIcon: "/assets/images/ai-assistence.svg",

  historyIcon: "/assets/images/history.svg",

  pagesIcon: "/assets/images/pages.svg",

  myContentIcon: "/assets/images/my-content.svg",

  sourcesIcon: "/assets/images/sources.svg",

  handWave: "/assets/images/hand-wave.png",

  homeHeaderBackground: "/assets/images/home-header-bg.jpg",

  HomeMyContentBackground: "/assets/images/home-myContent-bg.svg",

  myContentFileIcon: "/assets/images/myContent-file-icon.svg",

  userManagementIcon: "/assets/images/user-management-icon.svg",

  taxonomyManagementIcon: "/assets/images/taxonomy-icon.svg",

  brandIcon: "/assets/icons/brand-icon-full.png",

  brandIconSvg: "/assets/icons/brand-icon-full.svg",

  octopusIcon: "/assets/icons/brand-icon.svg",

  avatarDefault: "/assets/images/avatar-default.svg",

  fileUploadedIcon: "/assets/icons/file-uploaded-icon.svg",

  accessGroupIcon: "/assets/images/access-group.svg",

  contentManagementIcon: "/assets/images/content-management.svg",

  dataConnectionIcon: "/assets/images/data-connection.svg",

  themeIcon: "/assets/images/theme.svg",

  workflowManagerIcon: "/assets/images/workflow-manager.svg",

  postThumbnail: "/assets/images/post-thumbnail.jpg",

  assetViewChartImage: "/assets/images/asset-view-graph.svg",

  totalDownloadChartImage: "/assets/images/total-download-chart.svg",

  waveImage: "/assets/images/octopus-wave.svg",

  userDefaultWall: "/assets/images/user-profile-default-wall.jpg",

  userDefaultAvatar: "/assets/images/user-default-avatar.jpg",

  //File Icons
  imageIcon: "/assets/icons/file-icons/image.svg",

  pptIcon: "/assets/icons/file-icons/ppt.svg",

  pdfIcon: "/assets/icons/file-icons/pdf.png",

  xlsIcon: "/assets/icons/file-icons/xls.svg",

  docsIcon: "/assets/icons/file-icons/docs.svg",

  videoIcon: "/assets/icons/file-icons/video.svg",

  audioIcon: "/assets/icons/file-icons/audio.svg",

  bookmarkFileIcon: "/assets/images/bookmark-file-icon.svg",

  iconLink: "/assets/icons/link-icon.svg",

  closePdfIcon: "/assets/icons/close-pdf-icon.svg",

  exptyBox: "/assets/images/empty-box 1.svg",

  googleLogo: "/assets/icons/google-logo.svg",

  cautionSign: "/assets/icons/caution-sign 1.svg",

  pdfIconBig: "/assets/icons/pdf-icon.svg",

  pdfViewer: "/assets/images/pdf-viewer.png",

  icon1: "/assets/icons/ai-summarize.svg",

  icon2: "/assets/icons/ai-tag-taxonomy.svg",

  icon3: "/assets/icons/ai-vision-model.svg",

  sharepointIcon: "/assets/icons/sharepoint-icon.svg",

  oneDriveIcon: "/assets/icons/oneDrive-icon.svg",

  salesforceIcon: "/assets/icons/salesforce-icon.svg",

  ConfluenceIcon: "/assets/icons/confluence-icon.svg",

  infoChatIcon: "/assets/icons/info-chat-icon.svg",

  folderIcon: "/assets/icons/folder-icon.svg",

  jdEmptyState: "/assets/images/jd-empty-state.svg",

  downloadProfileDocIon: "/assets/icons/download-profile-pdf-icon.svg",

  loadingSpinner: "/assets/icons/loading-spinner.svg",

  insightIcon: "/assets/icons/insight-icon.svg",

  profileIcon: "/assets/icons/profile-icon.svg",

  chatMicIcon: "/assets/icons/chat-mic-icon.svg",

  chatStopMicIcon: "/assets/icons/stop-chat-recording.svg",

  pageNotFound: "/assets/images/effect-404.jpg",

  rightArrowIcon: "/assets/icons/right-arrow.svg",

  mediaFullScreen: "/assets/icons/media-full-screen.svg",

  exploreMoreEmpty: "/assets/icons/explore_more_empty.svg",

  uncoverMoreEmpty: "/assets/icons/uncover_more_empty.svg",

  chatEmptyState: "/assets/icons/chat-empty-state.svg",
};
