import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { MenuProps } from "antd";
import { RootState } from "../../store";
import { VideoAudioPLayerModal } from "../../pages";
import { SideBar } from "../../components";
import { checkPlatformPermission, PATHS, Permissions } from "../../shared";
import "./AdminLayout.scss";

type MenuItem = Required<MenuProps>["items"][number];

const AdminLayout = () => {
  const { isShowVideoPLayerModal, videoModalContent } = useSelector(
    (state: RootState) => state.mediaUploadSlice
  );

  const sideBarItems = () => {
    const items: MenuItem[] = [
      {
        label: "Role Management",
        icon: <i className="ci ci-user-management-icon" />,
        key: PATHS.adminRoleManagement,
      },
      {
        label: "Content Access Group",
        icon: <i className="ci ci-access-group-icon" />,
        key: PATHS.adminAccessGroup,
      },
      {
        label: "User Management",
        icon: <i className="ci ci-user-management-icon" />,
        key: PATHS.userManagement,
      },
      {
        label: "Asset Management",
        icon: <i className="ci ci-asset-management-icon" />,
        key: PATHS.adminContentManagement,
      },
      {
        label: "Data Connection",
        icon: <i className="ci ci-data-connection-icon" />,
        key: PATHS.adminDataConnection,
      },
    ];

    if (checkPlatformPermission(Permissions.VIEW_TAXONOMY)) {
      items.push({
        label: "Taxonomy",
        icon: <i className="ci ci-taxonomy-icon" />,
        key: PATHS.adminTaxonomyManagement,
      });
    }

    items.push({
      label: "Theme",
      icon: <i className="ci ci-theme-icon" />,
      key: PATHS.adminTheme,
    });

    return items;
  };

  return (
    <>
      <div className="adminLayoutContainer">
        <div className={"side-bar-outlet-wrap sidebar-with-content sidebar-with-user-content"}>
          <SideBar sideBarItems={sideBarItems()} />
          <div className="outlet-wrap">
            <Outlet />
          </div>
        </div>
      </div>

      {/* Video Player Modal */}
      {isShowVideoPLayerModal && (
        <VideoAudioPLayerModal content={videoModalContent} isModalOpen={isShowVideoPLayerModal} />
      )}
    </>
  );
};

export default AdminLayout;
