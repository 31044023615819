import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Checkbox, Form, Input, Modal, Select } from "antd";
import { RootState } from "../../../store";
import { clearRoleManagementFilter } from "../../../store/roleManagement/roleManage.slice";
import {
  IRoleManagementForm,
  IRolesListRes,
} from "../../../store/roleManagement/roleManage.interface";
import { addRole, updateRole } from "../../../services/roleManagement";
import { ISelectOpt } from "../../../types";
import { isEmpty } from "../../../shared";

interface IAddEditRole {
  isModalOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  titleBtn?: string;
  selectedRole: IRolesListRes | null;
}

const AddEditRole = ({
  isModalOpen,
  title,
  onCancel,
  titleBtn = "Create",
  selectedRole,
}: IAddEditRole) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isRoleUpdating, searchText, currPage, permissionsList } = useSelector(
    (state: RootState) => state.roleManagement
  );
  const [selectedPermissions, setSelectedPermissions] = useState<number[]>([]);

  const handleSetSelectedRole = () => {
    if (!isEmpty(selectedRole)) {
      const privilegeIds = selectedRole?.privileges?.map((item) => item?.id);
      form.setFieldsValue({
        ...selectedRole,
        privilegeIds: privilegeIds,
      });
      setSelectedPermissions(privilegeIds ?? []);
    }
  };

  useEffect(() => {
    handleSetSelectedRole();
  }, [selectedRole]);

  const getFormattedPermissionsOpt = () => {
    let options: ISelectOpt[] = [];
    if (!isEmpty(permissionsList)) {
      permissionsList.forEach((item) => {
        options.push({ value: item?.id, label: item?.label });
      });
    }
    return options;
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleFinish = async (values: IRoleManagementForm) => {
    if (selectedRole?.id) {
      await updateRole({ ...values, id: selectedRole?.id }, searchText, currPage);
    } else {
      const res = await addRole(values, "", 1);
      if (res?.status === 200) {
        dispatch(clearRoleManagementFilter());
      }
    }
    handleCancel();
  };

  useEffect(() => {
    form.setFieldsValue({ privilegeIds: selectedPermissions });
  }, [selectedPermissions]);

  const handleSelectPermissions = (value: any[]) => {
    if (value.includes("selectAll")) {
      const allValues = getFormattedPermissionsOpt()?.map((item) => Number(item?.value));

      // De-Select All
      if (allValues?.length === value?.length - 1) {
        setSelectedPermissions([]);
      }
      // Select All
      else if (selectedPermissions?.length === value?.length - 1) {
        setSelectedPermissions(allValues);
      } else {
        const ids = selectedPermissions?.map((item) => Number(item));
        setSelectedPermissions([...new Set(ids)]);
      }
    } else {
      setSelectedPermissions(value);
    }
  };

  return (
    <Modal
      centered
      title={title}
      afterClose={() => form.resetFields()}
      width={500}
      footer={
        <>
          <Button onClick={handleCancel} className="btn" size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={isRoleUpdating}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn"
            type="primary"
            size={"middle"}>
            {titleBtn}
          </Button>
        </>
      }
      onCancel={handleCancel}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addEditRole"
          initialValues={{
            name: "",
            privilegeIds: null,
          }}
          onFinish={handleFinish}
          requiredMark={false}
          size="large"
          className="form-container add-role-form">
          <div className="container2">
            <div className="form-inner">
              <Card className="input-card">
                <Form.Item
                  label="Role name"
                  name="name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter role name",
                    },
                    // {
                    //   pattern: new RegExp(/^[a-zA-Z\s]*$/),
                    //   message: "Name field only accept alphabets",
                    // },
                  ]}>
                  <Input placeholder="Enter Role Name" autoComplete="off" />
                </Form.Item>

                <Form.Item
                  label="Select Permission(s)"
                  rules={[{ required: true, message: "Please select permission" }]}
                  name="privilegeIds"
                  labelCol={{ span: 24 }}>
                  <Select
                    allowClear={true}
                    mode="multiple"
                    className="select-dropdown-multiple"
                    popupClassName="ui-dropdown-default ui-dropdown-checkbox-default"
                    placeholder="Select Permission"
                    style={{ flex: 1 }}
                    value={selectedPermissions || []}
                    options={
                      !isEmpty(getFormattedPermissionsOpt())
                        ? [
                            { label: "Select All", value: "selectAll" },
                            ...getFormattedPermissionsOpt(),
                          ]
                        : []
                    }
                    onChange={handleSelectPermissions}
                    filterOption={(inputValue: string, option: any) =>
                      option?.label?.toLowerCase().indexOf(inputValue?.toLowerCase()) !== -1
                    }
                    menuItemSelectedIcon={({ isSelected }) => (
                      <span>
                        {isSelected ||
                        (selectedPermissions &&
                          selectedPermissions?.length === getFormattedPermissionsOpt()?.length) ? (
                          <Checkbox checked={true}></Checkbox>
                        ) : (
                          <Checkbox checked={false}></Checkbox>
                        )}
                      </span>
                    )}
                    maxTagCount={2}
                  />
                </Form.Item>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditRole;
