import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Card, Col, Row, Input, Form, Button, Flex, Select, Switch } from "antd";
import { Option } from "antd/es/mentions";
import "./TaxonomyOverview.scss";
import { RootState } from "../../store";
import { TaxonomyTree } from "../taxonomyTree";
import { TaxonomyFlat } from "../taxonomyFlat";
import PageHeader from "../pageHeader/PageHeader";
import { fetchTaxonomyById, getTaxonomyByTitle, updateTaxonomy } from "../../services/taxonomy";
import {
  checkPlatformPermission,
  isEmpty,
  TaxonomyItem,
  TaxonomyTitle,
  TaxonomyType,
  TaxonomyView,
  Permissions,
} from "../../shared";

const TaxonomyOverview = () => {
  const location = useLocation();
  const { id } = useParams();
  const [form] = Form.useForm();
  const taxonomyId: number = Number(id) > 0 ? Number(id) : -1;

  const { getTaxonomyTree } = useSelector((state: RootState) => state.taxonomy);

  const [colLevel, setColLevel] = useState<number>(-1);
  const [isMandatory, setIsMandatory] = useState<boolean>(false);
  const [taxonomyView, setTaxonomyView] = useState(TaxonomyView.TreeHierarchy);
  const [isEditMode, setIsEditMode] = useState<boolean>(location?.state?.isEditMode);

  const getTaxonomyMaxLevel = (taxonomies: TaxonomyItem[]): number => {
    let maxLevel = 0;
    taxonomies.map((taxonomy: TaxonomyItem) => {
      if (maxLevel < (taxonomy?.taxonomyNodeLevel || 0)) maxLevel = taxonomy.taxonomyNodeLevel || 0;

      let level = getTaxonomyMaxLevel(taxonomy.children);
      if (level > maxLevel) {
        maxLevel = level;
      }
    });
    return maxLevel;
  };

  useEffect(() => {
    if (getTaxonomyTree?.taxonomyType) {
      const taxonomyType =
        getTaxonomyTree?.taxonomyType === TaxonomyType.TREE
          ? TaxonomyView.TreeHierarchy
          : TaxonomyView.FlatKeywords;
      setTaxonomyView(taxonomyType);

      const maxLevel = getTaxonomyMaxLevel(getTaxonomyTree.taxonomyTree || []);
      if (maxLevel > colLevel && taxonomyId === getTaxonomyTree.taxonomyId)
        setColLevel(Number(maxLevel || 0));
    }

    let totalLength = 0;
    const parseTree = getTaxonomyTree?.taxonomyUI ? JSON.parse(getTaxonomyTree?.taxonomyUI) : [];

    getTaxonomyTree?.taxonomyType === TaxonomyType.TREE &&
      parseTree?.length &&
      parseTree.forEach((obj: any) => {
        obj.tree.forEach((treeObj: any) => {
          totalLength += treeObj?.level?.length;
        });
      });

    form.setFieldsValue(getTaxonomyTree);
    setIsMandatory(getTaxonomyTree?.mandatory || false);
  }, [getTaxonomyTree]);

  useEffect(() => {
    return () => {
      if (getTaxonomyTree?.title?.toLocaleLowerCase() === TaxonomyTitle.Industry) {
        getTaxonomyByTitle(TaxonomyTitle.Industry);
      } else if (getTaxonomyTree?.title?.toLocaleLowerCase() === TaxonomyTitle.Function) {
        getTaxonomyByTitle(TaxonomyTitle.Function);
      } else if (getTaxonomyTree?.title?.toLocaleLowerCase() === TaxonomyTitle.AssetType) {
        getTaxonomyByTitle(TaxonomyTitle.AssetType);
      }
    };
  }, [getTaxonomyTree]);

  const renderTaxonomyView = () => {
    if (taxonomyView === TaxonomyView.TreeHierarchy) {
      return (
        <div className="taxonomy-type d-flex d-flex-middle">
          <div className="no-terms-text">No. of terms: {getTaxonomyTree?.termsCount ?? 0}</div>
          <div className="tree-hierarchy-text d-flex d-flex-middle">
            <span className="taxonomy-icon d-flex d-flex-middle">
              <i className="ci ci-tree-hierarchy"></i>
            </span>
            Tree hierarchy
          </div>
        </div>
      );
    } else {
      return (
        <div className="taxonomy-type d-flex d-flex-middle">
          <div className="no-terms-text">No. of terms: {getTaxonomyTree?.termsCount ?? 0}</div>
          <div className="tree-hierarchy-text d-flex d-flex-middle">
            <span className="taxonomy-icon d-flex d-flex-middle">
              <i className="ci ci-flat-hierarchy"></i>
            </span>
            Flat keywords
          </div>
        </div>
      );
    }
  };

  const handleFieldValidation = (values: { title: string; purpose: string }) => {
    if (isEmpty(values?.title) && isEmpty(values?.purpose)) {
      toast.error("Please enter title and purpose");
      return true;
    } else if (isEmpty(values?.purpose)) {
      toast.error("Please enter purpose");
      return true;
    } else if (isEmpty(values?.title)) {
      toast.error("Please enter title");
      return true;
    } else {
      return false;
    }
  };

  const onFinish = async (values: { title: string; purpose: string }) => {
    const isError = handleFieldValidation(values);
    if (isError) {
      return;
    }

    const setBasicValues = {
      ...values,
      taxonomyType: getTaxonomyTree?.taxonomyType,
      synonyms: "",
      levels: getTaxonomyTree?.levels ?? 0,
      mandatory: isMandatory,
    };

    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      const prevTitle = getTaxonomyTree?.title || "";
      const prevPurpose = getTaxonomyTree?.purpose || "";

      if (
        prevTitle !== values?.title ||
        prevPurpose !== values?.purpose ||
        isMandatory !== getTaxonomyTree?.mandatory
      ) {
        await updateTaxonomy(taxonomyId, setBasicValues);
        await fetchTaxonomyById(taxonomyId);
      }
      setIsEditMode(false);
    }
  };

  const renderTaxonomyFooter = () => {
    if (taxonomyView === TaxonomyView.TreeHierarchy) {
      return (
        <div className="global-ui-card tree-hierarchy-new">
          <Card>
            <TaxonomyTree colLevel={colLevel} setColLevel={setColLevel} />
          </Card>
        </div>
      );
    } else {
      return (
        <div className="global-ui-card taxonomy-flat-card">
          <Card>
            <TaxonomyFlat colLevel={1} />
          </Card>
        </div>
      );
    }
  };

  const handleCancel = () => {
    setIsEditMode(false);
    form.setFieldsValue(getTaxonomyTree);
    setIsMandatory(getTaxonomyTree?.mandatory || false);
  };

  return (
    <Flex className="admin-inner-wrap taxonomy-management-overview" vertical>
      <PageHeader isHeadBack={true} title="Taxonomy Details" content={renderTaxonomyView()} />

      <div className="page-body-scroll d-flex d-flex-wrap">
        <div className="global-ui-card page-card-top">
          <Card>
            <Form
              form={form}
              initialValues={{
                mandatory: getTaxonomyTree?.mandatory || false,
              }}
              onFinish={onFinish}>
              {checkPlatformPermission(Permissions.EDIT_TAXONOMY) && (
                <div className="page-card-action-Wrap">
                  <div className="level-select d-flex d-flex-middle d-flex-right">
                    <div className="no-terms-text">
                      Mandatory{"  "}
                      <Switch
                        checked={isMandatory}
                        disabled={!isEditMode}
                        onChange={(e) => setIsMandatory(e)}
                      />
                    </div>

                    {!isEditMode ? (
                      <Button
                        className="btn edit-button d-flex d-flex-middle"
                        htmlType="submit"
                        icon={<i className="ri-edit-fill" />}>
                        Edit
                      </Button>
                    ) : (
                      <div
                        className="page-title-box page-title-edit-mode d-flex d-flex-middle d-flex-right"
                        hidden={!isEditMode}>
                        <Button className="btn add-user-button" onClick={handleCancel}>
                          Cancel
                        </Button>
                        <Button htmlType="submit" type="primary" className="btn add-user-button">
                          Save changes
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <Row className="page-card-form" gutter={[15, 15]}>
                <Col span={7}>
                  <Form.Item label="Industry Name" name="title" rules={[{ required: false }]}>
                    <Input
                      className={`input-style input-title ${isEditMode && "edit-input-style"}`}
                      value={getTaxonomyTree?.title}
                      readOnly={isEditMode === false ? true : false}
                      style={{ width: "10", maxWidth: "10" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} hidden>
                  <div className="ant-form-item-row version-row">
                    <div className="ant-col">
                      <span className="input-label">Select Version</span>
                      <div className="version-selector">
                        <Select
                          placeholder="Versions"
                          popupClassName="ui-dropdown-default"
                          disabled={!isEditMode}
                          value="2.0"
                          popupMatchSelectWidth={false}>
                          <Option value="1.0">Version 1.0</Option>
                          <Option value="1.5">Version 1.5</Option>
                          <Option value="2.0">
                            Version 2.0 <span className="stat active">(Current)</span>
                          </Option>
                          <Option value="2.5">
                            Version 2.5 <span className="stat draft">(Draft)</span>
                          </Option>
                        </Select>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item label="Purpose" name="purpose" rules={[{ required: false }]}>
                    <Input.TextArea
                      className={`input-style ${isEditMode && "edit-input-style"}`}
                      readOnly={isEditMode === false ? true : false}
                      value={getTaxonomyTree?.purpose}
                      autoSize={{ minRows: 3, maxRows: 4 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </div>

        {renderTaxonomyFooter()}
      </div>
    </Flex>
  );
};

export default TaxonomyOverview;
