import { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Dropdown,
  Empty,
  Input,
  MenuProps,
  Popover,
  Row,
  Select,
  Skeleton,
  Switch,
  Table,
} from "antd";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import {
  AssetSummaryPopover,
  AssetTags,
  AssetUploadStatus,
  BookmarkIcon,
  FileCardType,
  FileLocation,
  IMAGES,
  NoContentData,
  PATHS,
  PageLimit,
  SummaryStaus,
  UserRoles,
  ViewType,
  getFormattedDate,
  getIsAssetProcessing,
  getIsContentFailed,
  getIsDisableEditContent,
  getIsShowRunVision,
  getUserRole,
  isEmpty,
  uniqueId,
} from "../../shared";
import {
  AddRecommendedAsset,
  DeleteModal,
  FileCard,
  FileCardSkeleton,
  TableSkeleton,
} from "../../components";
import {
  bookmarkContent,
  deleteAssetById,
  disablePublish,
  enablePublish,
  getAllContent,
  getAssetById,
  getAssetDetailsByAssetId,
  runVision,
  selectContent,
  stopProcessing,
  updateViewCount,
} from "../../services/contentManagement";
import {
  IContent,
  IContributor,
  ITagsByAssestId,
} from "../../store/contentManagement/content.interface";
import {
  contentByIdInitialValue,
  contentFilterField,
  setAllContentDetails,
  setContentById,
  setIsUserListLoading,
  setSelectedAllTagsByAssestId,
  setUpdateContentFilter,
  setUpdatePublishDraft,
  setUserListPageCount,
  tagsByAssestIdInitValue,
  updateAssetByAssetId,
} from "../../store/contentManagement/contentSlice";
import { setViewType } from "../../store/appState/appSlice";
import TextArea from "antd/es/input/TextArea";
import { IUser } from "../../store/userManagement/user.interface";
import { fetchAllUsers } from "../../services/userManagement";
import "./TopicAbout.scss";

const TopicAbout: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { assetId } = { assetId: "547" };

  const { viewType } = useSelector((state: RootState) => state.app);
  const { allUsers, totalUsersCount } = useSelector((state: RootState) => state.user);
  const {
    contents,
    isContentByIdLoading,
    contentById,
    isEditContentLoading,
    contentDeleteLoading,
    userPageCount,
    totalContentsCount,
    contentFilter,
    isUserListLoading,
    isContentTableLoading,
    contentFilesInContext,
  } = useSelector((state: RootState) => state.content);

  const currentPage = contentFilter?.currentPage ?? 1;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [pendingContents, setPendingContents] = useState<IContent[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [content, setContent] = useState<IContent>(contentByIdInitialValue);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);
  const [selectedOwner, setSelectedOwner] = useState({ value: "", key: "-1" });
  const [selectedContributorId, setSelectedContributorId] = useState<number>(-1);
  const [addContributors, setAddContributors] = useState({ value: "", key: "-1" });
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [isShowCancelWarnModal, setIsShowCancelWarnModal] = useState<boolean>(false);
  const [isOpenPeopleRemoveModal, setIsOpenPeopleRemoveModal] = useState<boolean>(false);
  const [isAddContributorVisible, setIsAddContributorVisible] = useState<boolean>(false);
  const [isShowContributorSearch, setIsShowContributorSearch] = useState<boolean>(false);
  const [userList, setUserList] = useState<IUser[]>(allUsers);
  const [adminsList, setAdminsList] = useState<IUser[]>(allUsers);
  const [filteredContributors, setfilteredContributors] = useState<IUser[] | []>([]);
  const [isUploadImageModal, setIsUploadImageModal] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading] = useState<boolean>(false);
  const [isShowRecommendedAssetModal, setIsShowRecommendedAssetModal] = useState<boolean>(false);

  console.log(file);

  const loading = isContentByIdLoading;

  let contentInterval: any;

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (contents && contents.length) {
        contents.forEach((content) => {
          if (
            [
              String(AssetUploadStatus.PROCESSING),
              AssetUploadStatus.SENT_TO_LLM,
              AssetUploadStatus.UPLOADED_TO_DO,
            ].includes(content?.assetStatus) ||
            content?.assetStatus === AssetUploadStatus.STOPPING
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  const handleContentPulling = () => {
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }
  };

  useEffect(() => {
    handleContentPulling();

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  useEffect(() => {
    getAllContent(contentFilter, false);
  }, []);

  useEffect(() => {
    dispatch(setUserListPageCount(2));
    const data = getFilteredContributors(allUsers);
    setfilteredContributors(data);
  }, [allUsers]);

  useEffect(() => {
    if (currentAssetId) getAssetById(currentAssetId(), setContent, navigate);
  }, [assetId]);

  const getFilteredContributors = (list: IUser[]) => {
    const filteredContributors = list.filter(
      (user) => !content.contributors.map((contributor) => contributor.id).includes(user.id)
    );
    return filteredContributors;
  };

  const handleCancel = () => {
    if (getIsAnyAssestEdited()) {
      setIsShowCancelWarnModal(true);
    } else {
      handleResetChanges();
    }
  };

  const currentAssetId = (): number => {
    return !!assetId ? parseInt(assetId) : -1;
  };

  const handleResetChanges = () => {
    if (content?.summaryStatus === SummaryStaus.PROCESSING) {
      // handleUpdateSummaryStatus();
    }

    setIsEditMode(false);
    setIsAddContributorVisible(false);
    setIsUploadImageModal(false);
    setContent(contentById);
    setIsShowContributorSearch(false);
    setIsShowCancelWarnModal(false);
    setSelectedOwner({ value: "", key: "-1" });
  };

  const getIsAnyAssestEdited = (): boolean => {
    const isContributorsEdited = getIsContributorsEdited();

    const prevTitle = contentById?.title ?? "";
    const prevSummary = contentById?.summary ?? "";
    const prevContentUserId = contentById?.user?.id ?? -1;

    const currTitle = content?.title ?? "";
    const currSummary = content?.summary ?? "";
    const currContentUserId = content?.user?.id ?? -1;
    const currSummaryStatus = content?.summaryStatus ?? "";

    if (
      prevTitle !== currTitle ||
      prevSummary !== currSummary ||
      prevContentUserId !== currContentUserId ||
      isContributorsEdited ||
      currSummaryStatus === SummaryStaus.PROCESSING
    ) {
      return true;
    }
    return false;
  };

  // const handleSaveAssetFieldValue = async (currentAssetId: number) => {
  //   setIsEditMode(false);
  //   const contributors = content?.contributors.map((v) => ({ id: v?.id }));
  //   const prevTitle = contentById?.title ?? "";
  //   const prevSummary = contentById?.summary ?? "";
  //   const prevContentUserId = contentById?.user?.id ?? -1;

  //   const currTitle = content?.title ?? "";
  //   const currSummary = content?.summary ?? "";
  //   const currContentUserId = content?.user?.id ?? -1;

  //   if (
  //     prevTitle !== currTitle ||
  //     prevSummary !== currSummary ||
  //     getIsContributorsEdited() ||
  //     prevContentUserId !== currContentUserId
  //   ) {
  //     await editAssetById(
  //       currentAssetId,
  //       content?.title,
  //       content?.summary,
  //       content?.assetType,
  //       content?.user?.id,
  //       contributors,
  //       content?.summaryStatus || null
  //     );
  //     setContent(content);
  //     dispatch(setContentById(content));
  //   }

  //   setIsEditMode(false);
  //   setIsAddContributorVisible(false);
  //   setIsShowContributorSearch(false);
  // };

  const getIsContributorsEdited = (): boolean => {
    const prevContributorsId = (contentById?.contributors || []).map((item) => item?.id);
    const currContributorsId = (content?.contributors || []).map((item) => item?.id);
    let isContributorsEdited = true;

    if (prevContributorsId.length === currContributorsId.length) {
      if (prevContributorsId.length === 0 && currContributorsId.length === 0) {
        isContributorsEdited = false;
      } else {
        for (const id of prevContributorsId) {
          if (currContributorsId.includes(id)) {
            isContributorsEdited = false;
          } else {
            isContributorsEdited = true;
            break;
          }
        }
      }
    } else {
      isContributorsEdited = true;
    }

    return isContributorsEdited;
  };

  const handleScroll = async (e: any) => {
    const { target } = e;
    if (Math.round(target.scrollTop + target.offsetHeight) === Math.round(target.scrollHeight)) {
      try {
        if (userList?.length < totalUsersCount) {
          dispatch(setIsUserListLoading(true));
          const res = await fetchAllUsers("", userPageCount);
          dispatch(setUserListPageCount(userPageCount + 1));
          const data = getFilteredContributors(res);
          setfilteredContributors([...filteredContributors, ...data]);
          setUserList([...userList, ...res]);
          setAdminsList([...adminsList, ...res]);
        }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsUserListLoading(false));
      }
    }
  };

  const AvatarAndTitleSkeleton = () => {
    return (
      <li className="ant-list-item">
        <div className="ant-list-item-meta">
          <div className="ant-list-item-meta-avatar">
            <span className="ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh">
              <Skeleton.Avatar className="mt-2" size={45} active={true} />
            </span>
          </div>

          <div className="ant-list-item-meta-content">
            <Skeleton.Input className="mt-1 content" active={true} />
          </div>
        </div>
      </li>
    );
  };

  const renderAssetAndContributor = () => {
    if (isAddContributorVisible) {
      return (
        <Card className="default-card">
          {/* Add people */}
          <div className="add-people">
            <div className="add-people-inner d-flex d-flex-middle">
              <div className="people-input position-relative">
                <Select
                  placeholder="Search names or emails"
                  optionLabelProp="value"
                  showSearch
                  onChange={onContributorsChange}
                  onSearch={onSearch}
                  value={addContributors}
                  labelInValue
                  suffixIcon={null}
                  popupClassName="people-input-dropdoun"
                  onPopupScroll={handleScroll}>
                  {filteredContributors &&
                    filteredContributors.map((v) => {
                      return (
                        <Select.Option key={v.id} value={v?.name} label={v?.name}>
                          <div className="people-info">
                            <img src={IMAGES.avatarDefault} />
                            <span className="user-name">{v?.name}</span>
                            <span className="user-email">{v?.email}</span>
                          </div>
                        </Select.Option>
                      );
                    })}
                </Select>
              </div>
              <Button
                type="primary"
                className="add-people-btn"
                onClick={handleAddContributors}
                disabled={addContributors?.value === ""}>
                Add people
              </Button>
            </div>

            {/* People List */}
            <div className="people-list-items">
              <ul className="ant-list-items sidebar-list">
                {content?.contributors &&
                  content?.contributors.map((v) => {
                    return (
                      <li className="ant-list-item" key={uniqueId()}>
                        <div className="ant-list-item-meta">
                          <div className="ant-list-item-meta-avatar">
                            <span className="ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh">
                              {/* <img src={IMAGES.avatar1} alt="avatar" /> */}
                            </span>
                          </div>
                          <div className="ant-list-item-meta-content">
                            <h5 className="ant-list-item-meta-title">{v?.name}</h5>
                            {/* Edit View */}
                            <p
                              className="link-action"
                              onClick={() => handleRemoveContributorModal(v?.id)}>
                              Remove
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className="bottom-btn">
              <Button
                type="primary"
                className="save-changes"
                onClick={() => setIsAddContributorVisible(false)}>
                Cancel
              </Button>
            </div>
          </div>
        </Card>
      );
    } else {
      return (
        <Row className="asset-owner-info">
          <Col span={24} className="divider">
            <h4 className="card-header-title">
              Owner(s)
              {isEditMode && !content?.user?.name && (
                <span className="choose-span"> (choose asset owner)</span>
              )}
            </h4>
            <ul
              className={
                (content?.user?.name === "" || content?.user === null) && isEditMode
                  ? "ant-list-items sidebar-list admin-list"
                  : "ant-list-items sidebar-list"
              }>
              {(content?.user?.name === "" || content?.user === null) && isEditMode ? (
                <div className="add-people-inner d-flex d-flex-middle">
                  <div className="people-input position-relative">
                    <i className="ri-search-line search-icon position-absolute"></i>
                    <Select
                      placeholder="Search names or emails"
                      optionLabelProp="value"
                      showSearch
                      onChange={handleSelectOwner}
                      onSearch={onSearch}
                      value={selectedOwner}
                      labelInValue
                      suffixIcon={null}
                      popupClassName="people-input-dropdoun"
                      onPopupScroll={handleScroll}>
                      {adminsList.map((v) => {
                        return (
                          <Select.Option key={v.id} value={v?.name} label={v?.name}>
                            <div className="people-info">
                              <img src={IMAGES.avatarDefault} />
                              <span className="user-name">{v?.name}</span>
                              <span className="user-email">{v?.email}</span>
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  <Button
                    type="primary"
                    className="btn"
                    // onClick={handleChangeOwnerAction}
                    disabled={selectedOwner?.value === ""}>
                    Change owner
                  </Button>
                </div>
              ) : content?.user?.name === "" || content?.user === null ? (
                loading ? (
                  <AvatarAndTitleSkeleton />
                ) : (
                  <div className="empty-owner">
                    <Empty />
                  </div>
                )
              ) : (
                <li className="ant-list-item">
                  <div className="ant-list-item-meta">
                    <div className="ant-list-item-meta-avatar">
                      <span
                        className={
                          content.user.status === AssetUploadStatus.DELETED
                            ? "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh alt-avatar-grey-user-image"
                            : "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh"
                        }>
                        {loading ? (
                          <Skeleton.Avatar className="mt-2" size={45} active={true} />
                        ) : (
                          <img src={IMAGES.avatarDefault} className="avatar" alt="avatar" />
                        )}
                      </span>
                    </div>

                    {/* Edit View */}
                    <div className="contributor-list">
                      {loading ? (
                        <Skeleton.Input className="mt-1 content" active={true} />
                      ) : (
                        <div>
                          <h5
                            className={
                              content.user.status === AssetUploadStatus.DELETED
                                ? "ant-list-item-meta-delete-user-title"
                                : "ant-list-item-meta-title"
                            }>
                            {content?.user?.status === AssetUploadStatus.DELETED
                              ? "Deleted User"
                              : content?.user?.name}
                          </h5>
                          <p
                            className="link-action"
                            onClick={() => window.open(`mailto:${content?.user?.email}`)}>
                            <i className="ri-mail-line"></i>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </Col>
          <Col span={24}>
            <div className="contributor-list-inner">
              <h4 className="card-header-title d-flex d-flex-middle d-flex-between">
                Contributor(s)
                {/* Edit View */}
                <div className="btn-wrap d-flex d-flex-middle">
                  <Button
                    className="btn gen-btn"
                    type="default"
                    onClick={() => setIsShowContributorSearch(true)}
                    hidden={!isEditMode || isShowContributorSearch}
                    icon={<i className="ri-add-line"></i>}>
                    Add people
                  </Button>
                  <Button
                    type="primary"
                    className="btn"
                    onClick={handleAddContributors}
                    hidden={!isEditMode || !isShowContributorSearch}
                    disabled={addContributors?.value === ""}>
                    Add people
                  </Button>
                  <Button
                    type="default"
                    className="btn"
                    hidden={!isEditMode || !isShowContributorSearch}
                    onClick={() => setIsShowContributorSearch(false)}>
                    Cancel
                  </Button>
                </div>
              </h4>

              <div
                hidden={!isEditMode || !isShowContributorSearch}
                className="add-people-inner d-flex d-flex-middle">
                <div className="people-input position-relative">
                  <i className="ri-search-line search-icon position-absolute"></i>
                  <Select
                    placeholder="Search names or emails"
                    optionLabelProp="value"
                    showSearch
                    onChange={onContributorsChange}
                    onSearch={onSearch}
                    value={addContributors}
                    labelInValue
                    suffixIcon={null}
                    popupClassName="people-input-dropdoun"
                    onPopupScroll={handleScroll}>
                    {filteredContributors &&
                      filteredContributors.map((v) => {
                        return (
                          <Select.Option key={v.id} value={v?.name} label={v?.name}>
                            <div className="people-info">
                              <img src={IMAGES.avatarDefault} />
                              <span className="user-name">{v?.name}</span>
                              <span className="user-email">{v?.email}</span>
                            </div>
                          </Select.Option>
                        );
                      })}
                    {isUserListLoading && (
                      <div className="loaderItem">
                        {/* <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" /> */}
                      </div>
                    )}
                  </Select>
                </div>
              </div>

              <ul className="ant-list-items sidebar-list">
                {content?.contributors && content?.contributors?.length ? (
                  content?.contributors.map((v) => {
                    return (
                      <li className="ant-list-item" key={uniqueId()}>
                        <div className="ant-list-item-meta">
                          <div className="ant-list-item-meta-avatar">
                            <span
                              className={
                                v.status === AssetUploadStatus.DELETED
                                  ? "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh alt-avatar-grey-user-image"
                                  : "ant-avatar ant-avatar-circle ant-avatar-image css-1k979oh"
                              }>
                              <img src={IMAGES.avatarDefault} className="avatar" alt="avatar" />
                              {/* <div className="user-icon">
                                  <i className="ri-user-fill"></i>
                                </div> */}
                            </span>
                          </div>
                          <div className="ant-list-item-meta-content">
                            <h5
                              className={
                                v.status === AssetUploadStatus.DELETED
                                  ? "ant-list-item-meta-delete-user-title"
                                  : "ant-list-item-meta-title"
                              }>
                              {v?.status === AssetUploadStatus.DELETED ? "Deleted User" : v?.name}
                            </h5>

                            {/* Edit View */}
                            <p
                              className="link-action"
                              hidden={!isEditMode}
                              onClick={() => handleRemoveContributorModal(v?.id)}>
                              Remove
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : loading ? (
                  [...Array(3)].map((_, index) => {
                    return (
                      <div key={index}>
                        <AvatarAndTitleSkeleton />
                      </div>
                    );
                  })
                ) : (
                  <div className="no-record">No record found</div>
                )}
              </ul>
            </div>
          </Col>
        </Row>
      );
    }
  };

  const handleSelectOwner = (v: { value: string; key: string }) => {
    setSelectedOwner({ value: v.value, key: v.key });
  };

  const handleSaveChanges = async () => {
    if (checkSaveChangesInputs()) return;
    // handleSaveAssetFieldValue(currentAssetId());
  };

  const checkSaveChangesInputs = () => {
    if (!content?.title || content?.title.trim() === "") {
      toast.error("Title input cannot be empty");
      return true;
    }
  };

  const handleChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
    setContent((prev) => ({ ...prev, title: e.target.value }));
  };

  const handleChangeSummary = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContent((prev) => ({ ...prev, summary: e.target.value }));
  };

  const onContributorsChange = (value: { key: string; value: string }) => {
    setAddContributors({ key: value?.key, value: value?.value });
  };

  const handleAddContributors = () => {
    const addedContributor: IContributor = {
      status: "",
      id: parseInt(addContributors?.key),
      username: addContributors?.value,
      name: addContributors?.value,
      email: "string,",
      userPermissions: [],
    };

    setContent((v) => ({
      ...v,
      contributors: [...v.contributors, addedContributor],
    }));
    setAddContributors({ key: "-1", value: "" });
  };

  const onSearch = (val: any) => {
    console.log("search:", val);
  };

  const handleRemoveContributor = () => {
    setIsOpenPeopleRemoveModal(false);

    const contributors = content?.contributors.filter((v) => v.id !== selectedContributorId);
    setContent({
      ...content,
      contributors,
    });
  };

  const handleRemoveContributorModal = (id: number) => {
    setSelectedContributorId(id);
    setIsOpenPeopleRemoveModal(true);
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      // const fileType = file?.name?.substring(file?.name.lastIndexOf(".") + 1);
      setFile(file);
    }
  };

  const isShowEditButton = () =>
    getUserRole() === UserRoles.Admin ||
    (getUserRole() === UserRoles.ContentEditor && content?.is_editable === true);

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    const content = !isEmpty(contents) && contents.find((e) => e.asset_id === assetId);
    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: { isEditMode, isReadOnly: content ? getIsContentFailed(content) : false },
    });

    updateViewCount(assetId);
  };

  const handleUpdatePageOnDelete = () => {
    if (totalContentsCount % PageLimit.ContentManagement === 1) {
      // handleSetSearchParams(String(currentPage - 1), searchText);
    }
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const res = await bookmarkContent(content?.asset_id, content?.is_bookmarked ? false : true);
    if (res) {
      const updatedContent = {
        ...content,
        is_bookmarked: !content?.is_bookmarked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handleDeleteContent = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        totalContentsCount,
        true,
        contentFilter,
        "content"
      );
      if (res?.data) {
        handleUpdatePageOnDelete();
      }
    }
    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    const filteredContext = contentFilesInContext.filter(
      (c) => selectedContent?.asset_id !== c.asset_id
    );
    selectContent(
      filteredContext.map((c) => c.asset_id),
      true
    );
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );
  };

  const getIsShowStopBtn = (content: IContent) =>
    [
      String(AssetUploadStatus.UPLOADED_TO_DO),
      AssetUploadStatus.PROCESSING,
      AssetUploadStatus.SENT_TO_LLM,
      AssetUploadStatus.CONVERTED_TO_PDF,
    ].includes(content?.assetStatus);

  const getStopBtnLabel = (content: IContent) => {
    let label = "";

    if (
      [
        String(AssetUploadStatus.UPLOADED_TO_DO),
        AssetUploadStatus.PROCESSING,
        AssetUploadStatus.SENT_TO_LLM,
        AssetUploadStatus.CONVERTED_TO_PDF,
      ].includes(content?.assetStatus)
    ) {
      label = "Stop";
    } else if (AssetUploadStatus.STOPPING === content?.assetStatus) {
      label = "Stopping";
    }

    return label;
  };

  const getRunVisionLabel = (content: IContent) => {
    let label = "Run Vision";

    if (content?.visionParsed) {
      if (
        [
          String(AssetUploadStatus.UPLOADED_TO_DO),
          AssetUploadStatus.PROCESSING,
          AssetUploadStatus.SENT_TO_LLM,
        ].includes(content?.assetStatus)
      ) {
        label = "Run Vision Processing";
      } else if (content?.assetStatus === AssetUploadStatus.COMPLETED) {
        label = "Run Vision Completed";
      } else if (content?.assetStatus === AssetUploadStatus.FAILED) {
        label = "Run Vision Failed";
      }
    }
    return label;
  };

  const getIsDisableRunVision = (content: IContent) => {
    let isDisable = true;

    if (content?.visionParsed === false && content?.assetStatus === AssetUploadStatus.COMPLETED) {
      isDisable = false;
    }
    return isDisable;
  };

  const handleRunVision = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      toast.success("Vision is running");
      const res = await runVision(content?.asset_id);

      if (res?.data?.visionParsed) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.SENT_TO_LLM,
          visionParsed: true,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const handleStopProcessing = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      const res = await stopProcessing(content?.asset_id);

      if (res?.data) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.STOPPING,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const getIsDisableStopBtn = (content: IContent) =>
    AssetUploadStatus.STOPPING === content?.assetStatus;

  const getItems = (content: IContent, index: number) => {
    let items: MenuProps["items"] = [];
    const isDisable = getIsDisableRunVision(content);
    const label = getRunVisionLabel(content);
    const isShowRunVision = getIsShowRunVision(content?.original_file_name || "");

    if (isShowRunVision) {
      items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                handleRunVision(selectedContent, index);
              }}>
              {label}
            </div>
          ),
          className: isDisable ? "run-vision-disable" : "",
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
              }}>
              Edit
            </div>
          ),
          className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
        },
        {
          key: "3",
          label: (
            <div
              onClick={() => {
                if (getIsShowStopBtn(content)) {
                  handleStopProcessing(selectedContent, index);
                } else {
                  setIsShowAssetDeleteModal(true);
                }
              }}
              className="taxonomyDeleteText">
              {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
            </div>
          ),
          className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
        },
      ];
    } else {
      items = [
        {
          key: "1",
          label: (
            <div
              onClick={() => {
                handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
              }}>
              Edit
            </div>
          ),
          className: getIsDisableEditContent(content) ? "run-vision-disable" : "",
        },
        {
          key: "2",
          label: (
            <div
              onClick={() => {
                if (getIsShowStopBtn(content)) {
                  handleStopProcessing(selectedContent, index);
                } else {
                  setIsShowAssetDeleteModal(true);
                }
              }}
              className="taxonomyDeleteText">
              {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
            </div>
          ),
          className: getIsDisableStopBtn(content) ? "run-vision-disable" : "",
        },
      ];
    }

    return items;
  };

  const handlePublishedValidation = (content: IContent) => {
    let flag = false;
    let messageList = [];
    const assetType = content?.linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];

    if (getIsAssetProcessing(content?.assetStatus)) {
      toast.error("Unable to publish as document is still under AI processing");
      return true;
    }

    if (!content?.title || content?.title.trim() === "") {
      messageList.push("Title");
      flag = true;
    }
    if (!content?.summary || content?.summary.trim() === "") {
      messageList.push("Summary");
      flag = true;
    }
    if (!assetType.length) {
      messageList.push("Asset type");
      flag = true;
    }

    if (messageList && messageList.length) {
      let message = "";
      messageList.forEach((item, index) => {
        message =
          message +
          `${
            index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
          }${item}`;
      });

      if (message) {
        message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
        toast.error(message);
      }
    }

    return flag;
  };

  const renderAssetStatus = (isPublished: boolean | string, content: IContent, index: number) => {
    switch (content?.assetStatus) {
      case AssetUploadStatus.PROCESSING:
      case AssetUploadStatus.UPLOADED_TO_DO:
      case AssetUploadStatus.SENT_TO_LLM:
      default:
        return (
          <div className="inProgressStatus">
            {/* <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" /> */}
            In processing
          </div>
        );

      case AssetUploadStatus.LOADED_INTO_LLM:
      case AssetUploadStatus.COMPLETED:
        return (
          <Switch
            value={["TRUE", true].includes(isPublished) ? true : false}
            onChange={() => {
              if (handlePublishedValidation(content)) return;

              const isPublishedValue = ["TRUE", true].includes(isPublished) ? true : false;
              if (!isPublishedValue) {
                enablePublish(content?.asset_id);
              } else {
                disablePublish(content?.asset_id);
              }
              dispatch(setUpdatePublishDraft(index));
            }}
          />
        );

      case AssetUploadStatus.FAILED:
      case AssetUploadStatus.FAILED_IN_LLM:
        return <div className="failedStatusText">Failed</div>;

      case AssetUploadStatus.STOPPING:
        return (
          <div className="inProgressStatus">
            {/* <img src={IMAGES.loadingSpinner} alt="loading" className="spinnerSvg" /> */}
            Process stopping
          </div>
        );
      case AssetUploadStatus.STOPPED:
        return <div className="failedStatusText">Stopped</div>;
    }
  };

  const fileExtraOptions = (content: IContent, index: number) => {
    return (
      <>
        {/* <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon disabled={isContentTableLoading} isActive={content?.is_bookmarked} />
        </div> */}
        <Dropdown
          trigger={["click"]}
          menu={{
            onClick: () => {
              setSelectedContent(content);
            },
            items: getItems(content, index),
          }}
          overlayClassName="table-action-dropdown content-dropdown"
          placement="bottomRight">
          <Button className="custom-icon more-action" onClick={() => setSelectedContent(content)}>
            <i className="ri-more-2-fill"></i>
          </Button>
        </Dropdown>
      </>
    );
  };
  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isContentTableLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const tableColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "150px",
      render: (text: string, content: IContent) => (
        <>
          <span className="td-label">Title : </span>
          <Popover
            placement="bottomLeft"
            rootClassName="custom-description-root"
            content={<AssetSummaryPopover content={content} tags={false} />}
            trigger="hover">
            <strong
              className={`${"cursor-pointer title-col  d-flex d-flex-middle"} `}
              onClick={() => {
                handleViewEditContentNavigate(content?.asset_id);
              }}>
              {/* <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" />{" "} */}
              <div
                className="file-bg"
                style={{ backgroundImage: `url(${content?.thumbnail_pre_signed_url})` }}></div>
              {text ? text : content?.original_file_name}
            </strong>
          </Popover>
        </>
      ),
    },
    {
      title: "Content type",
      dataIndex: "file_level",
      key: "file_level",
      width: "120px",
      render: (file_level: string) => (
        <>
          <span className="td-label">Content type : </span>
          <span>
            {file_level === FileLocation.USER && "My Uploads"}
            {file_level === FileLocation.ORGANISATION && "Organisation"}
          </span>
        </>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "modifiedOn",
      key: "modifiedOn",
      width: "14%",
      render: (date: string) => (
        <>
          <span className="td-label">Last Updated : </span>
          {getFormattedDate(date, "type1")}
        </>
      ),
    },
    {
      title: "Tags",
      dataIndex: "linkedTags",
      key: "linkedTags",
      width: "20%",
      render: (linkedTags: ITagsByAssestId, content: IContent) => (
        <AssetTags {...{ linkedTags, content }} />
      ),
    },
    {
      title: "Published/Draft",
      dataIndex: "isPublished",
      key: "isPublished",
      width: "18%",
      render: (isPublished: boolean | string, content: IContent, index: number) => (
        <>
          <span className="td-label">Published/Draft : </span>
          <span className="published-draft-label d-flex d-flex-middle d-flex-between">
            {renderAssetStatus(isPublished, content, index)}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: (_: any, content: IContent, index: number) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="extra-options">
              <div className="file-extra-options">
                {fileExtraOptionsTwo(content, index)}
                {fileExtraOptions(content, index)}
              </div>
              <a
                className={`${
                  getIsDisableEditContent(content) ? "disable-edit" : "arrow-link"
                } `}></a>
            </div>
          </>
        );
      },
    },
  ];

  const renderAddOtherAsset = () => {
    return (
      <div
        className="add-other-asset cursor-pointer"
        onClick={() => setIsShowRecommendedAssetModal(true)}>
        <Button className="add-asset-btn" type="text" icon={<i className="ri-add-line"></i>}>
          Add Other Asset
        </Button>
      </div>
    );
  };

  const recommendedContentGridData = () => {
    return (
      <Row className="tab-row-inner">
        {isContentTableLoading ? (
          <FileCardSkeleton />
        ) : !isEmpty(contents) ? (
          <>
            {contents.map((item: IContent, index: number) => (
              <FileCard
                key={index}
                data={item}
                type={FileCardType.UPLOADED}
                extraOptions={fileExtraOptions(content, index)}
                extraOptionsTwo={fileExtraOptionsTwo(content, index)}
                onClick={() => handleViewEditContentNavigate(item.asset_id)}
              />
            ))}
            {isEditMode && renderAddOtherAsset()}
          </>
        ) : (
          <NoContentData />
        )}
      </Row>
    );
  };

  const recommendedContentListData = () => {
    return (
      <Row className="tab-row-inner list-view">
        <Col span={24}>
          <Card className="global-table-card">
            {isContentTableLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                className="global-table responsive-table"
                rowKey="asset_id"
                columns={tableColumns}
                dataSource={!isEmpty(contents) ? contents : []}
                locale={{ emptyText: <NoContentData /> }}
                tableLayout="fixed"
                scroll={{ y: "calc(100vh - 330px)" }}
                pagination={false}
              />
            )}
          </Card>
        </Col>
      </Row>
    );
  };

  const handleAddAssetSubmit = () => {
    setIsShowRecommendedAssetModal(false);
  };

  return (
    <>
      <div className="ant-tabs-extra-content topic-tab-extra">
        {isEditMode ? (
          <>
            <Button className="btn" type="default" onClick={handleCancel}>
              Cancel
            </Button>

            <Button
              className="btn"
              type="primary"
              onClick={handleSaveChanges}
              disabled={content?.summaryStatus === SummaryStaus.PROCESSING}
              loading={isEditContentLoading}>
              Save changes
            </Button>
          </>
        ) : (
          <>
            {isShowEditButton() && (
              <Button
                className="btn"
                type="default"
                onClick={() => setIsEditMode(true)}
                icon={<i className="ri-edit-fill"></i>}>
                Edit
              </Button>
            )}
          </>
        )}
      </div>

      <motion.div
        className="topic-about"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.6 }}>
        <Row className="topic-row">
          <Col span={16}>
            <div className="content-about">
              {loading ? (
                <Skeleton.Image active className="skeleton image" />
              ) : (
                <div
                  className="post-thumbnail position-relative"
                  style={{
                    backgroundImage: `url(${
                      !isEmpty(content) && content?.thumbnail_pre_signed_url
                        ? content?.thumbnail_pre_signed_url
                        : IMAGES.postThumbnail
                    })`,
                  }}>
                  {isUploadImageModal && (
                    <div className="image-modal">
                      <div
                        className="modal-shadow"
                        onClick={() => setIsUploadImageModal(false)}></div>
                      <div className="modal-wrapper">
                        <div className="modal-wrap-in">
                          <p className="input-label">Upload</p>
                          <div>
                            <input
                              ref={fileInputRef}
                              className="file-input"
                              type="file"
                              multiple={false}
                              placeholder="Upload Asset"
                              onChange={handleFileUpload}
                            />
                            <div className="upload-file-container">
                              <div className="file-name">
                                <span>
                                  <i className="ri-attachment-line browseIcon"></i>
                                  <span>Browse</span>
                                </span>
                              </div>

                              <Button
                                // onClick={handleClickUploadFile}
                                className="btn-primary fill-button btn-type2"
                                type="primary"
                                size="middle"
                                disabled={isUploading}>
                                Browse
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {isEditMode && (
                    <span
                      className="post-thumbnail-type position-absolute d-flex d-flex-middle d-flex-center cursor-pointer"
                      onClick={() => setIsUploadImageModal(true)}>
                      <i className="ci ci-image-upload"></i>
                    </span>
                  )}
                </div>
              )}
              <Card>
                {loading ? (
                  <>
                    <Skeleton.Input className="skeleton-input title" active />
                    <Skeleton.Input className="skeleton-input meta" active />
                    <Skeleton
                      className="skeleton desc"
                      title={false}
                      active
                      paragraph={{ rows: 7 }}
                    />
                  </>
                ) : (
                  <>
                    <h2 hidden={isEditMode} className="post-title">
                      {content?.title}
                    </h2>
                    <Input
                      className="title-edit input-style"
                      value={content?.title}
                      onChange={handleChangeTitle}
                      hidden={!isEditMode}
                    />
                    <div className="post-publish-date">
                      {content?.uploadedOn && (
                        <>
                          <span>
                            <b>Updated -</b> {getFormattedDate(content?.modifiedOn, "type1")}
                          </span>
                          <span className="divider-date">
                            <b>|</b>
                          </span>
                        </>
                      )}
                      {content?.modifiedOn && (
                        <span>
                          <b>Published -</b> {getFormattedDate(content?.uploadedOn, "type1")}
                        </span>
                      )}
                    </div>
                    <div className="post-content">
                      <p hidden={isEditMode}>{content?.summary}</p>
                      <div className="edit-mode" hidden={!isEditMode}>
                        <TextArea
                          value={content?.summary}
                          className="title-edit input-style"
                          rows={4}
                          onChange={handleChangeSummary}
                        />
                      </div>
                    </div>
                  </>
                )}
              </Card>
            </div>
          </Col>
          <Col span={8}>
            <div className="right-side-bar">
              <Card className="related-card">
                <h3 className="card-header-title">Related Pages</h3>
                <ul>
                  <li>
                    <Link to="">Aircraft Propulsion</Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                  <li>
                    <Link to="">Spacecraft Engineering</Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                  <li>
                    <Link to="">Structural Dynamics and Aeroelasticity</Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                  <li>
                    <Link to="">Aerodynamics and Fluid Mechanics</Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                  <li>
                    <Link to="">Vigilant Aerospace Systems, Inc.</Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                  <li>
                    <Link to="">Monroe Aerospace </Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                  <li>
                    <Link to="">Aerospace Projects Review Blog.</Link>
                    <i className="ri-arrow-right-s-line"></i>
                  </li>
                </ul>
              </Card>
              <Card className="contributor-card">{renderAssetAndContributor()}</Card>
            </div>
          </Col>
          <Col span={24}>
            <Card className="recommended-content">
              <div className="fix-bar d-flex d-flex-middle d-flex-between">
                <h3 className="card-header-title">Recommended content</h3>
                <div className="ant-tabs-extra-content">
                  <div className="view-change">
                    <Button
                      type="text"
                      className={`view-type ${viewType === ViewType.GRID ? "active" : ""}`}
                      onClick={() => dispatch(setViewType(ViewType.GRID))}>
                      <i className="ri-layout-grid-fill"></i>
                    </Button>
                    <Button
                      type="text"
                      className={`view-type ${viewType === ViewType.LIST ? "active" : ""}`}
                      onClick={() => dispatch(setViewType(ViewType.LIST))}>
                      <i className="ri-list-unordered"></i>
                    </Button>
                  </div>
                </div>
              </div>
              {viewType === ViewType.GRID
                ? recommendedContentGridData()
                : recommendedContentListData()}
            </Card>
          </Col>
        </Row>
      </motion.div>

      {/* Remove contributor modal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isOpenPeopleRemoveModal}
        handleCancel={() => setIsOpenPeopleRemoveModal(false)}
        handleSubmit={handleRemoveContributor}
        message="This will remove the selected contributor"
        title="Are you sure you want to remove?"
      />

      {/* Cancel warning Mmodal */}
      <DeleteModal
        isLoading={false}
        isModalOpen={isShowCancelWarnModal}
        handleCancel={() => setIsShowCancelWarnModal(false)}
        handleSubmit={handleResetChanges}
        message="This will remove your unsaved changes."
        title="Are you sure you want to cancel?"
        submitBtnName="Yes"
        cancelBtnName="No"
      />

      {/* Single content delete */}
      <DeleteModal
        isLoading={contentDeleteLoading}
        isModalOpen={isShowAssetDeleteModal}
        handleCancel={() => setIsShowAssetDeleteModal(false)}
        handleSubmit={handleDeleteContent}
        message="Are you sure you want to delete this asset?"
        title="Delete Asset"
      />

      <AddRecommendedAsset
        isModalOpen={isShowRecommendedAssetModal}
        title="Add  Assets to Recommended Content"
        handleCancel={() => setIsShowRecommendedAssetModal(false)}
        handleSubmit={handleAddAssetSubmit}
      />
    </>
  );
};

export default TopicAbout;
