import { toast } from "react-toastify";
import { store } from "../store";
import { userDeleteReq, userPostReq, userGetReq, userPutReq } from "./apiClients";
import {
  setIsPermissionFetching,
  setIsRoleDeleting,
  setIsRoleFetching,
  setIsRoleUpdating,
  setPermissionsList,
  setRolesList,
  setTotalRoles,
  updatePageRoleDelete,
} from "../store/roleManagement/roleManage.slice";
import { IRoleManagementForm } from "../store/roleManagement/roleManage.interface";
import { ApiErrorCode, PageLimit } from "../shared";

let abortController: AbortController | null = null;

export const fetchRolesList = async (searchText = "", page = 1, paginationRequired = true) => {
  const updatedPage = page < 1 ? 1 : page;
  try {
    store.dispatch(setIsRoleFetching(true));

    // Cancel the previous request if it's still in progress
    if (abortController) {
      abortController.abort();
    }
    // Create a new AbortController instance for the current request
    abortController = new AbortController();
    const { signal } = abortController;

    const res = await userGetReq(`/api/roles`, {
      signal,
      params: {
        page: updatedPage - 1,
        limit: PageLimit.RoleManagement,
        roleName: searchText || null,
        paginationRequired,
        sortDirection: "desc",
      },
    });

    store.dispatch(setRolesList(res?.content || []));
    store.dispatch(setTotalRoles(res?.totalElements || 0));
    return res;
  } catch (error: any) {
    if (error?.code !== ApiErrorCode?.ERR_CANCELED) {
      toast.error(error?.response?.data?.message || "Failed to fetch Roles");
      store.dispatch(setRolesList([]));
      store.dispatch(setTotalRoles(0));
    }
  } finally {
    store.dispatch(setIsRoleFetching(false));
  }
};

export const addRole = async (
  request: IRoleManagementForm,
  searchText: string,
  currPage: number
) => {
  try {
    store.dispatch(setIsRoleUpdating(true));
    const res = await userPostReq(`/api/roles`, { ...request, active: true });

    fetchRolesList(searchText, currPage);
    toast.success("Role added successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Failed to add Role");
    return error;
  } finally {
    store.dispatch(setIsRoleUpdating(false));
  }
};

export const updateRole = async (
  request: IRoleManagementForm,
  searchText: string,
  currPage: number
) => {
  try {
    store.dispatch(setIsRoleUpdating(true));
    const res = await userPutReq(`/api/roles/${request?.id}`, {
      roleId: request?.id,
      privilegeIds: request?.privilegeIds,
      name: request?.name,
    });

    fetchRolesList(searchText, currPage);
    toast.success("Role updated successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Failed to update Role");
    return error;
  } finally {
    store.dispatch(setIsRoleUpdating(false));
  }
};

export const deleteRole = async (
  id: number,
  searchText: string,
  currPage: number,
  totalRolesCount: number
) => {
  try {
    store.dispatch(setIsRoleDeleting(true));
    const res = await userDeleteReq(`/api/roles/${id}`);

    let newPage = currPage || 1;
    if (totalRolesCount % PageLimit.RoleManagement === 1) {
      newPage = newPage - 1;
      store.dispatch(updatePageRoleDelete(newPage));
    }

    fetchRolesList(searchText, newPage);
    toast.success("Role deleted successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Failed to delete Role");
    return error;
  } finally {
    store.dispatch(setIsRoleDeleting(false));
  }
};

export const updateRoleStatus = async (isActive: boolean, roleId: number | undefined) => {
  try {
    store.dispatch(setIsRoleUpdating(true));
    const res = await userPutReq(
      `/api/roles/${roleId}/status`,
      {},
      {
        params: {
          enable: isActive,
        },
      }
    );

    toast.success("Status updated successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Failed to update status");
    return error;
  } finally {
    store.dispatch(setIsRoleUpdating(false));
  }
};

export const fetchPermissionsList = async () => {
  try {
    store.dispatch(setIsPermissionFetching(true));
    const res = await userGetReq(`/api/privileges/getAll`);

    store.dispatch(setPermissionsList(res || []));
    return res;
  } catch (error: any) {
    store.dispatch(setPermissionsList([]));
    return error;
  } finally {
    store.dispatch(setIsPermissionFetching(false));
  }
};
