import { useEffect } from "react";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { Col, Flex, Modal, Row } from "antd";
import { HomeSlider, HomeFeatured } from "../../components/home";
import { RootState } from "../../store";
import { getOthersLookingAssets, getRecentlyViewedAssets } from "../../services/home";
import {
  FromWhere,
  IMAGES,
  ImageViewer,
  PATHS,
  PDFViewer,
  getIsContentFailed,
  getIsDisableEditContent,
  getIsSummaryProcessing,
  getLocalStorageItem,
  getUserFirstName,
  isEmpty,
} from "../../shared";
import "./HomePage.scss";
import { setIsPDFModalOpen } from "../../store/pdfViewer/pdfViewer.slice";
import {
  contentByIdInitialValue,
  setContentById,
  setSelectedAllTagsByAssestId,
  tagsByAssestIdInitValue,
} from "../../store/contentManagement/contentSlice";
import { IContent } from "../../store/contentManagement/content.interface";
import { updateViewCount } from "../../services/contentManagement";
import { useNavigate } from "react-router-dom";

const sliderSettings = {
  // slidesToShow: 3.8,
};

const HomePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const parsedUserDetails = localStorageItem && JSON.parse(localStorageItem);

  const { isPDFModalOpen, pdfContent } = useSelector((state: RootState) => state.pdfViewer);
  const { contents } = useSelector((state: RootState) => state.content);
  const {
    isRecentlyViewedAssetsLoading,
    recentlyViewedAssets,
    isOthersLookingAssetsLoading,
    othersLookingAssets,
  } = useSelector((state: RootState) => state.home);

  useEffect(() => {
    getRecentlyViewedAssets();
    getOthersLookingAssets();
  }, []);

  const renderHeader = () => {
    return (
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        className="header-page">
        <div className="header-inner">
          <h1 className="header-title">
            {!!parsedUserDetails?.name ? `Hi, ${getUserFirstName(parsedUserDetails?.name)} ` : `Hi`}
            <img className="handWave" src={IMAGES.handWave} alt="logo" />
          </h1>
          <div className="header-sub-text">
            Here's what's new and trending in your knowledge hub today.
          </div>
        </div>
      </motion.div>
    );
  };

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    updateViewCount(assetId);

    const content = contents.find((e) => e.asset_id === assetId);

    const isReadOnly = () => {
      return content
        ? getIsContentFailed(content) || getIsSummaryProcessing(content?.assetStatus)
        : false;
    };

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: isReadOnly(),
        fromWhere: FromWhere.Home,
      },
    });
  };

  const handleContentDetail = (content: IContent) => {
    if (getIsDisableEditContent(content)) {
      return () => {};
    }
    return handleViewEditContentNavigate(content?.asset_id);
  };

  const handlePDFClose = () => {
    dispatch(setIsPDFModalOpen(false));
  };

  return (
    <Flex className="home-page-wrap" vertical>
      {renderHeader()}

      <div className="content-wrap">
        <motion.div
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.25 }}>
          <Row className="home-featured-row">
            <Col span={24}>
              <HomeFeatured />
            </Col>
          </Row>
        </motion.div>

        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.5 }}>
          <Row className="content-Browsing-wrap content-col-wrap-two multiple-col">
            <Col span={24}>
              <HomeSlider
                title="Continue browsing"
                isLoading={isRecentlyViewedAssetsLoading}
                data={recentlyViewedAssets}
                description={true}
                fromWhere={FromWhere.RecentlyViewed}
              />
              <HomeSlider
                title="See what others are looking at"
                isLoading={isOthersLookingAssetsLoading}
                data={othersLookingAssets}
                fromWhere={FromWhere.OthersViewed}
                settings={sliderSettings}
              />
            </Col>
          </Row>
        </motion.div>
      </div>

      {/* Image Modal */}
      <ImageViewer handleDetail={() => handleContentDetail(pdfContent)} />

      {/* PDF Modal */}
      <Modal
        className="pdf-modal detail-open"
        centered={true}
        onCancel={handlePDFClose}
        footer={false}
        open={!isEmpty(pdfContent) && isPDFModalOpen}
        style={{ top: "20px", right: "20px", position: "absolute", marginRight: "20px" }}>
        <PDFViewer
          isRelevantAsset={true}
          isChatButton={true}
          content={pdfContent}
          btnClick={() => handleContentDetail(pdfContent)}
        />
      </Modal>
    </Flex>
  );
};

export default HomePage;
