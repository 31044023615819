import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAccessGroupProps, IaccessGroups } from "./accessGroup.interface";

const initialState: IAccessGroupProps = {
  accessGroups: [],
  isAccessGroupsLoading: false,
  isAccessGroupDeleting: false,
  isAccessGroupUpdating: false,
  accessGroupSearch: "",
  accessGroupPage: 1,
  totalAccessGroupsCount: 0,
};

export const accessGroup = createSlice({
  name: "accessGroup",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setAccessGroups: (state, action: PayloadAction<IaccessGroups[]>) => {
      state.accessGroups = action.payload;
    },

    setIsAccessGroupsLoading: (state, action: PayloadAction<boolean>) => {
      state.isAccessGroupsLoading = action.payload;
    },

    setIsAccessGroupUpdating: (state, action: PayloadAction<boolean>) => {
      state.isAccessGroupUpdating = action.payload;
    },

    deleteAccessGroupById: (state, action: PayloadAction<number>) => {
      state.accessGroups = state.accessGroups.filter((v) => v?.id !== action.payload);
    },

    setIsAccessGroupDeleting: (state, action: PayloadAction<boolean>) => {
      state.isAccessGroupDeleting = action.payload;
    },

    setAccessGroupSearch: (state, action: PayloadAction<string>) => {
      state.accessGroupSearch = action.payload;
    },

    setAccessGroupPage: (state, action: PayloadAction<number>) => {
      state.accessGroupPage = action.payload;
    },

    setTotalAccessGroupsCount: (state, action: PayloadAction<number>) => {
      state.totalAccessGroupsCount = action.payload;
    },

    resetAccessGroupFilters: (state) => {
      state.accessGroupPage = 1;
      state.accessGroupSearch = "";
    },

    updatePageOnAccessGrpDelete: (state, action: PayloadAction<number>) => {
      state.accessGroupPage = action.payload;
    },
  },
});

export const {
  setAccessGroups,
  setAccessGroupPage,
  setAccessGroupSearch,
  deleteAccessGroupById,
  setIsAccessGroupsLoading,
  setTotalAccessGroupsCount,
  setIsAccessGroupDeleting,
  setIsAccessGroupUpdating,
  resetAccessGroupFilters,
  updatePageOnAccessGrpDelete,
} = accessGroup.actions;

export default accessGroup.reducer;
