import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Button, Card, Col, Dropdown, Flex, Input, MenuProps, Row, Switch, Table } from "antd";
import { PageHeader, TableSkeleton, DeleteModal } from "../../../components";
import { RootState } from "../../../store";
import { IUser } from "../../../store/userManagement/user.interface";
import { setSearchText, setUserCurrPage } from "../../../store/userManagement/usersSlice";
import { deleteUser, fetchAllUsers, updateUserStatus } from "../../../services/userManagement";
import { getAccessGroups } from "../../../services/accessGroup";

import {
  useDebounce,
  PageLimit,
  CustomPagination,
  getLocalStorageItem,
  isEmpty,
} from "../../../shared";
import "./ToolConfiguration.scss";

const ToolConfiguration = () => {
  const dispatch = useDispatch();

  const {
    allUsers,
    userTableLoading,
    userDeleteLoading,
    totalUsersCount,
    userCurrPage,
    searchText,
  } = useSelector((state: RootState) => state.user);

  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState<boolean>(false);

  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};

  useEffect(() => {
    fetchAllUsers(searchText, userCurrPage);
  }, [userCurrPage]);

  useEffect(() => {
    getAccessGroups(true, "", 1);
  }, []);

  const handleUpdateStatus = (value: boolean, userDetials: IUser) => {
    updateUserStatus(value, userDetials?.id);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <div>Edit</div>,
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => {
            setIsShowDeleteUserModal(true);
          }}
          className="UserDeleteText">
          Delete
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: "Tool Name",
      dataIndex: "toolName",
      key: "toolName",
      width: "30%",
      render: () => (
        <>
          <span className="td-label">Tool Name : </span>
          <h5 className="toolname-heading">Content Retrieval</h5>
          <p className="toolname-pera">
            AI based retrieval tool which retrieves content and information from your content & data
            sources
          </p>
        </>
      ),
    },
    {
      title: "Tool prompt",
      dataIndex: "toolprompt",
      key: "toolprompt",
      width: "35%",
      render: () => (
        <>
          <span className="td-label">Tool prompt : </span>
          <div>&lt;tool1_name&gt;: Description of tool1, inputs, and output_type</div>
          <div>&lt;tool2_name&gt;: Description of tool2, inputs, and output_type</div>
          {/* <Input name="toolPrompt" /> */}
        </>
      ),
    },
    {
      title: "Model",
      dataIndex: "model",
      key: "model",
      width: "15%",
      render: () => (
        <>
          <span className="td-label">Model : </span>
          GPT 4o Mini
          {/* <Select
            defaultValue=" "
            placeholder="Select Model"
            style={{ width: "100%" }}
            popupClassName="ui-dropdown-default"
            options={[
              { value: "option1", label: "Option1" },
              { value: "option2", label: "Option2" },
              { value: "option3", label: "Option3" },
            ]}
          /> */}
        </>
      ),
    },

    {
      title: "Status",
      width: "10%",
      render: (_: string, user: IUser) => {
        return (
          <>
            <span className="td-label">Status : </span>
            <Switch
              disabled={user?.id === userDetails?.id}
              defaultChecked={user?.status === "A" ? true : false}
              onChange={(e) => handleUpdateStatus(e, user)}
            />
          </>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      width: "60px",
      className: "centered-column",
      render: (_: string, user: IUser) => (
        <>
          <span className="td-label">Action : </span>
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: () => {
                setSelectedUser(user);
              },
              items,
            }}
            overlayStyle={{ width: 80 }}
            overlayClassName="table-action-dropdown table-action-dropdown-user"
            placement="bottomRight">
            <Button className="more-action">
              <i className="ri-more-2-fill"></i>
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  // const handleLogout = () => {
  //   toast.success("Logout Successfully");
  //   persistStore(store).purge();
  //   removeLocalstorage();
  //   removeCookie();
  //   indexedDB.deleteDatabase("localforage");
  //   indexedDB.deleteDatabase("knowledge-management");
  //   setOldAppearance();
  //   navigate(PATHS.login);
  // };

  // const handleOnCancel = () => {
  //   setIsModalOpen(false);
  //   setSelectedUser(null);
  // };

  const getUsersBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) await fetchAllUsers(trimmedSearchText, 1);
    if (e.target.value.length === 0) {
      await fetchAllUsers("", 1);
    }
    dispatch(setUserCurrPage(1));
  };

  const handleSearchDebounce = useDebounce(getUsersBySearch, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounce(e);
    dispatch(setSearchText(e.target.value));
  };

  const handleDeleteUser = async () => {
    if (selectedUser?.id) await deleteUser(selectedUser?.id, searchText, userCurrPage);
    setIsShowDeleteUserModal(false);
    setSelectedUser(null);
    dispatch(setUserCurrPage(userCurrPage));
    setSelectedUser(null);
  };

  const handlePagination = (page: number) => {
    dispatch(setUserCurrPage(page));
  };

  return (
    <Flex className="admin-inner-wrap user-managements-page" vertical>
      <PageHeader
        title="Tool Configuration"
        isRelevantAsset={false}
        content={
          <>
            <Input
              type="search"
              className="header-search"
              placeholder="Search here..."
              prefix={<i className="ri-search-line" />}
              onChange={(e) => {
                handleChangeSearch(e);
              }}
              defaultValue={searchText}
              value={searchText}
              allowClear
            />
            {/* <Button
              className="btn"
              type="default"
              icon={<i className="ri-add-circle-fill"></i>}
              onClick={() => setIsModalOpen(true)}>
              Add New Model
            </Button> */}
          </>
        }
      />

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}>
        <Row>
          <Col span={24}>
            <Card className={`global-table-card ${isEmpty(allUsers) ? "global-table-empty" : ""}`}>
              {userTableLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table responsive-table-user-management"
                  columns={columns}
                  dataSource={allUsers?.length ? allUsers : []}
                  // dataSource={allUsers?.length ? allUsers?.slice(0, 1) : []}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 188px)" }}
                  pagination={false}
                />
              )}

              {allUsers && allUsers.length ? (
                <CustomPagination
                  currentPage={userCurrPage}
                  total={totalUsersCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.UserManagement}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </motion.div>

      {/* {!!isModalOpen && (
        <AddEditModelConfiguration
          title={"Add Model"}
          isModalOpen={isModalOpen}
          onCancel={handleOnCancel}
          handleLogout={handleLogout}
        />
      )} */}

      {/* delete user modal */}
      <DeleteModal
        isLoading={userDeleteLoading}
        isModalOpen={isShowDeleteUserModal}
        handleCancel={() => {
          setIsShowDeleteUserModal(false);
          setSelectedUser(null);
        }}
        handleSubmit={handleDeleteUser}
        message="Are you sure you want to delete this Tool?"
        title="Delete Tool"
      />
    </Flex>
  );
};

export default ToolConfiguration;
