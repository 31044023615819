import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Flex,
  Modal,
  Popover,
  Row,
  Table,
  Tooltip,
  Switch,
  Dropdown,
  MenuProps,
} from "antd";
import "./Source.scss";
import { RootState } from "../../store";
import { setViewType } from "../../store/appState/appSlice";
import { ITaxonomyTree } from "../../store/taxonomy/taxonomy.interface";
import { isSourceFilterEmpty } from "../../shared/helper/contentHelper";
import { setIsPDFModalOpen } from "../../store/pdfViewer/pdfViewer.slice";
import { getAllTaxonomyNodes, getTaxonomyByTitle } from "../../services/taxonomy";
import { setSelectedFilesForAssetFilter } from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  AssetTags,
  AssetUploadStatus,
  BookmarkIcon,
  ContentSourceFilter,
  CustomPagination,
  FileCardType,
  FromWhere,
  NoContentData,
  PATHS,
  PageLimit,
  ViewType,
  getFormattedDate,
  renderFileTypeIcon,
  AssetSummaryPopover,
  getIsDisableEditContent,
  isEmpty,
  getIsContentFailed,
  getIsAssetProcessing,
  getIsSummaryProcessing,
  PDFViewer,
  getCardOwnersFullName,
  TaxonomyTitle,
  assetEditPermission,
  ImageViewer,
  handleOpenDocClick,
  checkPlatformPermission,
  Permissions,
} from "../../shared";
import {
  ContentChatButton,
  DeleteModal,
  FileCard,
  FileCardSkeleton,
  PageHeader,
  TableSkeleton,
  UploadAssets,
} from "../../components";
import {
  bookmarkContent,
  deleteAssetById,
  getAllContent,
  getAssetDetailsByAssetId,
  likeContent,
  updateViewCount,
} from "../../services/contentManagement";
import {
  IContent,
  IContentFilter,
  ITagsByAssestId,
} from "../../store/contentManagement/content.interface";
import {
  contentByIdInitialValue,
  contentFilterField,
  setAllContentDetails,
  setContentById,
  setSelectedAllTagsByAssestId,
  setUpdateSourceFilter,
  tagsByAssestIdInitValue,
  setUpdateAllSourceFilter,
  clearSourceFilterWithoutBookmark,
  updateAssetByAssetId,
  sourceFilterInitValue,
  setSelectionAppliedForSources,
  setSelectContentDetails,
  setContentFilesInContext,
  setContentSelectedIds,
  setAllAssetsSelected,
} from "../../store/contentManagement/contentSlice";

interface ISourcesProps {
  isProfile: boolean;
}

const Sources = (props: ISourcesProps) => {
  const { isProfile } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    contents,
    isContentTableLoading,
    totalContentsCount,
    contentDeleteLoading,
    sourceFilter,
    contentFilesInContext,
  } = useSelector((state: RootState) => state.content);
  const { viewType } = useSelector((state: RootState) => state.app);
  const { isPDFModalOpen, pdfContent } = useSelector((state: RootState) => state.pdfViewer);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [pendingContents, setPendingContents] = useState<IContent[]>([]);
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState<IContentFilter>(sourceFilterInitValue);

  const isDataPersist = location?.state?.isDataPersist || false;
  const currentPage = sourceFilter?.currentPage ?? 1;
  let contentInterval: any;

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (contents && contents.length) {
        contents.forEach((content) => {
          if (
            getIsAssetProcessing(content?.assetStatus) ||
            content?.assetStatus === AssetUploadStatus.STOPPING ||
            getIsSummaryProcessing(content?.summaryStatus)
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  const resetIsDataPersistRoute = () => {
    navigate(PATHS.sources, { state: { isDataPersist: false } });
  };

  useEffect(() => {
    getAllTaxonomyNodes();
  }, []);

  useEffect(() => {
    if (!isDataPersist) {
      getTaxonomyByTitle(TaxonomyTitle.Industry);
      getTaxonomyByTitle(TaxonomyTitle.Function);
      getTaxonomyByTitle(TaxonomyTitle.AssetType);
      getAllContent(sourceFilter, isProfile);
    } else {
      resetIsDataPersistRoute();
    }

    return () => {
      dispatch(setContentFilesInContext([]));
      dispatch(setContentSelectedIds([]));
      dispatch(setAllAssetsSelected(false));
    };
  }, []);

  const handleContentPulling = () => {
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }
  };

  useEffect(() => {
    handleContentPulling();

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  useEffect(() => {
    localFilters?.isFilterVisible
      ? document.body.classList.add("content-filter-visible")
      : document.body.classList.remove("content-filter-visible");
  }, [localFilters]);

  useEffect(() => {
    setLocalFilters(sourceFilter);
  }, [sourceFilter]);

  const handleViewEditContentNavigate = (assetId: number, isEditMode = false) => {
    dispatch(setContentById(contentByIdInitialValue));
    dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
    dispatch(setUpdateSourceFilter({ field: contentFilterField?.currentPage, value: currentPage }));
    updateViewCount(assetId);

    const content = contents.find((e) => e.asset_id === assetId);

    navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
      state: {
        isEditMode,
        isReadOnly: content ? getIsContentFailed(content) : false,
        fromWhere: FromWhere.Source,
      },
    });
  };

  const findNodeNameByValue = (data: ITaxonomyTree[], value: number): string => {
    for (const item of data) {
      if (item.taxonomyNodeId === value) {
        return item.taxonomyNodeName;
      }
      if (item.children) {
        const result = findNodeNameByValue(item.children, value);
        if (result) {
          return result;
        }
      }
    }
    return "";
  };

  const handleDeleteSource = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        totalContentsCount,
        false,
        sourceFilter,
        "sources"
      );
      if (res?.data) {
        let contentsValues = [...contents];
        contentsValues = !isEmpty(contentsValues)
          ? contentsValues?.filter((item) => item.asset_id !== selectedContent?.asset_id)
          : [];
        dispatch(setAllContentDetails(contentsValues));

        const filteredContext = contentFilesInContext.filter(
          (c) => selectedContent?.asset_id !== c.asset_id
        );
        dispatch(setSelectedFilesForAssetFilter(filteredContext));
      }
    }

    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    dispatch(setUpdateSourceFilter({ field: contentFilterField?.currentPage, value: currentPage }));
  };

  const handleSelectAllContents = (e: any) => {
    if (contents && contents.length) {
      const filteredContents = [...contents].map((item) => ({
        ...item,
        isSelected: getIsDisableEditContent(item) ? false : e?.target?.checked,
      }));
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(filteredContents));
    }
  };

  const handleSelectAllOnEveryPage = (e: any) => {
    if (contents && contents.length) {
      const filteredContents = [...contents].map((item) => ({
        ...item,
        isSelected: getIsDisableEditContent(item) ? false : e?.target?.checked,
      }));
      dispatch(setAllAssetsSelected(e?.target?.checked));
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(filteredContents));
    }
  };

  const getEditableContents = () => {
    let editableContents: number[] = [];
    if (contents && contents.length) {
      editableContents = contents
        .filter((item) => !getIsDisableEditContent(item))
        .map((item) => item?.asset_id);
    }
    return editableContents || [];
  };

  const getSelectedContents = () => {
    let selectedContents: number[] = [];
    if (contents && contents.length) {
      selectedContents = contents
        .filter((item) => !getIsDisableEditContent(item) && item?.isSelected)
        .map((item) => item?.asset_id);
    }
    return selectedContents || [];
  };

  const rowSelection = {
    selectedRowKeys: getSelectedContents(),
    onChange: (selectedRowKeys: any[]) => {
      let updatedContent: IContent[] = [];
      if (contents && contents?.length) {
        contents.forEach((item) => {
          if (selectedRowKeys.includes(item?.asset_id)) {
            updatedContent.push({ ...item, isSelected: true });
          } else {
            updatedContent.push({ ...item, isSelected: false });
          }
        });
      }
      dispatch(setSelectionAppliedForSources(false));
      dispatch(setSelectContentDetails(updatedContent));
    },
    getCheckboxProps: (record: IContent) => ({
      disabled: getIsDisableEditContent(record),
    }),
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const res = await bookmarkContent(content.asset_id, content?.is_bookmarked ? false : true);
    if (res) {
      const updatedContent = {
        ...content,
        is_bookmarked: !content?.is_bookmarked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handleLike = async (
    content: IContent,
    index: number,
    isLiked: boolean | null,
    flag: string
  ) => {
    const res = await likeContent(content.asset_id, isLiked, flag);
    if (res) {
      const updatedContent = {
        ...content,
        is_liked: isLiked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
    }
  };

  const handlePagination = (page: number) => {
    dispatch(setUpdateAllSourceFilter({ ...sourceFilter, currentPage: page }));
    getAllContent({ ...sourceFilter, currentPage: page }, isProfile);
  };

  const chatOption = (content: IContent) => {
    return isContentTableLoading || getIsDisableEditContent(content) ? (
      <></>
    ) : (
      <Tooltip
        placement="topRight"
        trigger="hover"
        title="Chat with this asset"
        rootClassName="start-chat-tooltip">
        <ContentChatButton {...{ content, isVisible: true }} />
      </Tooltip>
    );
  };

  const fileHoverOptions = (content: IContent, index: number) => {
    return (
      <>
        <Button
          className={content?.is_liked === true ? "btn active" : "btn"}
          type="text"
          onClick={() =>
            handleLike(content, index, content?.is_liked === true ? null : true, "like")
          }
          icon={<i className="ri-thumb-up-fill"></i>}></Button>
        <Button
          className={content?.is_liked === false ? "btn active" : "btn"}
          type="text"
          onClick={() =>
            handleLike(content, index, content?.is_liked === false ? null : false, "dislike")
          }
          icon={<i className="ri-thumb-down-fill"></i>}></Button>
      </>
    );
  };

  const handleContentDetail = (content: IContent) => {
    if (getIsDisableEditContent(content)) {
      return () => {};
    }
    return handleViewEditContentNavigate(content?.asset_id);
  };

  const getItems = (content: IContent) => {
    let items: MenuProps["items"] = [];

    !isProfile &&
      checkPlatformPermission(Permissions.EDIT_DOC) &&
      items.push({
        key: 1,
        label: (
          <div
            onClick={() => {
              handleViewEditContentNavigate(selectedContent?.asset_id || 0, true);
            }}>
            Edit
          </div>
        ),
        className: assetEditPermission(content) ? "" : "action-disable",
      });

    checkPlatformPermission(Permissions.DELETE_DOC) &&
      items.push({
        key: 2,
        label: (
          <div onClick={() => setIsShowAssetDeleteModal(true)} className="taxonomyDeleteText">
            Delete
          </div>
        ),
        className: assetEditPermission(content) ? "" : "action-disable",
      });

    return items;
  };

  const threeDotsMenu = (content: IContent) => {
    return (
      <Dropdown
        trigger={["click"]}
        menu={{
          onClick: () => {
            setSelectedContent(content);
          },
          items: getItems(content),
        }}
        open={content.asset_id === selectedContent?.asset_id && isDropdownOpen}
        onOpenChange={(open) => setIsDropdownOpen(open)}
        overlayClassName="table-action-dropdown content-dropdown"
        placement="bottomRight">
        <Button className="custom-icon more-action" onClick={() => setSelectedContent(content)}>
          <i className="ri-more-2-fill" />
        </Button>
      </Dropdown>
    );
  };

  const fileExtraOptions = (content: IContent) => {
    return <>{threeDotsMenu(content)}</>;
  };

  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        {chatOption(content)}
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isContentTableLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const renderOwnerUI = (data: IContent) => {
    return (
      <div className="author-info">
        <div className="profile-pic">
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
        <span className="author-name">{getCardOwnersFullName(data?.user)}</span>
      </div>
    );
  };

  const renderTableColumns = () => {
    const tableColumns: any = [
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        width: "200px",
        className: "title-table-col",
        render: (text: string, content: IContent) => (
          <>
            <span className="td-label">Title : </span>
            <Popover
              placement="bottomLeft"
              rootClassName="custom-description-root"
              content={
                <AssetSummaryPopover content={content} tags={false} fromWhere={FromWhere.Source} />
              }
              trigger="hover">
              <strong
                className={`${"cursor-pointer title-col float-title d-flex d-flex-middle"} `}
                onClick={() => handleOpenDocClick(content, handleViewEditContentNavigate)}>
                <div
                  className="file-bg"
                  style={{ backgroundImage: `url(${content?.thumbnail_pre_signed_url})` }}></div>
                {text ? text : content?.original_file_name}
              </strong>
            </Popover>
          </>
        ),
      },
      {
        title: "Type",
        key: "docType",
        width: "110px",
        render: (content: IContent) => (
          <>
            <span className="td-label">Type : </span>
            <span className="doc-type">
              <div className="profile-pic">
                <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" />
              </div>
              {content?.file_type}
            </span>
          </>
        ),
      },
      {
        title: isProfile ? "Uploader" : "Owner",
        key: "Owner",
        width: "150px",
        render: (content: IContent) => (
          <>
            <span className="td-label">Owner : </span>
            {renderOwnerUI(content)}
          </>
        ),
      },
      {
        title: "Last Updated",
        dataIndex: "modifiedOn",
        key: "modifiedOn",
        width: "120px",
        render: (date: string) => (
          <>
            <span className="td-label">Last Updated : </span>
            {getFormattedDate(date, "type1")}
          </>
        ),
      },
      {
        title: "Tags",
        dataIndex: "linkedTags",
        key: "linkedTags",
        width: "200px",
        hidden: true,
        render: (linkedTags: ITagsByAssestId, content: IContent) => (
          <AssetTags {...{ linkedTags, content }} fromWhere={FromWhere.Source} />
        ),
      },
      {
        title: "Content Source",
        dataIndex: "contentSource",
        key: "contentSource",
        width: "160px",
        render: () => (
          <>
            <span className="td-label">Content Source : </span>
            <span>User Uploaded</span>
          </>
        ),
      },
      {
        title: "Action",
        key: "action",
        width: "90px",
        fixed: "right",
        className: "fix-action",
        render: (_: any, content: IContent, index: number) => {
          return (
            <>
              <span className="td-label">Action : </span>
              <div className="extra-options">
                <div className="file-extra-options">
                  {fileExtraOptionsTwo(content, index)}
                  {threeDotsMenu(content)}
                </div>
              </div>
            </>
          );
        },
      },
    ];

    if (isProfile) {
      return tableColumns.filter((v: any) => v.title !== "Tags" && v.title !== "Content Source");
    }
    return tableColumns;
  };

  const tabDataGrid = () => {
    return (
      <>
        <div className="page-scroll">
          <Row className="tab-row-inner">
            {isContentTableLoading ? (
              <FileCardSkeleton />
            ) : !isEmpty(contents) ? (
              contents.map((item: IContent, index: number) => (
                <Fragment key={`${index}-${item.asset_id}`}>
                  <FileCard
                    data={item}
                    type={FileCardType.GENERATED}
                    extraOptions={fileExtraOptions(item)}
                    extraOptionsTwo={fileExtraOptionsTwo(item, index)}
                    hoverOptions={fileHoverOptions(item, index)}
                    onClick={() => handleOpenDocClick(item, handleViewEditContentNavigate)}
                    fromWhere={FromWhere.Source}
                  />
                </Fragment>
              ))
            ) : (
              <NoContentData />
            )}
          </Row>
        </div>
      </>
    );
  };

  const tabDataList = () => {
    return (
      <>
        <Row className="tab-row-inner list-view">
          <Col span={24}>
            <Card className="global-table-card">
              {isContentTableLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table"
                  rowKey={"asset_id"}
                  columns={renderTableColumns()}
                  dataSource={contents?.length ? contents : []}
                  locale={{ emptyText: <NoContentData /> }}
                  tableLayout="fixed"
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  scroll={{ y: "calc(100vh - 235px)", x: "100" }}
                  pagination={false}
                />
              )}
            </Card>
          </Col>
        </Row>
      </>
    );
  };

  const handleBookmarkFilter = async (checked: boolean) => {
    if (typeof checked === "boolean") {
      dispatch(
        setUpdateSourceFilter({
          field: contentFilterField?.isBookmarked,
          value: checked,
        })
      );
      await getAllContent(
        {
          ...sourceFilter,
          isBookmarked: checked,
        },
        isProfile
      );
    }
  };

  const toggleFilter = () => {
    dispatch(
      setUpdateAllSourceFilter({ ...localFilters, isFilterVisible: !localFilters?.isFilterVisible })
    );
  };

  const handleResetFilter = async (isToastShow = true) => {
    dispatch(clearSourceFilterWithoutBookmark());
    await getAllContent(
      { ...sourceFilterInitValue, isBookmarked: sourceFilter?.isBookmarked },
      isProfile
    );
    isToastShow && toast.success("Filter successfully reset");
  };

  const handleApplyFilter = async (localFilters: IContentFilter) => {
    const res = await getAllContent(
      {
        ...localFilters,
        isBookmarked: sourceFilter?.isBookmarked,
      },
      isProfile
    );
    if (res) {
      dispatch(setUpdateAllSourceFilter(localFilters));
      dispatch(
        setUpdateSourceFilter({
          field: contentFilterField?.isApplied,
          value: true,
        })
      );
      dispatch(
        setUpdateSourceFilter({
          field: contentFilterField?.isFilterChanged,
          value: false,
        })
      );
      toast.success("Filter successfully applied");
    }
  };

  //reload contents for selected filter comes from the conversation
  useEffect(() => {
    let routeState = location.state;
    if (
      !isEmpty(routeState) &&
      routeState?.isReloadData &&
      !isEmpty(localFilters) &&
      JSON.stringify(sourceFilter) === JSON.stringify(localFilters)
    ) {
      handleApplyFilter(localFilters);
      navigate("", { state: { isReloadData: false } });
    }
  }, [location, localFilters]);

  const handlePDFClose = () => {
    dispatch(setIsPDFModalOpen(false));
  };

  const isShowPagination = () => {
    return (
      contents && contents.length && (totalContentsCount || 1) / PageLimit.ContentManagement > 1
    );
  };

  return (
    <Flex
      className={`handle-layout inner-app-wrap sources-page ${
        !isShowPagination() ? "no-pagination" : ""
      }`}
      vertical>
      <PageHeader
        title={isProfile ? "Resumes" : "Sources"}
        isRelevantAsset={false}
        content={
          <>
            <div className="bookmark-filter">
              <span className="text">Bookmarked</span>
              <Switch
                size="small"
                checked={sourceFilter?.isBookmarked ? true : false}
                onChange={handleBookmarkFilter}
              />
            </div>

            {!isProfile && (
              <Button
                type="text"
                className="filter-toggle-btn"
                onClick={toggleFilter}
                icon={<i className="ri-equalizer-fill"></i>}>
                Filters
              </Button>
            )}

            {checkPlatformPermission(Permissions.UPLOAD_DOC) && (
              <Button
                className="btn upload-btn"
                type="primary"
                onClick={() => setIsOpenUploadAsset(true)}>
                Upload
              </Button>
            )}

            <div className="view-change">
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.GRID ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.GRID))}>
                <i className="ri-layout-grid-fill"></i>
              </Button>
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.LIST ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.LIST))}>
                <i className="ri-list-unordered"></i>
              </Button>
            </div>
          </>
        }
      />

      {localFilters?.isFilterVisible && (
        <motion.div
          className="content-source-filter"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}>
          <ContentSourceFilter
            isShowContentType={false}
            isShowContentStatus={false}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            isFilterSelected={!isSourceFilterEmpty(localFilters)}
          />
        </motion.div>
      )}

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25 }}>
        <div className="relevant-assets-wrap">
          <Card className="relevant-assets">
            <div className="top-bar d-flex d-flex-middle d-flex-between">
              <div className="ant-tabs-nav-wrappe">
                <div className="ant-tabs-nav-container" hidden={isEmpty(contents)}>
                  <Dropdown
                    trigger={["click"]}
                    menu={{
                      items: [
                        {
                          key: 0,
                          label: (
                            <Checkbox
                              className="select-label hide-check"
                              onChange={handleSelectAllContents}>
                              Select All on this page ({contents?.length} assets)
                            </Checkbox>
                          ),
                        },
                        {
                          key: 1,
                          disabled: true,
                          label: (
                            <Checkbox
                              disabled={true}
                              className="select-label hide-check"
                              onChange={handleSelectAllOnEveryPage}>
                              Select All ({totalContentsCount} assets)
                            </Checkbox>
                          ),
                        },
                      ],
                    }}
                    overlayClassName="table-action-dropdown content-dropdown"
                    placement="bottomLeft">
                    <Checkbox
                      className="select-label"
                      checked={getSelectedContents().length === getEditableContents().length}
                      indeterminate={
                        getSelectedContents().length &&
                        getSelectedContents().length < getEditableContents().length
                          ? true
                          : false
                      }>
                      {getSelectedContents().length
                        ? `${getSelectedContents().length} Out of ${contents?.length} selected`
                        : "Select All"}
                    </Checkbox>
                  </Dropdown>

                  {/* <Checkbox
                    className="select-label"
                    checked={getSelectedContents().length === getEditableContents().length}
                    indeterminate={
                      getSelectedContents().length &&
                      getSelectedContents().length < getEditableContents().length
                        ? true
                        : false
                    }
                    onChange={handleSelectAllContents}>
                    {getSelectedContents().length ? (
                      `${getSelectedContents().length} Out of ${contents?.length} selected`
                    ) : (
                      <>
                        Select All <span>(Showing {contents?.length} results)</span>
                      </>
                    )}
                  </Checkbox> */}
                </div>
              </div>

              {!isContentTableLoading && (
                <div className="ant-tabs-extra-content">
                  <div className="btn-wrap">
                    <ContentChatButton
                      {...{
                        localFilters,
                        selectedContents: !isEmpty(contents)
                          ? contents.filter((content) => content.isSelected)
                          : [],
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            {viewType === ViewType.GRID ? tabDataGrid() : tabDataList()}
            {isShowPagination() ? (
              <div className="bottom-bar d-flex d-flex-middle d-flex-right">
                <CustomPagination
                  currentPage={currentPage}
                  total={totalContentsCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.ContentManagement}
                />
              </div>
            ) : (
              <></>
            )}
          </Card>
        </div>
      </motion.div>

      {isOpenUploadAsset && (
        <UploadAssets
          isModalOpen={isOpenUploadAsset}
          setIsModalOpen={setIsOpenUploadAsset}
          fromWhere={FromWhere.Source}
          toWhere={PATHS.myContent}
        />
      )}

      {/* Image Modal */}
      <ImageViewer handleDetail={() => handleContentDetail(pdfContent)} />

      {/* PDF Modal */}
      <Modal
        className="pdf-modal detail-open"
        centered={true}
        onCancel={handlePDFClose}
        footer={false}
        open={!isEmpty(pdfContent) && isPDFModalOpen}
        style={{ top: "20px", right: "20px", position: "absolute", marginRight: "20px" }}>
        <PDFViewer
          isRelevantAsset={true}
          isChatButton={true}
          content={pdfContent}
          btnClick={() => handleContentDetail(pdfContent)}
        />
      </Modal>

      {/* delete Content modal */}
      <DeleteModal
        isLoading={contentDeleteLoading}
        isModalOpen={isShowAssetDeleteModal}
        handleCancel={() => setIsShowAssetDeleteModal(false)}
        handleSubmit={handleDeleteSource}
        message="Are you sure you want to delete this asset?"
        title="Delete Asset"
      />
    </Flex>
  );
};

export default Sources;
