import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { persistStore } from "redux-persist";
import { useNavigate } from "react-router-dom";
import { store } from "../../store";
import { setFindProfileModel, setIsShowDeletedUserModal } from "../../store/appState/appSlice";
import { setOldAppearance } from "../../services/appAppearanceApi";
import { PATHS } from "../constants";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    store.dispatch(setIsShowDeletedUserModal(false));
    toast.success("Logout Successfully");
    persistStore(store).purge();
    localStorage.removeItem("user_foundationKM");
    localStorage.removeItem("token_foundationKM");
    indexedDB.deleteDatabase("localforage");
    indexedDB.deleteDatabase("knowledge-management");
    setOldAppearance();
    navigate(PATHS.login);
    dispatch(setFindProfileModel(false));
  };

  return {
    handleLogout,
  };
};
