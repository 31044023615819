import { PURGE } from "redux-persist";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IAuth, IPlatformRole } from "./auth.interface";

const initialState: IAuth = {
  loginLoading: false,
  userId: -1,
  isSendOtpLoading: false,
  isVerifyOtpLoading: false,
  userEmailAtForgetPassword: "",
  isCreatePasswordLoading: false,
  otpAtForgetPassword: -1,
  resetPasswordLoading: false,
  chatThreadID: null,
  platformAccess: {
    isPlatformAdmin: false,
    role: {
      id: -1,
      name: "",
      privileges: [],
      status: false,
    },
  },
};

export const authSlice = createSlice({
  name: "authSlice",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      Object.assign(state, initialState);
    });
  },
  reducers: {
    setLoginLoading: (state, action: PayloadAction<boolean>) => {
      state.loginLoading = action.payload;
    },

    setUserId: (state, action: PayloadAction<number>) => {
      state.userId = action.payload;
    },

    setIsSendOtpLoading: (state, action: PayloadAction<boolean>) => {
      state.isSendOtpLoading = action.payload;
    },

    setIsVerifyOtpLoading: (state, action: PayloadAction<boolean>) => {
      state.isVerifyOtpLoading = action.payload;
    },

    setUserEmailAtForgetPassword: (state, action: PayloadAction<string>) => {
      state.userEmailAtForgetPassword = action.payload;
    },

    setIsCreatePasswordLoading: (state, action: PayloadAction<boolean>) => {
      state.isCreatePasswordLoading = action.payload;
    },

    setOtpAtForgetPassword: (state, action: PayloadAction<number>) => {
      state.otpAtForgetPassword = action.payload;
    },

    resetOtpPassword: (state, _) => {
      state.otpAtForgetPassword = -1;
      state.userEmailAtForgetPassword = "";
    },

    setRestPasswordLoading: (state, action: PayloadAction<boolean>) => {
      state.resetPasswordLoading = action.payload;
    },

    setChatThreadID: (state, action: PayloadAction<string | null>) => {
      state.chatThreadID = action.payload;
    },

    setPlatformAccess: (
      state,
      action: PayloadAction<{ isPlatformAdmin: boolean; role: IPlatformRole }>
    ) => {
      state.platformAccess = {
        isPlatformAdmin: action?.payload?.isPlatformAdmin,
        role: action?.payload?.role,
      };
    },
  },
});

export const {
  setUserId,
  setLoginLoading,
  resetOtpPassword,
  setChatThreadID,
  setPlatformAccess,
  setIsSendOtpLoading,
  setIsVerifyOtpLoading,
  setRestPasswordLoading,
  setOtpAtForgetPassword,
  setIsCreatePasswordLoading,
  setUserEmailAtForgetPassword,
} = authSlice.actions;

export default authSlice.reducer;
