import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Dropdown,
  Flex,
  MenuProps,
  Popover,
  Row,
  Switch,
  Table,
  Tabs,
  TabsProps,
  Tooltip,
  Modal,
} from "antd";
import "./MyContent.scss";
import { RootState } from "../../store";
import { StatsCard } from "../../components/myContent";
import { setViewType } from "../../store/appState/appSlice";
import { getTaxonomyByTitle } from "../../services/taxonomy";
import { setIsPDFModalOpen } from "../../store/pdfViewer/pdfViewer.slice";
import { isMyContentFilterEmpty } from "../../shared/helper/contentHelper";
import { setSelectedFilesForAssetFilter } from "../../store/conversationalSearch/conversationalSearchSlice";
import {
  ContentActionButton,
  ContentChatButton,
  DeleteModal,
  FileCard,
  FileCardSkeleton,
  PageHeader,
  TableSkeleton,
  UploadAssets,
} from "../../components";
import {
  ContentSourceFilter,
  CustomPagination,
  AssetTags,
  getIsDisableEditContent,
  getIsContentFailed,
  isEmpty,
  getIsSummaryProcessing,
  getIsAssetProcessing,
  PDFViewer,
  getCardOwnersDetail,
  getCardOwnersFullName,
  FileLocation,
  renderFileTypeIcon,
  AssetStatus,
  FileLocationLabel,
  TaxonomyTitle,
  renderSourceType,
  assetEditPermission,
  ImageViewer,
  handleOpenDocClick,
  AssetUploadStatus,
  BookmarkIcon,
  FileCardType,
  FromWhere,
  NoContentData,
  PATHS,
  PageLimit,
  ViewType,
  getFormattedDate,
  AssetSummaryPopover,
  checkPlatformPermission,
  Permissions,
} from "../../shared";
import {
  bookmarkContent,
  deleteAssetById,
  disablePublish,
  enablePublish,
  getAllContent,
  getAssetCount,
  getAssetDetailsByAssetId,
  retryProcess,
  stopProcessing,
  updateAssetFileLevel,
  updateViewCount,
} from "../../services/contentManagement";
import {
  clearContentFilterWithoutBookmark,
  contentByIdInitialValue,
  contentFilterField,
  contentFilterInitValue,
  setAllContentDetails,
  setContentById,
  setContentFilesInContext,
  setContentSelectedIds,
  setSelectContentDetails,
  setSelectedAllTagsByAssestId,
  setSelectionAppliedForContent,
  setUpdateAllContentFilter,
  setUpdateContentFilter,
  setUpdatePublishDraft,
  tagsByAssestIdInitValue,
  updateAssetByAssetId,
} from "../../store/contentManagement/contentSlice";
import {
  IContent,
  IContentFilter,
  ITagsByAssestId,
} from "../../store/contentManagement/content.interface";

interface IMyContentProps {
  isProfile: boolean;
}

const MyContent = (props: IMyContentProps) => {
  const { isProfile } = props;

  const { viewType } = useSelector((state: RootState) => state.app);
  const {
    contents,
    isContentTableLoading,
    totalContentsCount,
    contentDeleteLoading,
    contentFilter,
    contentFilesInContext,
    isRetryLoading,
    isFileLevelUpdating,
  } = useSelector((state: RootState) => state.content);
  const { isPDFModalOpen, pdfContent } = useSelector((state: RootState) => state.pdfViewer);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [pendingContents, setPendingContents] = useState<IContent[]>([]);
  const [isOpenUploadAsset, setIsOpenUploadAsset] = useState<boolean>(false);
  const [selectedContent, setSelectedContent] = useState<IContent | null>(null);
  const [isShowAssetDeleteModal, setIsShowAssetDeleteModal] = useState<boolean>(false);
  const [localFilters, setLocalFilters] = useState<IContentFilter>(contentFilterInitValue);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  let contentInterval: any;
  const currentPage = contentFilter?.currentPage ?? 1;
  const isDataPersist = location?.state?.isDataPersist || false;

  const setSelectedFiles = (data: IContent[]) => {
    if (!isEmpty(data)) {
      const values = data.map((item: IContent) => ({
        ...item,
        isSelected: getSelectedContents().includes(item?.asset_id) ? true : false,
      }));
      dispatch(setSelectContentDetails(values));
    }
  };

  useEffect(() => {
    setLocalFilters(contentFilter);
  }, [contentFilter]);

  const handleSetPendingContents = () => {
    if (!isContentTableLoading) {
      let filteredPendingContents: IContent[] = [];
      if (contents && contents.length) {
        contents.forEach((content: IContent) => {
          if (
            getIsAssetProcessing(content?.assetStatus) ||
            content?.assetStatus === AssetUploadStatus.STOPPING ||
            getIsSummaryProcessing(content?.summaryStatus)
          ) {
            filteredPendingContents.push(content);
          }
        });
      }
      setPendingContents(filteredPendingContents);
    }
  };

  useEffect(() => {
    handleSetPendingContents();
  }, [currentPage, isContentTableLoading, contents]);

  const handleContentPulling = () => {
    if (pendingContents?.length > 0) {
      contentInterval = setInterval(async () => {
        for (const file of pendingContents) {
          let res = await getAssetDetailsByAssetId(file.asset_id);
          if (
            (res?.asset_id && res?.assetStatus === AssetUploadStatus.COMPLETED) ||
            res.assetStatus === AssetUploadStatus.FAILED ||
            res.assetStatus === AssetUploadStatus.FAILED_IN_LLM ||
            res.assetStatus === AssetUploadStatus.STOPPED
          ) {
            dispatch(updateAssetByAssetId({ data: res, id: res.asset_id }));
            setPendingContents(pendingContents.filter((asset) => asset.asset_id !== res.asset_id));
          }
        }
      }, 10000);
    } else {
      clearInterval(contentInterval);
    }
  };

  useEffect(() => {
    handleContentPulling();

    return () => {
      clearInterval(contentInterval);
    };
  }, [pendingContents]);

  const resetIsDataPersistRoute = () => {
    navigate(PATHS.myContent, { state: { isDataPersist: false } });
  };

  useEffect(() => {
    if (!isDataPersist) {
      getTaxonomyByTitle(TaxonomyTitle.Industry);
      getTaxonomyByTitle(TaxonomyTitle.Function);
      getTaxonomyByTitle(TaxonomyTitle.AssetType);
      getAllContent(contentFilter, isProfile);
    } else {
      resetIsDataPersistRoute();
    }

    return () => {
      dispatch(setContentFilesInContext([]));
      dispatch(setContentSelectedIds([]));
    };
  }, [contentFilter?.fileLevel]);

  useEffect(() => {
    if (!isDataPersist) {
      getAssetCount();
    }
  }, []);

  const getIsShowStopBtn = (content: IContent) =>
    [
      String(AssetUploadStatus.UPLOADED_TO_DO),
      AssetUploadStatus.PROCESSING,
      AssetUploadStatus.SENT_TO_LLM,
      AssetUploadStatus.CONVERTED_TO_PDF,
    ].includes(content?.assetStatus);

  const getStopBtnLabel = (content: IContent) => {
    let label = "";

    if (
      [
        String(AssetUploadStatus.UPLOADED_TO_DO),
        AssetUploadStatus.PROCESSING,
        AssetUploadStatus.SENT_TO_LLM,
        AssetUploadStatus.CONVERTED_TO_PDF,
      ].includes(content?.assetStatus)
    ) {
      label = "Stop";
    } else if (AssetUploadStatus.STOPPING === content?.assetStatus) {
      label = "Stopping";
    }

    return label;
  };

  const getIsDisableStopBtn = (content: IContent) =>
    AssetUploadStatus.STOPPING === content?.assetStatus;

  const handleViewEditContentNavigate = (
    assetId: number,
    isEditMode: boolean,
    assetStatus: string | undefined
  ) => {
    if (assetStatus === AssetUploadStatus.COMPLETED) {
      dispatch(setContentById(contentByIdInitialValue));
      dispatch(setSelectedAllTagsByAssestId(tagsByAssestIdInitValue));
      dispatch(
        setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
      );
      updateViewCount(assetId);

      const content = contents.find((e) => e.asset_id === assetId);
      navigate(`${PATHS.viewEditContentManagement}/${assetId}`, {
        state: {
          isEditMode,
          isReadOnly: content ? getIsContentFailed(content) : false,
          fromWhere: FromWhere.MyContent,
        },
      });
    }
  };

  const handleContentDetail = (content: IContent) => {
    if (getIsDisableEditContent(content)) {
      return () => {};
    }
    return handleViewEditContentNavigate(content?.asset_id, false, content?.assetStatus);
  };

  const handlePublishedValidation = (content: IContent) => {
    let flag = false;
    let messageList = [];
    const assetType = content?.linkedTags?.linkedTaxonomyNodes?.["Asset Type"] || [];

    if (getIsAssetProcessing(content?.assetStatus)) {
      toast.error("Unable to publish as document is still under AI processing");
      return true;
    }

    if (!content?.title || content?.title.trim() === "") {
      messageList.push("Title");
      flag = true;
    }
    if (!content?.summary || content?.summary.trim() === "") {
      messageList.push("Summary");
      flag = true;
    }
    if (!assetType.length) {
      messageList.push("Asset type");
      flag = true;
    }

    if (messageList && messageList.length) {
      let message = "";
      messageList.forEach((item, index) => {
        message =
          message +
          `${
            index === 0 ? "" : index > 0 && index === messageList.length - 1 ? " and " : ", "
          }${item}`;
      });

      if (message) {
        message = message + ` ${messageList.length > 1 ? "inputs" : "input"} cannot be empty`;
        toast.error(message);
      }
    }

    return flag;
  };

  const renderAssetStatus = (isPublished: boolean | string, content: IContent, index: number) => {
    return (
      <Switch
        value={["TRUE", true].includes(isPublished) ? true : false}
        disabled={getIsContentFailed(content)}
        onChange={() => {
          if (handlePublishedValidation(content)) return;

          const isPublishedValue = ["TRUE", true].includes(isPublished) ? true : false;
          if (!isPublishedValue) {
            (async () => {
              const res = await enablePublish(content?.asset_id);
              if (res?.data) {
                dispatch(setUpdatePublishDraft(index));
              }
            })();
          } else {
            (async () => {
              const res = await disablePublish(content?.asset_id);
              if (res?.data) {
                dispatch(setUpdatePublishDraft(index));
              }
            })();
          }
        }}
      />
    );
  };

  const handleRetry = async (content: IContent, index: number) => {
    if (content?.asset_id) {
      const res = await retryProcess(content?.asset_id);

      if (res?.data) {
        const contentsValues = [...contents];
        contentsValues[index] = {
          ...content,
          assetStatus: res?.data?.assetStatus || "",
        };
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const handleUpdateFileLevel = async (content: IContent, index: number) => {
    if (content?.asset_id) {
      const res = await updateAssetFileLevel(content?.asset_id);

      if (res?.data) {
        const contentsValues = [...contents];
        contentsValues[index] = {
          ...content,
          file_level: FileLocation.ORGANISATION,
        };
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const handleStopProcessing = async (content: IContent | null, index: number) => {
    if (content?.asset_id) {
      const res = await stopProcessing(content?.asset_id);

      if (res?.data) {
        const updatedContent = {
          ...content,
          assetStatus: AssetUploadStatus.STOPPING,
        };
        const contentsValues = [...contents];
        contentsValues[index] = updatedContent;
        dispatch(setAllContentDetails(contentsValues));
      }
    }
  };

  const getItems = (content: IContent, index: number) => {
    let items: MenuProps["items"] = [];

    checkPlatformPermission(Permissions.EDIT_DOC) &&
      items.push({
        key: 1,
        label: (
          <div
            onClick={() => {
              handleViewEditContentNavigate(
                selectedContent?.asset_id || 0,
                true,
                selectedContent?.assetStatus
              );
            }}>
            Edit
          </div>
        ),
        className:
          getIsDisableEditContent(content) || !assetEditPermission(content)
            ? "run-vision-disable"
            : "",
      });

    checkPlatformPermission(Permissions.DELETE_DOC) &&
      items.push({
        key: 2,
        label: (
          <div
            onClick={() => {
              if (getIsShowStopBtn(content)) {
                handleStopProcessing(selectedContent, index);
              } else {
                setIsShowAssetDeleteModal(true);
              }
            }}
            className="taxonomyDeleteText">
            {getStopBtnLabel(content) ? getStopBtnLabel(content) : "Delete"}
          </div>
        ),
        className:
          getIsDisableStopBtn(content) || !assetEditPermission(content) ? "run-vision-disable" : "",
      });

    if (
      [String(AssetUploadStatus.FAILED), AssetUploadStatus.FAILED_IN_LLM].includes(
        content?.assetStatus
      ) &&
      viewType === ViewType.GRID
    ) {
      items.push({
        key: 3,
        label: (
          <div onClick={() => handleRetry(content, index)} className="taxonomyDeleteText">
            Retry
          </div>
        ),
        className: isRetryLoading ? "run-vision-disable" : "",
      });
    }

    if (
      content?.file_level === FileLocation.USER &&
      content?.assetStatus === AssetUploadStatus.COMPLETED &&
      (content?.isPublished === true || content?.isPublished === "TRUE") &&
      assetEditPermission(content)
    ) {
      items.push({
        key: 4,
        label: (
          <div className="taxonomyDeleteText" onClick={() => handleUpdateFileLevel(content, index)}>
            Move to Sources
          </div>
        ),
        className: isFileLevelUpdating ? "run-vision-disable" : "",
      });
    }

    return items;
  };

  const handleBookmark = async (content: IContent, index: number) => {
    const res = await bookmarkContent(content?.asset_id, content?.is_bookmarked ? false : true);
    if (res) {
      const updatedContent = {
        ...content,
        is_bookmarked: !content?.is_bookmarked,
      };
      const contentsValues = [...contents];
      contentsValues[index] = updatedContent;
      dispatch(setAllContentDetails(contentsValues));
      setSelectedFiles(contentsValues);
    }
  };

  const chatOption = (content: IContent) => {
    return !getIsDisableEditContent(content) ? (
      <Tooltip
        placement="topRight"
        trigger="hover"
        title="Chat with this document"
        rootClassName="start-chat-tooltip">
        <ContentChatButton {...{ content, isVisible: true }} fromWhere={FromWhere.MyContent} />
      </Tooltip>
    ) : (
      <ContentChatButton
        {...{ localFilters, isVisible: true, isDisabled: true }}
        fromWhere={FromWhere.MyContent}
      />
    );
  };

  const threeDotsMenu = (content: IContent, index: number) => {
    return (
      <Dropdown
        trigger={["click"]}
        menu={{
          onClick: () => {
            setSelectedContent(content);
          },
          items: getItems(content, index),
        }}
        open={content.asset_id === selectedContent?.asset_id && isDropdownOpen}
        onOpenChange={(open) => setIsDropdownOpen(open)}
        overlayClassName="table-action-dropdown content-dropdown"
        placement="bottomRight">
        <Button className="custom-icon more-action" onClick={() => setSelectedContent(content)}>
          <i className="ri-more-2-fill"></i>
        </Button>
      </Dropdown>
    );
  };

  const fileExtraOptions = (content: IContent, index: number) => {
    return threeDotsMenu(content, index);
  };

  const fileExtraOptionsTwo = (content: IContent, index: number) => {
    return (
      <>
        {chatOption(content)}
        <div onClick={() => handleBookmark(content, index)}>
          <BookmarkIcon
            disabled={isContentTableLoading || getIsDisableEditContent(content)}
            isActive={content?.is_bookmarked}
          />
        </div>
      </>
    );
  };

  const renderOwnerUI = (data: IContent) => {
    return (
      <div className="author-info">
        <div className="profile-pic">
          {/* if user pic is true */}
          {/* <img src={IMAGES.avatarDefault} alt="profile-pic" className="profile-pic" /> */}

          {/* else */}
          <div className="user-icon">
            <i className="ri-user-fill"></i>
          </div>
        </div>
        <span className="author-name">{getCardOwnersFullName(data?.user)}</span>
      </div>
    );
  };

  const tableColumns: any = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "250px",
      className: "title-table-col",
      render: (text: string, content: IContent) => (
        <>
          <span className="td-label">Title : </span>
          <Popover
            placement="bottomLeft"
            rootClassName="custom-description-root"
            content={
              <AssetSummaryPopover content={content} tags={false} fromWhere={FromWhere.MyContent} />
            }
            trigger="hover">
            <strong
              className={`title-col  d-flex d-flex-middle ${
                content?.assetStatus === AssetUploadStatus.COMPLETED
                  ? " cursor-pointer"
                  : " cursor-default"
              } ${content?.file_level === FileLocation.USER ? " padding-right" : ""}`}
              onClick={() => handlePDFClick(content)}>
              {/* <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" /> */}
              <div
                className="file-bg"
                style={{ backgroundImage: `url(${content?.thumbnail_pre_signed_url})` }}></div>
              {text ? text : content?.original_file_name}
              {content?.file_level === FileLocation.USER && (
                <span className="table-tag">{getCardOwnersDetail(content?.user)}</span>
              )}
            </strong>
          </Popover>
        </>
      ),
    },
    {
      title: "Type",
      key: "docType",
      width: "110px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Type : </span>
          <span className="doc-type">
            <div className="profile-pic">
              <img src={renderFileTypeIcon(content?.file_type)} alt="Icon" />
            </div>
            {content?.file_type}
          </span>
        </>
      ),
    },
    {
      title: "Source Type",
      key: "src_type",
      width: "110px",
      render: (content: IContent) => (
        <>
          <span className="td-label">Type : </span>
          <span className="doc-type">{renderSourceType(content?.src_type)}</span>
        </>
      ),
    },
    {
      title: isProfile ? "Uploader" : "Owner",
      key: "owner",
      width: "150px",
      render: (content: IContent) => {
        return (
          <>
            <span className="td-label">Owner : </span>
            {renderOwnerUI(content)}
          </>
        );
      },
    },
    {
      title: "Last Updated",
      dataIndex: "modifiedOn",
      key: "modifiedOn",
      width: "120px",
      render: (date: string) => (
        <>
          <span className="td-label">Last Updated : </span>
          {getFormattedDate(date, "type1")}
        </>
      ),
    },
    {
      title: "Tags",
      dataIndex: "linkedTags",
      key: "linkedTags",
      width: "200px",
      hidden: true,
      render: (linkedTags: ITagsByAssestId, content: IContent) => (
        <AssetTags {...{ linkedTags, content }} fromWhere={FromWhere.MyContent} />
      ),
    },
    {
      title: "Published",
      dataIndex: "isPublished",
      key: "isPublished",
      width: "150px",
      render: (isPublished: boolean | string, content: IContent, index: number) => (
        <>
          <span className="td-label">Published : </span>
          <span className="published-draft-label d-flex d-flex-middle d-flex-between">
            {renderAssetStatus(isPublished, content, index)}
          </span>
        </>
      ),
    },
    {
      title: "AI Status",
      key: "isPublished",
      width: "130px",
      render: (_: any, content: IContent, index: number) => (
        <>
          <span className="td-label">AI Status : </span>
          <span className="published-draft-label d-flex d-flex-middle">
            <AssetStatus content={content} isShowAllStatus={true} />
            {(content?.assetStatus === AssetUploadStatus.FAILED ||
              content?.assetStatus === AssetUploadStatus.FAILED_IN_LLM) && (
              <Button
                className="table-retry"
                type="text"
                onClick={() => handleRetry(content, index)}>
                <i className={`ci ci-retry-icon ${isRetryLoading ? "round-animate" : ""}`} />
              </Button>
            )}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "110px",
      fixed: "right",
      className: "fix-action",
      render: (_: any, content: IContent, index: number) => {
        return (
          <>
            <span className="td-label">Action : </span>
            <div className="extra-options">
              <div className="file-extra-options">
                {fileExtraOptionsTwo(content, index)}
                {threeDotsMenu(content, index)}
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const tabDataGrid = () => {
    return (
      <Row className="tab-row-inner">
        {isContentTableLoading ? (
          <FileCardSkeleton />
        ) : !isEmpty(contents) ? (
          contents.map((item: IContent, index: number) => (
            <FileCard
              key={index}
              data={item}
              type={FileCardType.UPLOADED}
              isPopOver={item.asset_id === selectedContent?.asset_id ? false : true}
              extraOptions={fileExtraOptions(item, index)}
              extraOptionsTwo={fileExtraOptionsTwo(item, index)}
              onClick={() => handlePDFClick(item)}
              fromWhere={FromWhere.MyContent}
            />
          ))
        ) : (
          <NoContentData />
        )}
      </Row>
    );
  };

  const getEditableContents = () => {
    let editableContents: number[] = [];
    if (contents && contents.length) {
      editableContents = contents
        .filter((item) => !getIsDisableEditContent(item))
        .map((item) => item?.asset_id);
    }
    return editableContents || [];
  };

  const getSelectedContents = () => {
    let selectedContents: number[] = [];
    if (contents && contents.length) {
      selectedContents = contents
        .filter((item) => !getIsDisableEditContent(item) && item?.isSelected)
        .map((item) => item?.asset_id);
    }
    const selectedIds = selectedContents || [];
    return selectedIds;
  };

  const rowSelection = {
    selectedRowKeys: getSelectedContents(),
    onChange: (selectedRowKeys: any[]) => {
      let updatedContent: IContent[] = [];
      if (contents && contents?.length) {
        contents.forEach((item) => {
          if (selectedRowKeys.includes(item?.asset_id)) {
            updatedContent.push({ ...item, isSelected: true });
          } else {
            updatedContent.push({ ...item, isSelected: false });
          }
        });
      }
      dispatch(setSelectionAppliedForContent(false));
      dispatch(setSelectContentDetails(updatedContent));
    },
    getCheckboxProps: (record: IContent) => ({
      disabled: getIsDisableEditContent(record),
    }),
  };

  const handlePagination = (page: number) => {
    dispatch(setUpdateContentFilter({ field: contentFilterField?.currentPage, value: page }));
    getAllContent({ ...contentFilter, currentPage: page }, isProfile);
  };

  const tabDataList = () => {
    return (
      <Row className="tab-row-inner list-view">
        <Col span={24}>
          <Card className="global-table-card">
            {isContentTableLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                className="global-table responsive-table"
                rowKey="asset_id"
                columns={tableColumns}
                dataSource={contents?.length ? contents : []}
                locale={{ emptyText: <NoContentData /> }}
                tableLayout="fixed"
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                scroll={{ y: "calc(100vh - 330px)", x: "100" }}
                pagination={false}
              />
            )}
          </Card>
        </Col>
      </Row>
    );
  };

  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "Uploaded",
      children: viewType === ViewType.GRID ? tabDataGrid() : tabDataList(),
      icon: <span className="count">{contents?.length ? `(${contents?.length})` : ""}</span>,
    },
  ];

  const handleDeleteContent = async () => {
    if (selectedContent?.asset_id) {
      const res: any = await deleteAssetById(
        selectedContent?.asset_id,
        totalContentsCount,
        false,
        contentFilter,
        "content"
      );

      if (res?.data) {
        let contentsValues = [...contents];
        contentsValues = !isEmpty(contentsValues)
          ? contentsValues?.filter((item) => item.asset_id !== selectedContent?.asset_id)
          : [];
        dispatch(setAllContentDetails(contentsValues));

        const filteredContext = contentFilesInContext.filter(
          (c) => selectedContent?.asset_id !== c.asset_id
        );
        dispatch(setSelectedFilesForAssetFilter(filteredContext));
      }
    }

    setIsShowAssetDeleteModal(false);
    setSelectedContent(null);
    setIsShowAssetDeleteModal(false);
    dispatch(
      setUpdateContentFilter({ field: contentFilterField?.currentPage, value: currentPage })
    );
  };

  const handleSelectAllContents = (e: any) => {
    if (contents && contents.length) {
      const filteredContents = [...contents].map((item) => ({
        ...item,
        isSelected: getIsDisableEditContent(item) ? false : e?.target?.checked,
      }));
      dispatch(setSelectionAppliedForContent(false));
      dispatch(setSelectContentDetails(filteredContents));
    }
  };

  const handleFileLevelSwitch = (checked: boolean) => {
    if (checked) {
      dispatch(
        setUpdateContentFilter({ field: contentFilterField?.contentType, value: ["personal"] })
      );
      dispatch(
        setUpdateContentFilter({ field: contentFilterField?.fileLevel, value: FileLocation.USER })
      );
    } else {
      dispatch(setUpdateContentFilter({ field: contentFilterField?.contentType, value: [] }));
      dispatch(
        setUpdateContentFilter({ field: contentFilterField?.fileLevel, value: FileLocation.ALL })
      );
    }
  };

  const tabRightContent: TabsProps["tabBarExtraContent"] = (
    <>
      <span className="file-level-filter">
        <Switch
          checked={localFilters?.fileLevel === FileLocation.USER}
          size="small"
          onChange={handleFileLevelSwitch}
          disabled={isContentTableLoading}
        />
        <span className="label">{FileLocationLabel.USER}</span>
      </span>
      <span className="selected-count">
        <Checkbox
          className="select-label just-show"
          disabled={isEmpty(contents)}
          indeterminate={
            getSelectedContents().length &&
            getSelectedContents().length < getEditableContents().length
              ? true
              : false
          }
          checked={
            contents.length > 0 && getSelectedContents().length === getEditableContents().length
          }
          onChange={handleSelectAllContents}>
          {getSelectedContents().length ? (
            `${getSelectedContents().length} Out of ${contents?.length} selected`
          ) : (
            <>
              Select All <span>(Showing {contents?.length} results)</span>
            </>
          )}
        </Checkbox>
      </span>
      <ContentChatButton
        {...{
          localFilters,
          selectedContents: !isEmpty(contents)
            ? contents.filter((content) => content.isSelected)
            : [],
          fromWhere: FromWhere.MyContent,
        }}
      />

      {checkPlatformPermission(Permissions.DELETE_DOC) && (
        <ContentActionButton isShowChatIcon={false} />
      )}
    </>
  );

  const handleBookmarkFilter = async (checked: boolean) => {
    if (typeof checked === "boolean") {
      dispatch(
        setUpdateContentFilter({
          field: contentFilterField?.isBookmarked,
          value: checked,
        })
      );
      const res = await getAllContent(
        {
          ...contentFilter,
          isBookmarked: checked,
        },
        isProfile
      );
      setSelectedFiles(res);
    }
  };

  useEffect(() => {
    localFilters?.isFilterVisible
      ? document.body.classList.add("content-filter-visible")
      : document.body.classList.remove("content-filter-visible");
  }, [localFilters]);

  //reload contents for selected filter comes from the conversation
  useEffect(() => {
    let routeState = location.state;
    if (
      !isEmpty(routeState) &&
      routeState?.isReloadData &&
      !isEmpty(localFilters) &&
      JSON.stringify(contentFilter) === JSON.stringify(localFilters)
    ) {
      handleApplyFilter(localFilters);
      navigate("", { state: { isReloadData: false } });
    }
  }, [location, localFilters]);

  const toggleFilter = () => {
    dispatch(
      setUpdateAllContentFilter({
        ...localFilters,
        isFilterVisible: !localFilters?.isFilterVisible,
      })
    );
  };

  const handleResetFilter = async (isToastShow = true) => {
    dispatch(clearContentFilterWithoutBookmark());

    await getAllContent(
      {
        ...contentFilterInitValue,
        isBookmarked: contentFilter?.isBookmarked,
      },
      isProfile
    );

    isToastShow && toast.success("Filter successfully reset");
  };

  const handleApplyFilter = async (localFilters: IContentFilter) => {
    const res = await getAllContent(
      {
        ...localFilters,
        isBookmarked: contentFilter?.isBookmarked,
      },
      isProfile
    );
    if (res) {
      dispatch(setUpdateAllContentFilter(localFilters));
      dispatch(
        setUpdateContentFilter({
          field: contentFilterField?.isApplied,
          value: true,
        })
      );
      dispatch(
        setUpdateContentFilter({
          field: contentFilterField?.isFilterChanged,
          value: false,
        })
      );
      setSelectedContent(res);
      toast.success("Filter successfully applied");
    }
  };

  const handlePDFClick = (item: IContent) => {
    if (getIsAssetProcessing(item?.assetStatus)) {
      toast.error("Asset is under processing");
      return;
    } else if (["xls", "xlsx"].includes(item?.file_type || "")) {
      if (item?.assetStatus === AssetUploadStatus.COMPLETED) {
        handleViewEditContentNavigate(item?.asset_id, false, item?.assetStatus);
      } else {
        toast.error("Excel file preview not supported");
      }
      return;
    }

    handleOpenDocClick(item);
  };

  const handlePDFClose = () => {
    dispatch(setIsPDFModalOpen(false));
  };

  const isShowPagination = () => {
    return (
      contents && contents.length && (totalContentsCount || 1) / PageLimit.ContentManagement > 1
    );
  };

  return (
    <Flex
      className={`handle-layout inner-app-wrap my-content-page ${
        !isShowPagination() ? "no-pagination" : ""
      }`}
      vertical>
      <PageHeader
        title={isProfile ? "My Uploads" : "My Assets"}
        isRelevantAsset={false}
        content={
          <>
            <div className="bookmark-filter">
              <span className="text">Bookmarked</span>
              <Switch
                size="small"
                checked={contentFilter?.isBookmarked ? true : false}
                onChange={handleBookmarkFilter}
              />
            </div>
            {!isProfile && (
              <Button
                type="text"
                className="filter-toggle-btn"
                onClick={toggleFilter}
                icon={<i className="ri-equalizer-fill"></i>}>
                Filters
              </Button>
            )}

            {checkPlatformPermission(Permissions.UPLOAD_DOC) && (
              <Button
                className="btn upload-btn"
                type="primary"
                onClick={() => setIsOpenUploadAsset(true)}>
                Upload
              </Button>
            )}

            <div className="view-change">
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.GRID ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.GRID))}>
                <i className="ri-layout-grid-fill"></i>
              </Button>
              <Button
                type="text"
                className={`view-type ${viewType === ViewType.LIST ? "active" : ""}`}
                onClick={() => dispatch(setViewType(ViewType.LIST))}>
                <i className="ri-list-unordered"></i>
              </Button>
            </div>
          </>
        }
      />

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.25 }}>
        <StatsCard />
      </motion.div>

      {localFilters?.isFilterVisible && (
        <motion.div
          className="content-source-filter"
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}>
          <ContentSourceFilter
            isShowContentType={false}
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
            handleApplyFilter={handleApplyFilter}
            handleResetFilter={handleResetFilter}
            fromWhere={FromWhere.MyContent}
            isFilterSelected={!isMyContentFilterEmpty(localFilters)}
          />
        </motion.div>
      )}

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.5 }}>
        <Row className="content-tab">
          <Col span={24}>
            <Card className="content-tab-card">
              <Tabs tabBarExtraContent={tabRightContent} items={tabItems} />
              {isShowPagination() ? (
                <CustomPagination
                  currentPage={currentPage}
                  total={totalContentsCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.ContentManagement}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </motion.div>

      {isOpenUploadAsset && (
        <UploadAssets
          isModalOpen={isOpenUploadAsset}
          setIsModalOpen={setIsOpenUploadAsset}
          fromWhere={FromWhere.MyContent}
        />
      )}

      {/* Image Modal */}
      <ImageViewer handleDetail={() => handleContentDetail(pdfContent)} />

      {/* PDF Modal */}
      {!isEmpty(pdfContent) && isPDFModalOpen && (
        <Modal
          className="pdf-modal detail-open"
          centered={true}
          onCancel={handlePDFClose}
          footer={false}
          open={!isEmpty(pdfContent) && isPDFModalOpen}
          style={{ top: "20px", right: "20px", position: "absolute", marginRight: "20px" }}>
          <PDFViewer
            isRelevantAsset={true}
            isChatButton={true}
            content={pdfContent}
            btnClick={() => handleContentDetail(pdfContent)}
          />
        </Modal>
      )}

      {/* Single content delete */}
      {isShowAssetDeleteModal && (
        <DeleteModal
          isLoading={contentDeleteLoading}
          isModalOpen={isShowAssetDeleteModal}
          handleCancel={() => setIsShowAssetDeleteModal(false)}
          handleSubmit={handleDeleteContent}
          message="Are you sure you want to delete this asset?"
          title="Delete Asset"
        />
      )}
    </Flex>
  );
};

export default MyContent;
