import { Button, Card, Form, Input, Modal, Select } from "antd";

interface IModal {
  isModalOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  handleLogout: VoidFunction;
  titleBtn?: string;
}

const AddEditModelConfiguration = ({
  isModalOpen,
  title,
  onCancel,
  titleBtn = "Create",
}: IModal) => {
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      title={title}
      afterClose={() => form.resetFields()}
      width={500}
      footer={
        <>
          <Button
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            className="btn"
            size={"middle"}>
            Cancel
          </Button>
          <Button
            // loading={isLoading}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn"
            type="primary"
            size={"middle"}>
            {titleBtn}
          </Button>
        </>
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addUser"
          initialValues={{
            name: "",
            email: "",
            password: "",
            roleName: null,
            industry: [],
            functions: [],
          }}
          // onFinish={onFinish}
          requiredMark={false}
          size="large"
          className="form-container add-role-form">
          <div className="container2">
            <div className="form-inner">
              <Card className="input-card">
                <Form.Item
                  label="Select Provider*"
                  name="name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Provider",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]*$/),
                      message: "Name field only accept alphabets",
                    },
                  ]}>
                  <Select
                    defaultValue=" "
                    placeholder="Select Provider"
                    style={{ width: "100%" }}
                    popupClassName="ui-dropdown-default"
                    options={[
                      { value: "jack", label: "Jack" },
                      { value: "lucy", label: "Lucy" },
                      { value: "Yiminghe", label: "yiminghe" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Select Model*"
                  name="selectModel"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please Select Model",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]*$/),
                      message: "Name field only accept alphabets",
                    },
                  ]}>
                  <Select
                    defaultValue=" "
                    placeholder="Select Model"
                    style={{ width: "100%" }}
                    popupClassName="ui-dropdown-default"
                    options={[
                      { value: "option1", label: "Option1" },
                      { value: "option2", label: "Option2" },
                      { value: "option3", label: "Option3" },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  label="Enter Model Name *"
                  name="name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Model Name",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]*$/),
                      message: "Name field only accept alphabets",
                    },
                  ]}>
                  <Input placeholder="Enter Model Name" autoComplete="off" />
                </Form.Item>
                <Form.Item
                  label="Enter API Key *"
                  name="APIKey"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter API Key",
                    },
                    {
                      pattern: new RegExp(/^[a-zA-Z\s]*$/),
                      message: "Name field only accept alphabets",
                    },
                  ]}>
                  <Input placeholder="Enter API Key" autoComplete="off" />
                </Form.Item>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditModelConfiguration;
