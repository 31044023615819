import { FC, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import { Space } from "antd";

import AssetTagsGroupModal from "./assetTagsGroup/AssetTagsGroupModal";
import { ITaxonomyTreeList } from "../../../store/taxonomy/taxonomy.interface";
import { IFormattedIndustry } from "../../../store/contentManagement/content.interface";
import {
  findParentNodesByValueForView,
  findNodeNameByValueForAccessGroupTaxonomy,
} from "../../../shared/taxonomyHelper";

interface IAccessGroupTaxonomies {
  id: number;
  title: string;
  selectedIds: number[];
  linkedTags: ITaxonomyTreeList[];
}

const AccessGroupTaxonomies: FC<IAccessGroupTaxonomies> = (props: IAccessGroupTaxonomies) => {
  const { linkedTags, selectedIds, id, title } = props;
  const [isModalOpen, setIsModalOpen] = useState(-1);

  const ids: number[] = [...selectedIds];
  const formattedTags: IFormattedIndustry[] = [];

  if (ids && ids.length) {
    ids.forEach((value: number) => {
      const strValue = String(value);
      formattedTags.push({
        parents: findParentNodesByValueForView(value, linkedTags),
        value: strValue,
        id: strValue,
        title: findNodeNameByValueForAccessGroupTaxonomy(linkedTags, value),
      });
    });
  }

  const renderMore = () => {
    const pointerClass = "cursor-pointer";
    return (
      <span className={`more-tag ${pointerClass}`} onClick={() => setIsModalOpen(id)}>
        +more
      </span>
    );
  };

  const handleCloseTagModal = () => {
    setIsModalOpen(-1);
  };

  return (
    <>
      {formattedTags && formattedTags.length ? (
        formattedTags.slice(0, 1).map((industry: IFormattedIndustry, industryIdx: number) => {
          if (industry.parents && industry.parents.length) {
            if (industry.parents.length === 1) {
              return (
                <div className="item-tags item-tags-ui" key={industryIdx}>
                  <Space size={[0, 8]} className="tags-span" align="center">
                    {industry.parents.slice(0, 1).map((indParent: any, indParentIdx: number) => {
                      return (
                        <>
                          <p key={indParentIdx} className="tags-p">
                            {indParent.taxonomyNodeName} {indParentIdx === 0 && <RightOutlined />}
                          </p>
                          <p key={indParentIdx} className="tags-p">
                            {industry.title}
                          </p>
                        </>
                      );
                    })}
                    {renderMore()}
                  </Space>
                </div>
              );
            } else {
              return (
                <div className="item-tags item-tags-ui" key={industryIdx}>
                  <Space size={[0, 8]} wrap className="tags-span" key={industryIdx}>
                    {industry.parents.slice(0, 2).map((indParent: any, indParentIdx: number) => {
                      return (
                        <div className="text-icon-right d-flex d-flex-middle">
                          <p key={indParentIdx} className="tags-p">
                            {indParent.taxonomyNodeName}
                          </p>
                          {indParentIdx === 0 && <RightOutlined />}
                        </div>
                      );
                    })}
                    {renderMore()}
                  </Space>
                </div>
              );
            }
          } else {
            return (
              <div className="item-tags item-tags-ui" key={industryIdx}>
                <Space size={[0, 8]} wrap className="tags-span">
                  {industry.title}
                  {renderMore()}
                </Space>
              </div>
            );
          }
        })
      ) : (
        <div className="item-tags item-tags-ui">
          <Space size={[0, 8]} wrap className="tags-span">
            N/A
          </Space>
        </div>
      )}

      <AssetTagsGroupModal
        isOpen={id === isModalOpen}
        formattedTags={formattedTags}
        contentTitle={title}
        setModalClose={handleCloseTagModal}
      />
    </>
  );
};

export default AccessGroupTaxonomies;
