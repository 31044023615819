import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Card, Form, Input, Modal } from "antd";
import EditContentTags from "../../viewEditContentManagement/EditContentTags/EditContentTags";
import { RootState } from "../../../store";
import { IaccessGroups, IAccessGroupsForm } from "../../../store/accessGroup/accessGroup.interface";
import { IMappedIndustryChildren } from "../../../store/contentManagement/content.interface";
import { resetAccessGroupFilters } from "../../../store/accessGroup/accessGroupSlice";
import { addContentAccessGroup, updateContentAccessGroup } from "../../../services/accessGroup";
import { convertNumberArrayToStringArray, isEmpty } from "../../../shared";

interface IAddEditAccessForm {
  isModalOpen: boolean;
  setIsModalOpen: (v: boolean) => void;
  onCancel: VoidFunction;
  title: string;
  selectedAccessGroup: IaccessGroups | null;
  titleBtn?: string;
  mappedTaxonomyData: IMappedIndustryChildren[];
}

const AddEditAccessForm = ({
  isModalOpen,
  setIsModalOpen,
  title,
  selectedAccessGroup = null,
  onCancel,
  titleBtn = "Create",
  mappedTaxonomyData,
}: IAddEditAccessForm) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { isAccessGroupUpdating, accessGroupPage, accessGroupSearch } = useSelector(
    (state: RootState) => state.accessGroup
  );
  const [linkedNodes, setLinkedNodes] = useState<number[]>([]);

  const handleSetFormData = () => {
    if (!isEmpty(selectedAccessGroup)) {
      const taxonomyNodeIds = selectedAccessGroup?.taxonomyNodeIds || [];
      form.setFieldsValue({
        name: selectedAccessGroup?.name,
        taxonomyNodeIds: taxonomyNodeIds,
      });
      setLinkedNodes(taxonomyNodeIds);
    }
  };

  useEffect(() => {
    handleSetFormData();
  }, [selectedAccessGroup]);

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleChangeTaxonomies = (selectedNodes: any[]) => {
    let ids: number[] = [];
    if (!isEmpty(selectedNodes)) {
      selectedNodes.forEach((node) => {
        ids.push(parseFloat(node));
        // ids.push(parseFloat(node?.value));
      });
    }
    setLinkedNodes(ids);
  };

  const handleFinish = async (values: IAccessGroupsForm) => {
    const request = {
      name: values?.name,
      taxonomyNodeIds: linkedNodes,
    };

    if (isEmpty(selectedAccessGroup)) {
      const res = await addContentAccessGroup(request, "", 1);
      if (res?.status === 200) {
        dispatch(resetAccessGroupFilters());
      }
    } else {
      await updateContentAccessGroup(
        { ...request, id: selectedAccessGroup?.id },
        accessGroupSearch,
        accessGroupPage
      );
    }
    setIsModalOpen(false);
  };

  return (
    <Modal
      centered
      title={title}
      afterClose={() => form.resetFields()}
      width={500}
      footer={
        <>
          <Button onClick={handleCancel} className="btn" size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={isAccessGroupUpdating}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn"
            type="primary"
            size={"middle"}>
            {titleBtn}
          </Button>
        </>
      }
      onCancel={handleCancel}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addEditAccessGroup"
          initialValues={{
            name: "",
            taxonomyNodeIds: [],
          }}
          onFinish={handleFinish}
          requiredMark={false}
          size="large"
          className="form-container add-role-form">
          <div className="container2">
            <div className="form-inner">
              <Card className="input-card">
                <Form.Item
                  label="Name"
                  name="name"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter Group Name",
                    },
                  ]}>
                  <Input placeholder="Enter Group Name" autoComplete="off" />
                </Form.Item>

                <Form.Item
                  label="Select Taxonomies"
                  rules={[{ required: isEmpty(linkedNodes), message: "Please select Taxonomies" }]}
                  name="taxonomyNodeIds"
                  labelCol={{ span: 24 }}>
                  <div className="hide-tree-search">
                    <EditContentTags
                      maxTagCountnumber={3}
                      selectedTags={convertNumberArrayToStringArray(linkedNodes)}
                      mappedContentTags={mappedTaxonomyData}
                      popupClassName="ui-choose-select"
                      placeholder="Select Taxonomies"
                      handleChange={handleChangeTaxonomies}
                      className="select-dropdown-multiple"
                      isNonLeafNodeDisable={false}
                      treeCheckStrictly={false}
                    />
                  </div>
                </Form.Item>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default AddEditAccessForm;
