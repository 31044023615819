import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Button, Card, Col, Dropdown, Flex, Input, MenuProps, Row, Switch, Table } from "antd";
import { AddEditUserManagement, PageHeader, TableSkeleton, DeleteModal } from "../../../components";
import AccessGroupModal from "../../../components/AddEditUserManagement/AccessGroupModal";
import { RootState } from "../../../store";
import { IUser } from "../../../store/userManagement/user.interface";
import {
  setAllUsersDetails,
  setSearchText,
  setUserCurrPage,
} from "../../../store/userManagement/usersSlice";
import { deleteUser, fetchAllUsers, updateUserStatus } from "../../../services/userManagement";
import { fetchContentAccessGroup } from "../../../services/accessGroup";
import { fetchRolesList } from "../../../services/roleManagement";
import {
  useDebounce,
  PageLimit,
  CustomPagination,
  getLocalStorageItem,
  formatRelativeTime,
  isEmpty,
  Limit,
  checkPlatformPermission,
  Permissions,
} from "../../../shared";
import "./Usermanagement.scss";

const Usermanagement = () => {
  const dispatch = useDispatch();
  const {
    allUsers,
    userTableLoading,
    userDeleteLoading,
    totalUsersCount,
    userCurrPage,
    searchText,
  } = useSelector((state: RootState) => state.user);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState<boolean>(false);
  const [accessGroupModalKey, setAccessGroupModalKey] = useState<number>(-1);

  const localStorageItem = getLocalStorageItem("user_foundationKM");
  const details: string | undefined = localStorageItem !== null ? localStorageItem : undefined;
  const userDetails = details ? JSON.parse(details) : {};

  useEffect(() => {
    fetchAllUsers(searchText, userCurrPage);
  }, [userCurrPage]);

  useEffect(() => {
    fetchContentAccessGroup(false, "", 1, false);
    fetchRolesList("", 1, false);
  }, []);

  const handleUpdateStatus = async (value: boolean, userDetials: IUser, selectedIndex: number) => {
    const res = await updateUserStatus(value, userDetials?.id);

    if (res?.status === 200) {
      const values: IUser[] = [...allUsers];
      values[selectedIndex] = {
        ...userDetials,
        status: value ? "A" : "D",
      };
      dispatch(setAllUsersDetails(values));
    }
  };

  const handleCloseAccessGroupModal = () => {
    setAccessGroupModalKey(-1);
    setSelectedUser(null);
  };

  const getMenuItems = () => {
    const items: MenuProps["items"] = [];

    if (checkPlatformPermission(Permissions.EDIT_USER)) {
      items.push({
        key: "1",
        label: (
          <div
            onClick={() => {
              setIsModalOpen(true);
            }}>
            Edit
          </div>
        ),
      });
    }

    if (checkPlatformPermission(Permissions.DELETE_USER)) {
      items.push({
        key: "2",
        label: (
          <div
            onClick={() => {
              setIsShowDeleteUserModal(true);
            }}
            className="UserDeleteText">
            Delete
          </div>
        ),
      });
    }

    return items;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      render: (text: string) => (
        <>
          <span className="td-label">Name : </span>
          {text}
        </>
      ),
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
      width: "280px",
      render: (text: string) => (
        <>
          <span className="td-label">Email address : </span>
          {text}
        </>
      ),
    },
    {
      title: "Role",
      key: "role",
      width: "180px",
      render: (user: IUser) => (
        <>
          <span className="td-label">Role : </span>
          {user?.role?.name ? user?.role.name : "N/A"}
        </>
      ),
    },
    {
      title: "Content Access Groups",
      key: "accessGroups",
      width: "240px",
      render: (_: undefined, user: IUser) => {
        return (
          <>
            <span className="td-label">Permissions : </span>

            {!isEmpty(user?.accessGroups) ? (
              <span className="permissions-text">
                {user?.accessGroups.slice(0, Limit.AccessGroup).map((item, index) => {
                  return (
                    <span key={index}>
                      {item?.name}
                      {user?.accessGroups?.length - 1 !== index && `, `}
                    </span>
                  );
                })}

                {user?.accessGroups?.length > Limit.AccessGroup && (
                  <u
                    onClick={() => {
                      setSelectedUser(user);
                      setAccessGroupModalKey(user?.id);
                    }}
                    className="more-tag">
                    +{user?.accessGroups?.length - Limit.AccessGroup} more
                  </u>
                )}
              </span>
            ) : (
              <span className="permissions-text">N/A</span>
            )}

            {accessGroupModalKey === user?.id && (
              <AccessGroupModal
                isOpen={accessGroupModalKey === user?.id}
                setModalClose={handleCloseAccessGroupModal}
                selectedUser={selectedUser}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Last Activity",
      key: "lastLogin",
      width: "180px",
      render: (user: IUser) => {
        return (
          <>
            <span className="td-label">Last Activity : </span>
            {user?.lastLogin ? formatRelativeTime(user?.lastLogin) : "N/A"}
          </>
        );
      },
    },
    {
      title: "Status",
      width: "100px",
      render: (_: undefined, user: IUser, index: number) => {
        return (
          <>
            <span className="td-label">Status : </span>
            <Switch
              disabled={
                user?.id === userDetails?.id || !checkPlatformPermission(Permissions.EDIT_USER)
              }
              defaultChecked={user?.status === "A" ? true : false}
              onChange={(e) => handleUpdateStatus(e, user, index)}
            />
          </>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "90px",
      className: "centered-column",
      render: (_: string, user: IUser) => (
        <>
          <span className="td-label">Action : </span>
          <Dropdown
            trigger={["click"]}
            menu={{
              onClick: () => {
                setSelectedUser(user);
              },
              items: getMenuItems(),
            }}
            overlayStyle={{ width: 80 }}
            overlayClassName="table-action-dropdown table-action-dropdown-user"
            placement="bottomRight">
            <Button className="more-action">
              <i className="ri-more-2-fill"></i>
            </Button>
          </Dropdown>
        </>
      ),
    },
  ];

  const handleOnCancel = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const getUsersBySearch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedSearchText = e.target.value.trim();
    if (trimmedSearchText.length >= 3) await fetchAllUsers(trimmedSearchText, 1);
    if (e.target.value.length === 0) {
      await fetchAllUsers("", 1);
    }
    dispatch(setUserCurrPage(1));
  };

  const handleSearchDebounce = useDebounce(getUsersBySearch, 500);

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchDebounce(e);
    dispatch(setSearchText(e.target.value));
  };

  const handleDeleteUser = async () => {
    if (selectedUser?.id) await deleteUser(selectedUser?.id, searchText, userCurrPage);
    setIsShowDeleteUserModal(false);
    setSelectedUser(null);
    dispatch(setUserCurrPage(userCurrPage));
    setSelectedUser(null);
  };

  const handlePagination = (page: number) => {
    dispatch(setUserCurrPage(page));
  };

  return (
    <Flex className="admin-inner-wrap user-managements-page" vertical>
      <PageHeader
        title="User Management"
        isRelevantAsset={false}
        content={
          <>
            <Input
              type="search"
              className="header-search"
              placeholder="Search by name or email..."
              prefix={<i className="ri-search-line" />}
              onChange={(e) => {
                handleChangeSearch(e);
              }}
              defaultValue={searchText}
              value={searchText}
              allowClear
            />
            {checkPlatformPermission(Permissions.ADD_USER) && (
              <Button
                className="btn"
                type="default"
                icon={<i className="ri-add-circle-fill"></i>}
                onClick={() => setIsModalOpen(true)}>
                Add new user
              </Button>
            )}
          </>
        }
      />

      <motion.div
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2 }}>
        <Row>
          <Col span={24}>
            <Card className={`global-table-card ${isEmpty(allUsers) ? "global-table-empty" : ""}`}>
              {userTableLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  className="global-table responsive-table responsive-table-user-management"
                  columns={columns}
                  dataSource={allUsers?.length ? allUsers : []}
                  tableLayout="fixed"
                  scroll={{ y: "calc(100vh - 188px)" }}
                  pagination={false}
                />
              )}

              {allUsers && allUsers.length ? (
                <CustomPagination
                  currentPage={userCurrPage}
                  total={totalUsersCount}
                  handlePagination={handlePagination}
                  pageSize={PageLimit.UserManagement}
                />
              ) : (
                <></>
              )}
            </Card>
          </Col>
        </Row>
      </motion.div>

      {isModalOpen && (
        <AddEditUserManagement
          title={isEmpty(selectedUser) ? "Add User" : "Edit User"}
          isModalOpen={isModalOpen}
          onCancel={handleOnCancel}
          selectedUser={selectedUser}
          userDetails={userDetails}
          titleBtn={isEmpty(selectedUser) ? "Create" : "Save"}
        />
      )}

      {isShowDeleteUserModal && (
        <DeleteModal
          isLoading={userDeleteLoading}
          isModalOpen={isShowDeleteUserModal}
          handleCancel={() => {
            setIsShowDeleteUserModal(false);
            setSelectedUser(null);
          }}
          handleSubmit={handleDeleteUser}
          message="Are you sure you want to delete this User?"
          title="Delete User"
        />
      )}
    </Flex>
  );
};

export default Usermanagement;
