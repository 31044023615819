import { toast } from "react-toastify";
import { store } from "../store";
import { userDeleteReq, userGetReq, userPostReq, userPutReq } from "./apiClients";
import {
  setAllUsersDetails,
  setUserTableLoading,
  setUserDeleteLoading,
  setTotalUsersCount,
  setUserCurrPage,
  setConfigurationList,
  setFileUploadProgress,
  setIsFileUpdating,
  setIsGetProfileLoading,
  setIsProfileUpdating,
  setIsResumeUploading,
  setUserProfile,
  userProfileInitValue,
  setIsUserUpdating,
  setIsUserVerifying,
} from "../store/userManagement/usersSlice";
import { ApiErrorCode, isEmpty, PageLimit, UploadResumeState } from "../shared";
import { IUserManagementForm } from "../store/userManagement/user.interface";

let abortController: AbortController | null = null;

export const fetchAllUsers = async (searchText = "", page = 1) => {
  try {
    store.dispatch(setUserTableLoading(true));

    // Cancel the previous request if it's still in progress
    if (abortController) {
      abortController.abort();
    }

    // Create a new AbortController instance for the current request
    abortController = new AbortController();
    const { signal } = abortController;

    const res = await userGetReq(
      `/api/users?nameOrEmail=${searchText}&page=${page - 1}&limit=${PageLimit.UserManagement}`,
      { signal }
    );

    const userRes = res.content ?? [];
    const totalElementsRes = res.totalElements ?? 0;
    const currPageRes = res.number ? res.number + 1 : 1;

    store.dispatch(setAllUsersDetails(userRes));
    store.dispatch(setTotalUsersCount(totalElementsRes));
    store.dispatch(setUserCurrPage(currPageRes));
    return userRes;
  } catch (error: any) {
    if (error?.code !== ApiErrorCode?.ERR_CANCELED) {
      toast.error(error?.response?.data?.message || "Failed to fetch Users");
      store.dispatch(setAllUsersDetails([]));
    }
  } finally {
    store.dispatch(setUserTableLoading(false));
  }
};

export const addUser = async (
  request: IUserManagementForm,
  searchText: string,
  currPage: number
) => {
  try {
    store.dispatch(setIsUserUpdating(true));
    const res = await userPostReq(`/api/users`, request);

    await fetchAllUsers(searchText, currPage);
    toast.success("User added successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.message || error?.response?.data?.message || "Failed to add User");
    return error;
  } finally {
    store.dispatch(setIsUserUpdating(false));
  }
};

export const updateUser = async (
  request: IUserManagementForm,
  searchText: string,
  currPage: number
) => {
  try {
    store.dispatch(setIsUserUpdating(true));
    const res = await userPutReq(`/api/users/${request?.userId}`, request);

    await fetchAllUsers(searchText, currPage);
    toast.success("User updated successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.message || error?.response?.data?.message || "Failed to update User");
    return error;
  } finally {
    store.dispatch(setIsUserUpdating(false));
  }
};

export const deleteUser = async (id: number, searchText: string, currPage: number) => {
  try {
    store.dispatch(setUserDeleteLoading(true));
    const res = await userDeleteReq(`/api/users/${id}`);

    await fetchAllUsers(searchText, currPage);
    toast.success("User deleted successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Failed to delete User");
    return error;
  } finally {
    store.dispatch(setUserDeleteLoading(false));
  }
};

export const updateUserStatus = async (isACtive: boolean, userId: number | undefined) => {
  try {
    store.dispatch(setIsUserUpdating(true));
    const res = await userPutReq(
      `/api/users/${userId}/status`,
      {},
      {
        params: {
          enable: isACtive,
        },
      }
    );

    toast.success("Status updated successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.response?.data?.message || "Failed to update status");
    return error;
  } finally {
    store.dispatch(setIsUserUpdating(false));
  }
};

export const updateUserTaxonomy = async (
  userId: number,
  taxonomyMapping: [{ taxonomyId: number; taxonomyNodeIds: [number] }]
) => {
  try {
    const res = await userPostReq(`/core/user-taxonomy`, { userId, ...taxonomyMapping });
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to add taxonomy nodes");
    return error;
  }
};

///core/configuration-settings/
export const getConfigurationList = async () => {
  try {
    const res = await userGetReq(`/core/configuration-settings/`);
    store.dispatch(setConfigurationList(res));
    return res;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const uploadProfilePic = async (
  formData: FormData,
  setUploadingState: Function,
  isBackground = false
) => {
  try {
    setUploadingState(UploadResumeState.UPLOADING);
    store.dispatch(setIsFileUpdating(true));
    const { data } = await userPutReq(`/core/profile/picture`, formData, {
      params: {},
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          store.dispatch(setFileUploadProgress(percentCompleted));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setUploadingState(UploadResumeState.UPLOADED);
    fetchUserProfile();
    if (isBackground) {
      toast.success("Background cover successfully updated");
    } else {
      toast.success("Profile pic successfully updated");
    }

    return data;
  } catch (error: any) {
    setUploadingState(UploadResumeState.FAILED);
    if (isBackground) {
      toast.error(error?.errorMessage ?? "Failed to update background cover");
    } else {
      toast.error(error?.errorMessage ?? "Failed to update profile pic");
    }
  } finally {
    store.dispatch(setIsFileUpdating(false));
  }
};

export const uploadProfileResume = async (formData: FormData, setUploadingState: Function) => {
  try {
    setUploadingState(UploadResumeState.UPLOADING);
    store.dispatch(setIsResumeUploading(true));
    const { data } = await userPostReq(`/core/profile/resume`, formData, {
      params: {},
      onUploadProgress: function (progressEvent) {
        if (progressEvent?.loaded && progressEvent?.total) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          store.dispatch(setFileUploadProgress(percentCompleted));
        }
      },
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setUploadingState(UploadResumeState.UPLOADED);
    fetchUserProfile();
    toast.success("Resume successfully uploaded");

    return data;
  } catch (error: any) {
    setUploadingState(UploadResumeState.FAILED);
    toast.error(error?.errorMessage ?? "Failed to upload resume");
  } finally {
    store.dispatch(setIsResumeUploading(false));
  }
};

export const fetchUserProfile = async () => {
  try {
    store.dispatch(setIsGetProfileLoading(true));
    const res = await userGetReq(`core/profile`);
    if (!isEmpty(res)) {
      store.dispatch(setUserProfile(res));
    }
    return res;
  } catch (error) {
    console.log(error);
    store.dispatch(setUserProfile(userProfileInitValue));
  } finally {
    store.dispatch(setIsGetProfileLoading(false));
  }
};

export const userProfilePulling = async () => {
  try {
    const res = await userGetReq(`core/profile`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const extractResumeDetails = async () => {
  try {
    const { data } = await userPutReq(`core/profile/extract`);
    return data;
  } catch (error) {
    console.log(error);
    toast.error("Failed to extract details from resume");
  }
};

export const updateUserProfile = async (profileDetails: any) => {
  try {
    store.dispatch(setIsProfileUpdating(true));
    const res = await userPostReq(`/core/profile`, { ...profileDetails });
    fetchUserProfile();
    toast.success("Profile successfully updated");
    return res;
  } catch (error) {
    console.log(error);
    toast.error("Failed to update profile");
  } finally {
    store.dispatch(setIsProfileUpdating(false));
  }
};


export const verifyUserByEmail = async (email: string) => {
  store.dispatch(setIsUserVerifying(true));
  try {
    const res = await userGetReq(`/api/users?email=${encodeURIComponent(email)}`);
    return res;
  } catch (error: any) {
    error?.status != 404 &&
      toast.error(error?.response?.data?.message ?? "Failed to verify email!");
    return null;
  } finally {
    store.dispatch(setIsUserVerifying(false));
  }
};