import { toast } from "react-toastify";
import { store } from "../store";
import {
  setAccessGroups,
  setIsAccessGroupUpdating,
  setIsAccessGroupDeleting,
  setIsAccessGroupsLoading,
  setTotalAccessGroupsCount,
  updatePageOnAccessGrpDelete,
} from "../store/accessGroup/accessGroupSlice";
import { IaccessGroups, IAccessGroupsForm } from "../store/accessGroup/accessGroup.interface";
import { deleteRequest, get, post, put } from "./apiClients";
import { ApiErrorCode, PageLimit, stringToBoolean } from "../shared";

interface IAccessGroupResponse {
  accessGroupId: number;
  accessGroupName: string;
  download: string;
  edit: string;
  role: string;
  searchAndView: string;
  status: string;
  taxonomyMapping: { taxonomyId: number; taxonomyNodeIds: number[] }[];
  upload: string;
}

// IGNORE the getAccessGroups function
export const getAccessGroups = async (
  isLoading: boolean,
  searchText: string | null,
  page: number | null
) => {
  try {
    isLoading && store.dispatch(setIsAccessGroupsLoading(true));
    let res;
    if (page === null || searchText === null) {
      res = await get(`/core/accessGroup/accessGroup-taxonomy`);
    } else {
      res = await get(
        `/core/accessGroup/accessGroup-taxonomy?name=${searchText}&page=${page - 1}&limit=${
          PageLimit.AccessGroup
        }`
      );
    }

    const accessGroupContent = res.content ?? [];
    const totalElementsRes = res.totalElements ?? 0;

    const apiResponse: IaccessGroups[] = accessGroupContent?.map((v: IAccessGroupResponse) => ({
      id: v?.accessGroupId,
      title: v?.accessGroupName,
      role: v?.role,
      taxonomyMapping: v?.taxonomyMapping,
      searchView: stringToBoolean(v?.searchAndView),
      download: stringToBoolean(v?.download),
      upload: stringToBoolean(v?.upload),
      edit: stringToBoolean(v?.edit),
      status: stringToBoolean(v?.status),
    }));

    store.dispatch(setAccessGroups(apiResponse ?? []));
    store.dispatch(setTotalAccessGroupsCount(totalElementsRes));
  } catch (error) {
    console.log(error, "getAccessGroups error");
    store.dispatch(setAccessGroups([]));
  } finally {
    store.dispatch(setIsAccessGroupsLoading(false));
  }
};

export const addContentAccessGroup = async (
  request: IAccessGroupsForm,
  searchText: string,
  currPage: number
) => {
  try {
    store.dispatch(setIsAccessGroupUpdating(true));
    const res = await post(`/core/access-group`, { ...request });

    fetchContentAccessGroup(true, searchText, currPage);
    toast.success("Access Group added successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage || "Failed to add Access Group");
    return error;
  } finally {
    store.dispatch(setIsAccessGroupUpdating(false));
  }
};

let abortController: AbortController | null = null;
export const fetchContentAccessGroup = async (
  isLoading: boolean,
  searchText: string | null,
  page: number,
  paginationRequired = true
) => {
  try {
    isLoading && store.dispatch(setIsAccessGroupsLoading(true));
    // Cancel the previous request if it's still in progress
    if (abortController) {
      abortController.abort();
    }
    // Create a new AbortController instance for the current request
    abortController = new AbortController();
    const { signal } = abortController;

    const res = await get(`/core/access-group`, {
      signal,
      params: {
        name: searchText || null,
        page: page - 1,
        limit: PageLimit.AccessGroup,
        paginationRequired,
      },
    });

    store.dispatch(setAccessGroups(res.content || []));
    store.dispatch(setTotalAccessGroupsCount(res.totalElements || 0));
  } catch (error: any) {
    if (error?.code !== ApiErrorCode?.ERR_CANCELED) {
      store.dispatch(setAccessGroups([]));
      store.dispatch(setTotalAccessGroupsCount(0));
      toast.error(error?.errorMessage || "Failed to fetch Access Group");
    }
  } finally {
    store.dispatch(setIsAccessGroupsLoading(false));
  }
};

export const updateContentAccessGroup = async (
  request: IAccessGroupsForm,
  searchText: string,
  currPage: number
) => {
  try {
    store.dispatch(setIsAccessGroupUpdating(true));
    const res = await put(`/core/access-group/${request?.id}`, request);

    fetchContentAccessGroup(true, searchText, currPage);
    toast.success("Access Group updated successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage || "Failed to update Access Group");
    return error;
  } finally {
    store.dispatch(setIsAccessGroupUpdating(false));
  }
};

export const deleteContentAccessGroup = async (
  id: number,
  searchText: string,
  currPage: number,
  totalAccessGroupsCount: number
) => {
  try {
    store.dispatch(setIsAccessGroupDeleting(true));
    const res = await deleteRequest(`/core/access-group/${id}`);

    let newPage = currPage || 0;
    if (totalAccessGroupsCount % PageLimit.AccessGroup === 1) {
      newPage = newPage - 1;
      store.dispatch(updatePageOnAccessGrpDelete(newPage));
    }
    fetchContentAccessGroup(true, searchText, newPage);
    toast.success("Access Group deleted successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage || "Failed to delete Access Group");
    return error;
  } finally {
    store.dispatch(setIsAccessGroupDeleting(false));
  }
};

export const updateContentAccessGroupStatus = async (isActive: boolean, userId: number) => {
  try {
    store.dispatch(setIsAccessGroupUpdating(true));
    const res = await put(
      `/core/access-group/${userId}/status`,
      {},
      {
        params: {
          enable: isActive,
        },
      }
    );

    toast.success("Status updated successfully");
    return res;
  } catch (error: any) {
    toast.error(error?.errorMessage || "Failed to update status");
    return error;
  } finally {
    store.dispatch(setIsAccessGroupUpdating(false));
  }
};
