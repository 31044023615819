import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Option } from "antd/es/mentions";
import { Button, Card, Form, Input, Modal, Select, Switch } from "antd";
import { createTaxonomy } from "../../services/taxonomy";
import "./CreateTaxonomy.scss";

interface IModal {
  isModalOpen: boolean;
  onCancel: VoidFunction;
  title: string;
  setIsModalOpen: (v: boolean) => void;
}

const CreateTaxonomy = (props: IModal) => {
  const { isModalOpen, onCancel, title, setIsModalOpen } = props;

  const [tableType, setTableType] = useState<string>("");
  const [isMandatory, setIsMandatory] = useState<boolean>(false);

  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: {
    title: string;
    type: string;
    purpose: string;
    levels: number;
    versions: number;
  }) => {
    const { purpose, title, type, levels, versions } = values;

    const newTaxonomy = {
      title: title,
      taxonomyType: type,
      purpose: purpose,
      levels: levels,
      versions: versions,
      mandatory: isMandatory,
    };
    createTaxonomy(newTaxonomy, navigate);
    setIsModalOpen(false);
  };

  const onSetRole = (type: string) => {
    setTableType(type);
  };

  return (
    <Modal
      title={title}
      afterClose={() => form.resetFields()}
      width={500}
      footer={
        <>
          <Button
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            className="btn"
            size={"middle"}>
            Cancel
          </Button>
          <Button
            loading={false}
            onClick={() => form.submit()}
            htmlType="submit"
            className="btn"
            type="primary"
            size={"middle"}>
            Create
          </Button>
        </>
      }
      onCancel={() => {
        form.resetFields();
        onCancel();
      }}
      open={isModalOpen}>
      <div className="add-user-inner">
        <Form
          form={form}
          name="addUser"
          initialValues={{ title: "", type: undefined, purpose: "", levels: null }}
          onFinish={onFinish}
          requiredMark={false}
          size="large"
          className="form-container add-user-form">
          <div className="container">
            <div className="form-inner">
              <Card className="input-card">
                <Form.Item
                  label="Title"
                  name="title"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter title",
                    },
                  ]}>
                  <Input size="middle" placeholder="Enter title" />
                </Form.Item>

                <Form.Item
                  label="Taxonomy type"
                  name="type"
                  labelCol={{ span: 24 }}
                  rules={[{ required: true, message: "Please select type" }]}>
                  <Select
                    placeholder="Select type"
                    optionFilterProp="children"
                    popupClassName="ui-dropdown-default"
                    onChange={(e) => onSetRole(e)}
                    options={[
                      {
                        value: "TREE",
                        label: "Tree hierarchy",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  label="Mandatory"
                  name="mandatory"
                  labelCol={{ span: 4 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter title",
                    },
                  ]}>
                  <Switch
                    checked={isMandatory}
                    onChange={(e) => setIsMandatory(e)}
                    style={{ marginLeft: "15px" }}
                  />
                </Form.Item>

                {tableType === "TREE" && false && (
                  <Form.Item
                    label="Version"
                    name="versions"
                    labelCol={{ span: 24 }}
                    rules={[{ required: true, message: "Please select version" }]}>
                    <Select placeholder="Select version" popupClassName="ui-dropdown-default">
                      <Option value="1.0">Version 1.0</Option>
                      <Option value="1.5">Version 1.5</Option>
                      <Option value="2.0">
                        Version 2.0 <span className="stat active">(Current)</span>
                      </Option>
                      <Option value="2.5" disabled={true}>
                        Version 2.5 <span className="stat draft">(Draft)</span>
                      </Option>
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  label="Purpose"
                  name="purpose"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter purpose",
                    },
                  ]}>
                  <Input.TextArea size="middle" placeholder="Type here..." />
                </Form.Item>
              </Card>
            </div>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateTaxonomy;
